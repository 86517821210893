
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="DevTracker">
  <div class="hashAncor" id="contentpage">
    <div class="container">
      <div class="half updates">
        <div class="title">
          <div class="title-container">
            <h2>UPDATES</h2>
          </div>
          <div class="decoration">
            <div class="title-marker"></div>
            <div class="sub-marker"></div>
          </div>
        </div>
        <div class="services-list">
          <div class="service-box dev-tracker service-1" *ngFor="let post of devPosts">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
            <div class="service-name">
              <div class="background"></div><a href="https://www.identityrpg.com/community/index.php?/topic/{{ post.topic_id }}-{{ post.title_seo }}/&amp;do=findComment&amp;comment={{ post.pid }}">
                <div class="service-icon"><img src="https://www.identityrpg.com/community/uploads/{{ post.author?.pp_thumb_photo }}"></div>
                <div class="service-title">{{ post.title }}</div>
                <div class="service-poster">{{ post.author?.name }}</div>
                <time class="service-time" datetime="post.post_date"><span>{{ post.post_date*1000 | date:'d'}}th</span><span>{{ post.post_date*1000 | date:'MMM y' }}</span></time></a>
            </div>
            <div class="service-text">
              <div class="service-description" [innerHTML]="post.post"></div>
            </div>
            <ul class="buttons">
              <li><a href="https://www.identityrpg.com/community/index.php?/topic/{{ post.topic_id }}-{{ post.title_seo }}/&amp;do=findComment&amp;comment={{ post.pid }}"><i class="licon licon-enter-right"></i></a></li>
            </ul>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="half twitter">
        <div class="title">
          <div class="title-container">
            <h2>FEED</h2>
          </div>
          <div class="decoration">
            <div class="title-marker"></div>
            <div class="sub-marker"></div>
          </div>
        </div>
        <div id="Twitter"><a class="twitter-timeline" href="https://twitter.com/Identityrpg" data-widget-id="682288432010399745">Tweets by @Identityrpg</a></div>
      </div>
    </div>
  </div>
</div>