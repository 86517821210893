
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- Store Listings-->
<section class="storehead" id="mainhead" *ngIf="slider">
  <!--/ data-origin-->
  <!-- "tl": Top left-->
  <!-- "tc": Top center-->
  <!-- "tr": Top right-->
  <!-- "ml": Middle left-->
  <!-- "mc": Middle center-->
  <!-- "mr": Middle right-->
  <!-- "bl": Bottom left-->
  <!-- "bc": Bottom center-->
  <!-- "br": Bottom right-->
  <!-- Store Slider-->
  <div class="store-header master-slider ms-skin-round ms-skin-black-2" id="masterslider">
    <!--.ms-slide( data-delay="10" )
    .ms-layer
    	iframe( width="560" height="315" src="https://www.youtube.com/embed/2l5SyrRZpiM" frameborder="0" allowfullscreen ) 
    
    -->
    <div class="ms-bullets"></div>
    <div class="ms-slide ms-home" data-delay="10">
      <div class="fence" data-effect="fade(long)" data-ease="easeOutQuint" data-duration="1500" data-delay="10"></div>
      <video data-mute="true" data-loop="true" data-fill-mode="fill">
        <source src="/assets/video/forest.webm" type="video/webm">
        <source src="/assets/video/forest.mp4" type="video/mp4">
      </video>
      <div class="ms-layer ms-caption slider-identity" style="bottom: 150px;" data-effect="top(long)" data-duration="1500" data-delay="0" data-ease="easeOutQuint">Identity</div>
      <div class="ms-layer ms-caption slider-subtitle" style="top: 57% !important" data-effect="bottom(long)" data-duration="1500" data-delay="500" data-ease="easeOutQuint" data-hide-effect="fade"> 
        <div class="borders"> 
          <div class="top"></div>
          <div class="bottom"></div>
        </div><span>Play sooner with Module Access</span>
      </div>
      <div class="ms-layer slider-listings bottom" data-effect="fade(long)" data-duration="4600" data-delay="2500" data-ease="easeOutQuint">
        <ul data-effect="fade" data-duration="1400" data-delay="1400">
          <li class="crowd-box greenmoss" *ngFor="let listing of items | categoryFilter:1 | slice:0:4; let i = index"> 
            <div class="background"></div>
            <div class="leafybackground"></div>
            <div class="blueprint text"><a class="title" routerLink="/item/{{ listing.id }}">{{ listing.title }}</a>
              <div class="crowd-listing">{{ listing.description }}</div>
            </div>
            <div class="blueprint cover crowd-pleaser">
              <!-- routerLink="/item/{{ listing.id }}"-->
              <!-- (click)="changeBackground(1, i)"-->
              <button class="button buy" routerLink="/item/{{ listing.id }}"> <span>More Info</span></button>
              <div class="crowd-buy">
                <div class="only">Only</div><span class="onsale">$ {{ listing.price }}<sup>.00</sup><span class="currency">{{ listing.currency }}</span></span><small *ngIf="listing.originalprice &gt; listing.price">${{ listing.originalprice }}<sup>.00</sup></small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="slider_bikes ms-slide" data-delay="10">
      <video data-mute="true" data-loop="true" data-fill-mode="fill">
        <source src="/assets/video/car.webm" type="video/webm">
        <source src="/assets/video/car.mp4" type="video/webm">
      </video>
      <div class="fence" data-effect="fade(long)" data-ease="easeOutQuint" data-duration="1500" data-delay="10"></div>
      <div class="ms-layer ms-caption slider-identity-title bike-title" data-effect="top(long)" data-duration="1500" data-delay="1200" data-ease="easeOutQuint">
        <div class="gradient"></div>
        <div class="borders">
          <div class="top"></div>
          <div class="bottom"></div>
        </div><span>Identity</span>
      </div>
      <div class="ms-garage-text-second ms-layer" data-effect="rotate3dbottom(80,0,0,short)" data-duration="1000" data-delay="1000" data-ease="easeOutQuint" data-hide-effect="fade"> 
        <div class="borders">
          <div class="top"></div>
          <div class="bottom"></div>
        </div><span>Bikes of all kinds</span>
      </div>
      <ul class="ms-layer ms-signs" data-effect="rotate3dtop(80,0,0,short)" data-duration="1000" data-delay="2500" data-ease="easeOutQuint" data-hide-effect="fade">
        <div class="borders">
          <div class="top"></div>
          <div class="bottom"></div>
        </div>
        <li><i class="icon road"></i><span>Own a Collection</span></li>
        <li><i class="icon eyedropper"></i><span>Customize Looks</span></li>
        <li><i class="icon wrench"></i><span>Testdrive and Tune</span></li>
      </ul>
      <div class="ms-layer slider-listings bottom house" data-effect="fade(long)" data-duration="4600" data-delay="2500" data-ease="easeOutQuint"> 
        <ul>
          <li class="crowd-box blueprint" *ngFor="let listing of items | categoryFilter:17 | slice:0:4; let i = index" data-effect="fade" data-duration="2500+(i*400)"> 
            <div class="background"></div>
            <div class="background-hex"></div>
            <div class="blueprint text"><a class="title" routerLink="/shop/item/{{ listing.id }}">{{ listing.title }}</a>
              <div class="crowd-listing">{{ listing.description }}</div>
            </div>
            <div class="blueprint cover crowd-pleaser">
              <!-- (click)="changeBackground(2, i)"-->
              <button class="button buy" routerLink="/item/{{ listing.id }}"> <span>More Info</span></button>
              <div class="crowd-buy">
                <div class="only">Only</div><span class="onsale">$ {{ listing.price }}<sup>.00</sup><span class="currency">{{ listing.currency }}</span></span><small *ngIf="listing.originalprice &gt; listing.price">${{ listing.originalprice }}<sup>.00</sup></small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="ms-slide ms-house world-of-identity">
      <div class="fence" data-effect="fade(long)" data-ease="easeOutQuint" data-duration="1500" data-delay="10"></div>
      <video data-mute="true" data-loop="true" data-fill-mode="fill">
        <source src="/assets/video/apartment.webm" type="video/webm">
        <source src="/assets/video/apartment.mp4" type="video/mp4">
      </video>
      <div class="ms-layer ms-caption slider-identity-title" data-effect="top(long)" data-duration="1500" data-delay="1200" data-ease="easeOutQuint"> 
        <div class="gradient"></div>
        <div class="borders">
          <div class="top"></div>
          <div class="bottom"></div>
        </div><span>Identity</span>
      </div>
      <div class="ms-layer ms-caption slider-subtitle-background" data-effect="rotate3dleft(30,50,0,50)" data-duration="1500" data-delay="1600" data-ease="easeOutQuint" data-hide-effect="fade">
        <div class="ms-layer ms-caption slider-subtitle-top" data-effect="rotate3dtop(80,0,0,short)" data-duration="1500" data-delay="1600" data-ease="easeOutQuint" data-hide-effect="fade">
          <div class="borders">
            <div class="top"></div>
            <div class="bottom"></div>
          </div><span>Find your escape </span>
        </div>
        <div class="ms-layer ms-caption slider-subtitle2-top" data-effect="rotate3dtop(80,0,0,short)" data-duration="1500" data-delay="2000" data-ease="easeOutQuint" data-hide-effect="fade">
          <div class="borders">
            <div class="top"></div>
            <div class="bottom"></div>
          </div><span>In the world of Identity</span>
        </div>
      </div>
      <div class="ms-layer slider-listings bottom house" data-effect="fade(long)" data-duration="4600" data-delay="2500" data-ease="easeOutQuint"> 
        <ul>
          <li class="crowd-box blueprint" *ngFor="let listing of items | categoryFilter:1 | slice:0:4; let i = index" data-effect="fade" data-duration="2500+(i*400)"> 
            <div class="background"></div>
            <div class="background-grid"></div>
            <div class="blueprint text"><a class="title" routerLink="/shop/item/{{ listing.id }}">{{ listing.title }}</a>
              <div class="crowd-listing">{{ listing.description }}</div>
            </div>
            <div class="blueprint cover crowd-pleaser">
              <!-- (click)="changeBackground(2, i)"-->
              <button class="button buy" routerLink="/item/{{ listing.id }}"> <span>More Info</span></button>
              <div class="crowd-buy">
                <div class="only">Only</div><span class="onsale">$ {{ listing.price }}<sup>.00</sup><span class="currency">{{ listing.currency }}</span></span><small *ngIf="listing.originalprice &gt; listing.price">${{ listing.originalprice }}<sup>.00</sup></small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="fade"></div>
</section>
<div id="Store">
  <div class="store-buttons"><a [attr.class]="flashCart ? 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue active' : 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue'" [@zFade]="flashCart" routerLink="/shop/checkout/">
      <div class="background"></div>
      <div class="background2"></div>
      <div class="cart-items">
        <div>{{ cart.length + ( custom ? custom.length:0 ) }}</div>
        <div class="small">{{ cart.length + ( custom ? custom.length:0 ) == 1 ? 'Item' : 'Items' }}</div>
      </div><i class="licon licon-cart-full"></i></a></div>
  <div class="hashAncor">
    <div class="row title-row">
      <div class="border">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="bottom-line"></div>
      </div>
      <div class="container">
        <div class="background"></div>
        <div class="backgroundtwo"></div>
        <h5 class="left crowd-title">
          <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/products/">Packages</a>
        </h5><a class="right shop-link" routerLink="/products">
          <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">More Products</span></a>
      </div>
    </div>
    <div class="row container">
      <products (addedProductoCart)="getCart()" hideHeader="false" showFilters="false"></products>
    </div>
    <div class="row title-row">
      <div class="border">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="bottom-line"></div>
      </div>
      <div class="container">
        <div class="background"></div>
        <div class="backgroundtwo"></div>
        <h5 class="left crowd-title">
          <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/categories/">Items</a>
        </h5><a class="right shop-link" routerLink="/categories">
          <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">More Items</span></a>
      </div>
    </div>
    <div class="row container">
      <categories (addedItemtoCart)="getItemsCart()" [resetCart]="cartReset" hideHeader="false"></categories>
    </div>
  </div>
</div>