
<div id="SocialCommunity" *ngFor="let account of accounts">
  <div class="title-row">
    <div class="Left">
      <h5>Social</h5>
      <h1>Network</h1>
    </div>
    <div class="Right"><span class="channel" (click)="channelSelector = (channelSelector == false) ? true : false">Channel: {{ '#'+selectedChannel }}</span>
      <ul class="channel-selector" [@groupSelector]="channelSelector">
        <li *ngFor="let channel of channels" (click)="selectChannel(channel.name)">{{ '#'+channel.name }}</li>
      </ul>
    </div>
  </div>
  <div class="clear"></div>
  <div id="Left">
    <div id="Feed">
      <div id="Publish">
        <div class="channels"><span class="post">Post</span></div>
        <div class="publish">
          <div class="post">
            <form>
              <textarea class="new-post" #NewPost type="text" name="post-body" value="Say what?" placeholder="Say what?" [(ngModel)]="post.body" (click)="composeMessage($e)" (keyUp)="composeMessage($e)" [style.height]="newPostHeight"></textarea>
            </form>
          </div>
          <div class="actions">
            <ul class="media">
              <li><i class="licon licon-file-image"></i><span>Photo</span></li>
              <li [attr.class]="( showTag == true ) ? 'active' : ''"><i class="licon licon-tags"></i><span>Tag</span>
                <div class="tagger" [@showtag]="showTag">
                  <input class="tag-input" type="text" (click)="searchTag($e)" (keyUp)="searchTag($e)" [(ngModel)]="tag.search" placeholder="Username / Character">
                  <ul class="people" (click)="showTag = (showTag == true) ? false : true">
                    <li *ngFor="let person of tagpeople">{{ person.name }}</li>
                  </ul>
                </div>
              </li>
              <li [attr.class]="( showEmotes == true ) ? 'active' : ''" (mouseover)="hoverEmotes()" (mouseout)="unhoverEmotes()" (click)="showEmotes = (showEmotes==true) ? false : true"><i class="licon licon-smile"></i><span (click)="showEmotes = (showEmotes==true) ? false : true">Feeling</span>
                <ul class="emotes" [@showemotes]="showEmotes">
                  <li *ngFor="let smiley of emotes" (click)="addEmotion(smiley)"><i [attr.class]="'licon licon-'+smiley"></i></li>
                </ul>
              </li>
            </ul>
            <div class="button" (click)="postMessage()">Post</div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div id="Articles">
      <article *ngFor="let post of feed">
        <div class="opening-post">
          <div class="top-right dimmable"><a (click)="removePost(post.postid)"><i class="licon licon-cross"></i></a></div>
          <div class="author"><img src="{{ post.author.image }}">
            <div class="user">{{ post.author.username }}</div>
            <div class="time">{{ post.time | amAdd : '1' : 'hours' | amTimeAgo }}</div>
          </div>
          <div class="post">
            <div class="content body" [innerHTML]="post.body"></div>
          </div>
          <ul class="reactions"></ul>
          <div class="comments">
            <form class="addComment"><img class="myPicture" src="{{ account.image }}">
              <input type="text" name="body" value="Reply to..." (keyup)="commenting($event, post)" placeholder="Reply to..." [(ngModel)]="comment.body">
            </form>
          </div>
          <ul class="actions">
            <li class="reactions">
              <div class="like">
                <div class="likepad" (click)="like(post)"></div><i class="licon licon-thumbs-up"></i><span>Like</span>
              </div>
              <ul class="subreactions">
                <li *ngFor="let act of reactions" (click)="like(post, act.kind)"><i [attr.class]="'licon ' + act.icon"> </i><span>{{ act.name }}</span></li>
              </ul>
            </li>
            <li class="share" (click)="share(post)"><i class="licon licon-share2"></i><span>Share</span></li>
            <li class="clear"></li>
          </ul>
        </div>
        <div class="clear"></div>
        <div class="commentsList">
          <ul>
            <li class="comment" *ngFor="let comment of post.comments"> 
              <div class="author"><img class="comment-profile" src="{{ comment.author.image }}"><span class="comment-author">{{ comment.author.username }}</span><i class="right licon licon-cross" (click)="removeComment(postid)"></i><span class="comment-time">{{ comment.created | amAdd : '1' : 'hours' | amTimeAgo  }}</span></div>
              <div class="comment-body" [innerHTML]="comment.body"></div>
              <ul class="actions">
                <li class="reactions">
                  <div class="like">
                    <div class="likepad" (click)="likeComment(comment)"></div><i class="licon licon-thumbs-up"></i><span>Like</span>
                  </div>
                  <ul class="subreactions">
                    <li *ngFor="let act of reactions" (click)="like(post, act.kind)"><i [attr.class]="'licon ' + act.icon"> </i><span>{{ act.name }}</span></li>
                  </ul>
                </li>
                <li class="quote" (click)="quote(post)"><i class="licon licon-share2"></i><span>Quote</span></li>
                <div class="comments"></div>
              </ul>
            </li>
          </ul>
          <form class="addComment" *ngIf="post.comments_count &gt; 0"><img class="myPicture" src="{{ account.image }}">
            <input type="text" name="body" value="Reply to..." (keyup)="commenting($event, post)" placeholder="Reply to..." [(ngModel)]="comment.body">
          </form>
        </div>
      </article>
    </div>
  </div>
  <div id="Right">
    <div id="Aside" malihu-scrollbar>
      <article *ngFor="let post of feed; let i = index" (click)="popupPost = (popupPost==i) ? -1 : i">
        <div class="author">
          <div class="image"><img src="{{ post.author.image }}"></div>
          <div class="user">{{ post.author.username }}</div>
          <div class="time">{{ post.time | amAdd : '1' : 'hours' | amTimeAgo }}</div>
        </div>
        <div class="smallpost" [innerHTML]="post.body"></div>
        <div class="pop" [@showPopupPost]="popupPost == i" (click)="popupPost = -1">
          <div class="post" [innerHTML]="post.body"></div>
          <ul class="actions">
            <li (click)="like(post)"><span>Like</span><i class="licon licon-thumbs-up"></i></li>
            <li (click)="share(post)"><span>Share</span><i class="licon licon-share2"></i></li>
          </ul>
        </div>
      </article>
    </div>
    <div id="Aside" malihu-scrollbar>
      <article *ngFor="let friend of friends" (click)="popupPost = (popupPost==i) ? -1 : i">
        <div class="profile-tag">
          <div class="profile pic"><img src="{{ friend.image }}"></div>
          <div class="profile name" (click)="chat(friend.accountid)">{{ friend.username }}</div>
          <div class="fa fa-circle" *ngIf="friend.active == 1"></div>
          <div class="profile last-online" *ngIf="friend.active != 1">Last active: {{ friend.sinceactive | amDuration:'seconds' }}</div>
          <div class="profile last-online" *ngIf="friend.active == 1">Active Now</div>
          <div class="actions">
            <!--.action.friend( *ngIf="friend.friend.status == 'friends'" )
            .hover.blue Friends
            i.fa.fa-world.blue	
            -->
            <!--.action.add-friend( (click)="confirmFriend(req.accountid)", *ngIf="friend.friend.status == 'pending'" )
            .hover Confirm Request
            i.fa.fa-check.green
            -->
            <div class="action add-friend">
              <div class="hover">{{ friend.friend.status }}</div><i class="fa fa-user" *ngIf="!friend.friend.status" (click)="addFriend(friend.accountid)"></i><i class="fa fa-user-plus" *ngIf="friend.friend.status == 'pending'" (click)="confirmFriend(friend.accountid)"></i><i class="fa fa-check green" *ngIf="friend.friend.status == 'confirmed'"></i>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</div>