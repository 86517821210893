
<div class="title-row">
  <h5>Build your</h5>
  <h1>Character</h1>
</div>
<div class="clear"></div>
<div id="UserCharacter">
  <ul>
    <li *ngFor="let character of characters"> 
      <div>{{ character.id }}</div>
      <div>{{ character.accountid }}</div>
      <div>{{ character.name }}</div>
      <div>{{ character.characterCustomization }}</div>
      <div>{{ character.bankMoney }}</div>
      <div>{{ character.equipped }}</div>
      <div>{{ character.created }}</div>
      <div>{{ character.modified }}</div>
      <div>{{ character.lastseen }}</div>
      <div>{{ character.currentlyactive }}</div>
    </li>
  </ul>
</div>