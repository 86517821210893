
<!-- Cart details-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="cart-details" id="Overlay" [@cartoverlay]="showDetails" *ngIf="selectedProduct != null">
  <div id="Darkness" (click)="showDetails = 'hide'"></div>
  <div id="CartDetails" [@cartoverlay]="showDetails">
    <div class="box-borders">
      <div class="top"></div>
      <div class="left"></div>
      <div class="right"></div>
      <div class="bottom"></div>
    </div>
    <div class="title">
      <div class="backgrounds">
        <div class="gradients"></div>
        <div class="stripes"></div>
      </div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div><i class="licon licon-cross-square close" (click)="showDetails = 'hide'"></i>
      <h2 *ngFor="let product of products | slice:selectedProduct:selectedProduct+1"> <a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h2>
    </div>
    <div id="Frame">
      <div id="InternalDetails">
        <div id="Loading" *ngIf="loadingCart"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>
        <div id="Item" *ngFor="let product of products | slice:selectedProduct:selectedProduct+1">
          <div class="row">
            <div id="ProductImages" *ngIf="product.images">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div><a routerLink="/shop/product/{{ product.id }}"><img class="slide" *ngFor="let image of product.images | imageSize:'listing'" [attr.src]="image.url"></a>
            </div>
            <div class="crowd-box" id="ProductDetails">
              <div class="crowd-details">
                <h5 class="crowd-title"><a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h5>
              </div>
              <div class="crowd-description">{{ product.html }}</div>
            </div>
            <h5 class="included_items_title">Items Included</h5>
            <div class="included_items" id="Items" *ngIf="product.items.length &gt; 0">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
              <ul>
                <li *ngFor="let item of product.items"><a routerLink="/shop/item/{{ item.id }}">
                    <div *ngIf="item.groupinfo"><i *ngIf="item.groupinfo.icon" [attr.class]="item.groupinfo.icon"></i></div><span>{{ item.title }}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="Actions">
        <div class="direction-controls lightbox-controls">
          <div class="background"></div>
          <div class="double-borders">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left-side">
              <div class="left"></div>
              <div class="top-left"></div>
              <div class="bottom-left"></div>
            </div>
            <div class="right-side">
              <div class="right"></div>
              <div class="top-right"></div>
              <div class="bottom-right"></div>
            </div>
          </div>
          <div class="next" *ngFor="let product of products | slice:selectedProduct+1:selectedProduct+2; let i = index;" (click)="nextCartItem()"><span>next</span><i class="licon licon-chevron-right"></i></div>
          <div class="prev" *ngFor="let product of products | slice:selectedProduct-1:selectedProduct; let i = index;" (click)="prevCartItem()"><i class="licon licon-chevron-left"></i><span>prev</span></div>
        </div>
        <div class="direction-controls">
          <div class="next" routerLink="/shop/product/{{ selectedProduct.id }}">
            <div class="background"></div>
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div><span>View</span><i class="licon licon-bookmark2"></i>
          </div>
          <div class="prev" (click)="showDetails = 'hide'">
            <div class="background"></div>
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div><i class="licon licon-cross2"></i><span>Close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="hideHeader == false">
  <section class="storehead" id="mainhead">
    <!--/ data-origin-->
    <!-- "tl": Top left-->
    <!-- "tc": Top center-->
    <!-- "tr": Top right-->
    <!-- "ml": Middle left-->
    <!-- "mc": Middle center-->
    <!-- "mr": Middle right-->
    <!-- "bl": Bottom left-->
    <!-- "bc": Bottom center-->
    <!-- "br": Bottom right-->
    <!-- Store Slider-->
    <div class="store-header master-slider ms-skin-round ms-skin-black-2" id="masterslider">
      <!--.ms-slide( data-delay="10" )
      .ms-layer
      	iframe( width="560" height="315" src="https://www.youtube.com/embed/2l5SyrRZpiM" frameborder="0" allowfullscreen ) 
      
      -->
      <div class="ms-bullets"></div>
      <div class="ms-slide ms-home" data-delay="10">
        <div class="fence" data-effect="fade(long)" data-ease="easeOutQuint" data-duration="1500" data-delay="10"></div>
        <video data-mute="true" data-loop="true" data-fill-mode="fill">
          <source src="/assets/video/forest.webm" type="video/webm">
          <source src="/assets/video/forest.mp4" type="video/mp4">
        </video>
        <div class="ms-layer ms-caption slider-identity" style="bottom: 150px;" data-effect="top(long)" data-duration="1500" data-delay="0" data-ease="easeOutQuint">Identity</div>
        <div class="ms-layer ms-caption slider-subtitle" style="top: 57% !important" data-effect="bottom(long)" data-duration="1500" data-delay="500" data-ease="easeOutQuint" data-hide-effect="fade"> 
          <div class="borders"> 
            <div class="top"></div>
            <div class="bottom"></div>
          </div><span>Play sooner with Module Access</span>
        </div>
        <div class="ms-layer slider-listings bottom" data-effect="fade(long)" data-duration="4600" data-delay="2500" data-ease="easeOutQuint">
          <ul data-effect="fade" data-duration="1400" data-delay="1400">
            <li class="crowd-box greenmoss" *ngFor="let listing of items | categoryFilter:1 | slice:0:4; let i = index"> 
              <div class="background"></div>
              <div class="leafybackground"></div>
              <div class="blueprint text"><a class="title" routerLink="/item/{{ listing.id }}">{{ listing.title }}</a>
                <div class="crowd-listing">{{ listing.description }}</div>
              </div>
              <div class="blueprint cover crowd-pleaser">
                <!-- routerLink="/item/{{ listing.id }}"-->
                <!-- (click)="changeBackground(1, i)"-->
                <button class="button buy" routerLink="/item/{{ listing.id }}"> <span>More Info</span></button>
                <div class="crowd-buy">
                  <div class="only">Only</div><span class="onsale">$ {{ listing.price }}<sup>.00</sup><span class="currency">{{ listing.currency }}</span></span><small *ngIf="listing.originalprice &gt; listing.price">${{ listing.originalprice }}<sup>.00</sup></small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="slider_bikes ms-slide" data-delay="10">
        <video data-mute="true" data-loop="true" data-fill-mode="fill">
          <source src="/assets/video/car.webm" type="video/webm">
          <source src="/assets/video/car.mp4" type="video/webm">
        </video>
        <div class="fence" data-effect="fade(long)" data-ease="easeOutQuint" data-duration="1500" data-delay="10"></div>
        <div class="ms-layer ms-caption slider-identity-title bike-title" data-effect="top(long)" data-duration="1500" data-delay="1200" data-ease="easeOutQuint">
          <div class="gradient"></div>
          <div class="borders">
            <div class="top"></div>
            <div class="bottom"></div>
          </div><span>Identity</span>
        </div>
        <div class="ms-garage-text-second ms-layer" data-effect="rotate3dbottom(80,0,0,short)" data-duration="1000" data-delay="1000" data-ease="easeOutQuint" data-hide-effect="fade"> 
          <div class="borders">
            <div class="top"></div>
            <div class="bottom"></div>
          </div><span>Bikes of all kinds</span>
        </div>
        <ul class="ms-layer ms-signs" data-effect="rotate3dtop(80,0,0,short)" data-duration="1000" data-delay="2500" data-ease="easeOutQuint" data-hide-effect="fade">
          <div class="borders">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
          <li><i class="icon road"></i><span>Own a Collection</span></li>
          <li><i class="icon eyedropper"></i><span>Customize Looks</span></li>
          <li><i class="icon wrench"></i><span>Testdrive and Tune</span></li>
        </ul>
        <div class="ms-layer slider-listings bottom house" data-effect="fade(long)" data-duration="4600" data-delay="2500" data-ease="easeOutQuint"> 
          <ul>
            <li class="crowd-box blueprint" *ngFor="let listing of items | categoryFilter:17 | slice:0:4; let i = index" data-effect="fade" data-duration="2500+(i*400)"> 
              <div class="background"></div>
              <div class="background-hex"></div>
              <div class="blueprint text"><a class="title" routerLink="/shop/item/{{ listing.id }}">{{ listing.title }}</a>
                <div class="crowd-listing">{{ listing.description }}</div>
              </div>
              <div class="blueprint cover crowd-pleaser">
                <!-- (click)="changeBackground(2, i)"-->
                <button class="button buy" routerLink="/item/{{ listing.id }}"> <span>More Info</span></button>
                <div class="crowd-buy">
                  <div class="only">Only</div><span class="onsale">$ {{ listing.price }}<sup>.00</sup><span class="currency">{{ listing.currency }}</span></span><small *ngIf="listing.originalprice &gt; listing.price">${{ listing.originalprice }}<sup>.00</sup></small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="ms-slide ms-house world-of-identity">
        <div class="fence" data-effect="fade(long)" data-ease="easeOutQuint" data-duration="1500" data-delay="10"></div>
        <video data-mute="true" data-loop="true" data-fill-mode="fill">
          <source src="/assets/video/apartment.webm" type="video/webm">
          <source src="/assets/video/apartment.mp4" type="video/mp4">
        </video>
        <div class="ms-layer ms-caption slider-identity-title" data-effect="top(long)" data-duration="1500" data-delay="1200" data-ease="easeOutQuint"> 
          <div class="gradient"></div>
          <div class="borders">
            <div class="top"></div>
            <div class="bottom"></div>
          </div><span>Identity</span>
        </div>
        <div class="ms-layer ms-caption slider-subtitle-background" data-effect="rotate3dleft(30,50,0,50)" data-duration="1500" data-delay="1600" data-ease="easeOutQuint" data-hide-effect="fade">
          <div class="ms-layer ms-caption slider-subtitle-top" data-effect="rotate3dtop(80,0,0,short)" data-duration="1500" data-delay="1600" data-ease="easeOutQuint" data-hide-effect="fade">
            <div class="borders">
              <div class="top"></div>
              <div class="bottom"></div>
            </div><span>Find your escape </span>
          </div>
          <div class="ms-layer ms-caption slider-subtitle2-top" data-effect="rotate3dtop(80,0,0,short)" data-duration="1500" data-delay="2000" data-ease="easeOutQuint" data-hide-effect="fade">
            <div class="borders">
              <div class="top"></div>
              <div class="bottom"></div>
            </div><span>In the world of Identity</span>
          </div>
        </div>
        <div class="ms-layer slider-listings bottom house" data-effect="fade(long)" data-duration="4600" data-delay="2500" data-ease="easeOutQuint"> 
          <ul>
            <li class="crowd-box blueprint" *ngFor="let listing of items | categoryFilter:1 | slice:0:4; let i = index" data-effect="fade" data-duration="2500+(i*400)"> 
              <div class="background"></div>
              <div class="background-grid"></div>
              <div class="blueprint text"><a class="title" routerLink="/shop/item/{{ listing.id }}">{{ listing.title }}</a>
                <div class="crowd-listing">{{ listing.description }}</div>
              </div>
              <div class="blueprint cover crowd-pleaser">
                <!-- (click)="changeBackground(2, i)"-->
                <button class="button buy" routerLink="/item/{{ listing.id }}"> <span>More Info</span></button>
                <div class="crowd-buy">
                  <div class="only">Only</div><span class="onsale">$ {{ listing.price }}<sup>.00</sup><span class="currency">{{ listing.currency }}</span></span><small *ngIf="listing.originalprice &gt; listing.price">${{ listing.originalprice }}<sup>.00</sup></small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fade"></div>
  </section>
  <div class="store-buttons"><a [attr.class]="flashCart ? 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue active' : 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue'" [@zFade]="flashCart" routerLink="/shop/checkout/">
      <div class="background"></div>
      <div class="background2"></div>
      <div class="cart-items">
        <div>{{ cart.length + ( custom ? custom.length:0 ) }}</div>
        <div class="small">{{ cart.length + ( custom ? custom.length:0 ) == 1 ? 'Item' : 'Items' }}</div>
      </div><i class="licon licon-cart-full"></i></a></div>
  <!-- +mixname-->
  <!-- +imagebox('let listing of items')-->
  <div class="cart-details" id="Overlay" [@cartoverlay]="showDetails" *ngIf="selectedProduct != null">
    <div id="Darkness" (click)="showDetails = 'hide'"></div>
    <div id="CartDetails" [@cartoverlay]="showDetails">
      <div class="box-borders">
        <div class="top"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="bottom"></div>
      </div>
      <div class="title">
        <div class="backgrounds">
          <div class="gradients"></div>
          <div class="stripes"></div>
        </div>
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div><i class="licon licon-cross-square close" (click)="showDetails = 'hide'"></i>
        <h2 *ngFor="let product of products | slice:selectedProduct:selectedProduct+1"> <a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h2>
      </div>
      <div id="Frame">
        <div id="InternalDetails">
          <div id="Loading" *ngIf="loadingCart"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>
          <div id="Item" *ngFor="let product of products | slice:selectedProduct:selectedProduct+1">
            <div class="row">
              <div id="ProductImages" *ngIf="product.images">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div><a routerLink="/shop/product/{{ product.id }}"><img class="slide" *ngFor="let image of product.images | imageSize:'listing'" [attr.src]="image.url"></a>
              </div>
              <div class="crowd-box" id="ProductDetails">
                <div class="crowd-details">
                  <h5 class="crowd-title"><a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h5>
                </div>
                <div class="crowd-description">{{ product.html }}</div>
              </div>
              <h5 class="included_items_title">Items Included</h5>
              <div class="included_items" id="Items" *ngIf="product.items.length &gt; 0">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div>
                <ul>
                  <li *ngFor="let item of product.items"><a routerLink="/shop/item/{{ item.id }}">
                      <div *ngIf="item.groupinfo"><i *ngIf="item.groupinfo.icon" [attr.class]="item.groupinfo.icon"></i></div><span>{{ item.title }}</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="Actions">
          <div class="direction-controls lightbox-controls">
            <div class="background"></div>
              <div class="double-borders">
                <div class="top"></div>
                <div class="bottom"></div>
                <div class="left-side">
                  <div class="left"></div>
                  <div class="top-left"></div>
                  <div class="bottom-left"></div>
                </div>
                <div class="right-side">
                  <div class="right"></div>
                  <div class="top-right"></div>
                  <div class="bottom-right"></div>
                </div>
              </div>
            <div class="next" *ngFor="let product of products | slice:selectedProduct+1:selectedProduct+2; let i = index;" (click)="nextCartItem()"><span>next</span><i class="licon licon-chevron-right"></i></div>
            <div class="prev" *ngFor="let product of products | slice:selectedProduct-1:selectedProduct; let i = index;" (click)="prevCartItem()"><i class="licon licon-chevron-left"></i><span>prev</span></div>
          </div>
          <div class="direction-controls">
            <div class="next" routerLink="/shop/product/{{ selectedProduct.id }}">
              <div class="background"></div>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div><span>View</span><i class="licon licon-bookmark2"></i>
            </div>
            <div class="prev" (click)="showDetails = 'hide'">
              <div class="background"></div>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div><i class="licon licon-cross2"></i><span>Close</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="Store">
  <div id="ProductsListing">
    <!--include ../cart.pug-->
    <div class="hashAncor">
      <div *ngIf="hideHeader == false">
          <div class="row title-row">
            <div class="border">
              <div class="top"></div>
              <div class="bottom"></div>
              <div class="bottom-line"></div>
            </div>
            <div class="container">
              <div class="background"></div>
              <div class="backgroundtwo"></div>
              <h5 class="left crowd-title">
                <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/popular">Popular Items</a>
              </h5><a class="right shop-link" routerLink="/store">
                <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">Identity Store</span></a>
            </div>
          </div>
      </div>
      <div class="container">
        <div class="row">
          <ul class="product_listings">
            <li class="row grid_4 crowd-box hiddenStagger" *ngFor="let listing of popularItems | slice:0:productsPerPage; let y = index" [@staggeredCards]="showCards &lt; y ? false : true">
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div>
              <div class="box-borders enlighten">
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="bottomline"></div>
                <div class="topline"></div>
              </div>
              <div class="background2"></div>
              <div class="Face" [@viewDesc]="viewPanel[y]">
                <div class="inner-box">
                  <div class="background"></div>
                  <div class="brighter-background"></div>
                  <div class="crowd-images">
                    <li *ngFor="let image of listing.images | imageSize:'listing' | slice:0:1; let y = index;"><a (click)="showDetails = true; selected = y"><img class="slide" src="{{ image.url }}"></a></li>
                  </div>
                  <h5 class="crowd-title"><a routerLink="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
                  <div class="description">{{ listing.description }}</div>
                  <div id="Beta"><i class="licon licon-warning"></i><span *ngIf="listing.beta &gt; 0">Includes Module Play</span></div>
                  <div id="Stock" *ngIf="listing.instock"><i [attr.class]="( listing.instock ? 'licon licon-check-square' : 'licon licon-cross-square' )"></i><span>{{ ( listing.instock ? (listing.stock > 0 ? listing.stock : '') + ' In Stock' : 'No Stock' ) }}</span></div>
                  <div id="Sale"><small *ngIf="listing.originalprice &gt; listing.price &amp;&amp; currency"><i class="licon licon-tags"></i><span (click)="flips = (flips ? false : true)">
                        <div [attr.class]=" flips ? 'line-through' : 'line-through rev' "></div><span>{{ listing.originalprice*currency.value | number:'1.2-2' }} {{ currency.symbol }}</span></span></small></div>
                  <div class="crowd-pleaser">
                    <div id="Currency" *ngIf="currency &amp;&amp; enableCurrencySelector" (click)="currencyOpen = ( currencyOpen &gt;= 0) ? -1 : y;"><span>{{ currency.symbol }}</span><i class="fa fa-angle-left" [@rotateDown]="(currencyOpen == y) ? true : false"></i>
                      <ul class="currencies" [@animateOpen]="(currencyOpen == y) ? true : false">
                        <li *ngFor="let c of currencies" (click)="changeCurrency(c)"><span>{{ c.symbol }}</span></li>
                      </ul>
                    </div>
                    <div class="crowd-buy" *ngIf="currency"><span class="onsale" *ngIf="currency">{{ listing.price*currency.value | number:'1.2-2' }} {{ currency.symbol }}</span></div>
                      <div id="PurchaseButton" [attr.class]="inCart(listing.id) ? 'active' : ''">
                        <button class="buy button add-to-cart" (click)="addToCart( listing )">
                            <div class="box-borders">
                              <div class="top"></div>
                              <div class="right"></div>
                              <div class="bottom"></div>
                              <div class="left"></div>
                            </div>
                          <div class="background"></div>
                          <div class="activeborders">
                            <div class="top"></div>
                            <div class="left"></div>
                            <div class="bottom"></div>
                            <div class="right"></div>
                          </div>
                          <div class="highlight"></div><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; !inCart(listing.id)">Add to Cart</span><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; inCart(listing.id)">Checkout</span><span *ngIf="(listing.limit != 0 &amp;&amp; listing.limit &lt;= listing.owns)">Player Owned</span>
                        </button>
                      </div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
              <div class="Back" [@viewInfo]="viewPanel[i]">
                <div class="inner-box">
                  <div class="back-view-icon" (click)="viewPanel[i] = (viewPanel[i]=='desc') ? 'info' : 'desc'">
                    <div class="left-arrow"></div>
                  </div>
                  <h5 class="crowd-title"><a routerLink="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
                  <hr>
                  <div class="crowd-items">
                    <ul>
                      <li *ngFor="let item of listing.items"><span>{{ item.title }}</span></li>
                    </ul>
                  </div>
                  <div class="crowd-pleaser">
                    <div class="crowd-buy"><span class="onsale"><span *ngIf="currency">{{ listing.price*currency.value | number:'1.2-2' }} {{ currency.symbol }}</span></span>
                      <!--small( *ngIf="listing.originalprice > listing.price" ) {{ listing.originalprice*currency.value |  number:'1.2-2' }} {{ currency.symbol }}-->
                    </div>
                    <button class="buy button add-to-cart" (click)="addToCart( listing, i )">
                      {{ ( listing.incart ? 'In Cart' : 'Add to Cart' ) }}
                      
                    </button>
                  </div>
                  <div class="beta available" *ngIf="listing.beta &gt; 0"><span>Includes Module Play</span></div>
                  <div class="stock" [@stock]="listing.instock">{{ ( listing.instock ? 'In Stock' : 'No Stock' ) }}</div>
                  <div class="clear"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>