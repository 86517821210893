
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="DevTracker">
  <div class="hashAncor" id="contentpage">
    <div class="container">
      <div class="title">
        <div class="title-container">
          <h2>UPDATES</h2>
        </div>
        <div class="decoration">
          <div class="title-marker"></div>
          <div class="sub-marker"></div>
        </div>
      </div>
      <div class="news-box" *ngFor="let item of news | slice:updates:updates+5; let i = index;">
        <div class="box-borders">
          <div class="top"></div>
          <div class="right"></div>
          <div class="bottom"></div>
          <div class="left"></div>
        </div>
        <time [attr.datetime]="item.start_date">
          <div class="background"></div>
          <div class="news-title">
            <h5>{{ item.title }}</h5>
          </div><span>{{item.start_date*1000 | date:'d'}}th</span>
          <div class="date">{{item.start_date*1000 | date:'MMM y'}}</div>
        </time>
        <div class="news-content">
          <p [innerHTML]="item.post"></p>
        </div>
        <ul class="news-links-list">
          <li><span class="fa fa-user"></span><a [attr.href]="item.author_url">{{ item.starter_name }}</a></li>
          <li><span class="fa fa-weixin"></span><a [attr.href]="item.comments_url">Comments</a></li>
        </ul>
        <div class="clear"></div>
      </div>
      <!--.controls
      a.prev.btn-primary( (click)="updates = updates-1", *ngIf="updates > 0" )
      	span prev
      	i.fa.fa-flex-left
      a.prev.btn-primary( routerLink="/updates/", *ngIf="updates == 0" ) See all updates
      a.next.btn-primary( (click)="updates = updates+1", *ngIf="updates < 5 ")
      	span next
      	i.fa.fa-fleupdates-right
      
      
      -->
      <div class="service-box">
        <div class="background"></div>
      </div>
    </div>
  </div>
</div>