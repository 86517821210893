
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="cart-details" id="Overlay" [@cartoverlay]="showDetails" *ngIf="selectedProduct != null">
  <div id="Darkness" (click)="showDetails = 'hide'"></div>
  <div class="user-claims-details" id="CartDetails" [@cartoverlay]="showDetails">
    <div class="box-borders">
      <div class="top"></div>
      <div class="left"></div>
      <div class="right"></div>
      <div class="bottom"></div>
    </div>
    <div class="title">
      <div class="backgrounds">
        <div class="gradients"></div>
        <div class="stripes"></div>
      </div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div><i class="licon licon-cross-square close" (click)="showDetails = 'hide'"></i>
      <h2 *ngFor="let product of products | slice:selectedProduct:selectedProduct+1"> <a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h2>
    </div>
    <div id="Frame">
      <div id="InternalDetails">
        <div id="Loading" *ngIf="loadingCart"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>
        <div id="Item" *ngFor="let product of products | slice:selectedProduct:selectedProduct+1">
          <div class="row">
            <div id="ProductImages" *ngIf="product.images">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div><a routerLink="/shop/product/{{ product.id }}"><img class="slide" *ngFor="let image of product.images | imageSize:'listing' | slice:0:1" [attr.src]="image.url"></a>
            </div>
            <div class="crowd-box" id="ProductDetails">
              <div class="crowd-details">
                <h5 class="crowd-title"><a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h5>
              </div>
              <div class="crowd-description">{{ product.description }}</div>
            </div>
            <div class="clear"></div>
            <div id="ProductDescription" [innerHTML]="product.html"></div>
            <div class="clear"></div>
            <h5 class="included_items_title" *ngIf="product.items.length &gt; 0">Items Included</h5>
            <div class="included_items" id="Items" *ngIf="product.items.length &gt; 0">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
              <ul>
                <li *ngFor="let item of product.items"><a routerLink="/shop/item/{{ item.id }}">
                    <div *ngIf="item.groupinfo"><i *ngIf="item.groupinfo.icon" [attr.class]="item.groupinfo.icon"></i></div><span>{{ item.title }}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="Actions" *ngFor="let product of products | slice:selectedProduct:selectedProduct+1">
        <div class="direction-controls">
          <div class="next"><a routerLink="/shop/product/{{ product.id }}">
              <div class="background"></div>
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div><span>View</span><i class="licon licon-bookmark2"></i></a></div>
          <div class="prev" (click)="showDetails = 'hide'">
            <div class="background"></div>
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div><i class="licon licon-cross2"></i><span>Close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="title-row">
  <h5>Your</h5>
  <h1>Claims</h1>
</div>
<div class="clear"> </div>
<div id="PurchasedProducts">
  <div id="StoreItems">
    <ul class="item_listings">
      <li class="row grid_4 crowd-box" *ngFor="let listing of products; let i = index">
        <div class="bg"></div>
        <div class="background"></div>
        <ul id="ProductImages">
          <li *ngFor="let image of listing.images | imageSize:'listing' | slice:0:1"><a (click)="showProduct(i)"><img class="slide" src="{{ image.url }}"></a></li>
        </ul>
        <div id="ProductDetails">
          <div class="copies" *ngIf="listing.quantity &gt; 1">
            <div class="background"></div><span>x{{ listing.quantity }}</span>
          </div>
          <div class="corners">
            <div class="left"></div>
            <div class="right"></div>
            <div class="bottomleft"></div>
            <div class="bottomright"></div>
            <div class="topline"></div>
          </div>
          <div class="crowd-title">
            <div class="borders">
              <div class="top"></div>
              <div class="bottom"></div>
            </div>
            <h5><a routerLink="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
            <div class="crowd-description"><a routerLink="/shop/product/{{ listing.id }}">{{ listing.description }}</a></div>
          </div>
          <div class="actions-menu" (mouseenter)="showActions[i] = true" (mouseleave)="showActions[i] = false"><i class="menu-icon fa fa-ellipsis-v"></i>
            <ul class="crowd-actions" [@actionsMenu]="showActions[i]">
              <li class="share" (click)="shareItem(listing);"><span class="licon licon-share3"></span><span>Share Link</span></li>
            </ul>
          </div>
        </div>
        <div class="clear"></div>
      </li>
      <li class="row grid_4 crowd-box loader" *ngIf="hasProducts == &quot;null&quot;">
        <div class="image"></div>
        <div class="title-line"></div>
        <div class="description-line"></div>
        <div class="description-line-two"></div>
        <div class="description-line-three"></div>
        <div class="background"></div>
      </li>
      <li class="row grid_4 crowd-box loader" *ngIf="hasProducts == &quot;null&quot;">
        <div class="image"></div>
        <div class="title-line"></div>
        <div class="description-line"></div>
        <div class="description-line-two"></div>
        <div class="description-line-three"></div>
        <div class="background"></div>
      </li>
      <li class="row grid_4 crowd-box loader" *ngIf="hasProducts == &quot;null&quot;">
        <div class="image"></div>
        <div class="title-line"></div>
        <div class="description-line"></div>
        <div class="description-line-two"></div>
        <div class="description-line-three"></div>
        <div class="background"></div>
      </li>
      <li class="row grid_4 crowd-box loader" *ngIf="hasProducts == &quot;null&quot;">
        <div class="image"></div>
        <div class="title-line"></div>
        <div class="description-line"></div>
        <div class="description-line-two"></div>
        <div class="description-line-three"></div>
        <div class="background"></div>
      </li>
    </ul>
  </div>
</div>