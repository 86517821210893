
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="home-page" id="content">
  <div id="Home">
    <!-- Full Page Slider-->
    <section id="HomeFeatures" #Slider>
      <home-features></home-features>
    </section>
    <section id="AdditionalVideos" #Buttons *ngIf="showVideos">
      <div class="row disabled-onepage-scroll" id="VideoBay" [@videoPanel]="showIntro ? 'flase' : 'true'" (swipeleft)="v = (v &lt; numberOfVideos-videosOnScreen) ? v+videosOnScreen : v;" (swiperight)="v = (v &gt; 0) ? v-videosOnScreen : v;" [style.touch-action]="'pan-y'">
        <div class="container">
          <ul class="video-selection row">
            <li *ngFor="let video of videos | slice:v:v+videosOnScreen; let i = index" [attr.id]="video.id" [attr.class]="(videoSelection.id == video.id) ? 'video active' : 'video'" (click)="playVideo(video)">
              <ul class="v-thumbnails">
                <li *ngFor="let i of video.images"><img src="{{ i.url }}"></li>
              </ul>
              <div class="v-title">{{ video.title }}</div><a class="v-user" target="_blank" href="{{ video.user_url }}">{{ video.user }}</a>
              <div class="v-time">{{ video.duration }}</div>
              <div class="v-source">
                <div class="local" *ngIf="video.type == 'local'"><i class="licon licon-clapboard-play"></i><span>Official</span></div>
                <div class="youtube" *ngIf="video.type == 'youtube'"><i class="fa fa-youtube-play"></i><span>YouTuber</span></div>
                <div class="twittch" *ngIf="video.type == 'twittch'"><i class="fa fa-twittch"></i><span>Twittch</span></div>
              </div>
              <div class="background"></div>
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="controls" *ngIf="numberOfVideos &gt; videosOnScreen">
          <div class="next" [@enabled]="v &lt; numberOfVideos-videosOnScreen" (click)="v = (v &lt; numberOfVideos-videosOnScreen) ? v+videosOnScreen : v"><i class="licon licon-chevron-right"></i></div>
          <div class="prev" [@enabled]="v &gt; 0" (click)="v = (v &gt; 0) ? v-videosOnScreen : v;"><i class="licon licon-chevron-left"></i></div>
        </div>
      </div>
    </section>
    <section class="news-section" id="info" #Updates>
      <div class="container">
        <!-- Developer Tracker-->
        <div class="services-side">
          <div class="title">
            <div class="title-container">
              <h2>DEV TRACKER</h2>
            </div>
            <div class="decoration">
              <div class="title-marker"></div>
              <div class="sub-marker"></div>
            </div>
          </div>
          <div class="services-list">
            <div class="load" *ngIf="loadedPosts == 'null'">
              <div class="service-box service-1 loader" *ngFor="let item of createRange(3); let i = index;">
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div>
                <div class="swooshy"></div>
                <div class="service-name">
                  <div class="background"></div><a>
                    <div class="service-icon"></div>
                    <div class="service-title"></div>
                    <div class="service-poster"></div>
                    <time class="service-time"><span></span></time></a>
                </div>
              </div>
            </div>
            <div class="loading-services" *ngIf="loadedPosts == 'true'">
              <div class="service-box service-1" *ngFor="let post of devPosts | slice:tracker:tracker+1">
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div>
                <div class="service-name">
                  <div class="background"></div><a href="https://www.identityrpg.com/community/index.php?/topic/{{ post.topic_id }}-{{ post.title_seo }}/&amp;do=findComment&amp;comment={{ post.pid }}">
                    <div class="service-icon"><img src="https://www.identityrpg.com/community/uploads/{{ post.author?.pp_thumb_photo }}"></div>
                    <div class="service-title">{{ post.title }}</div>
                    <div class="service-poster">{{ post.author?.name }}</div>
                    <time class="service-time" datetime="post.post_date"><span>{{ post.post_date*1000 | date:'d'}}th</span><span>{{ post.post_date*1000 | date:'MMM y' }}</span></time></a>
                </div>
                <div class="service-text">
                  <div class="service-description" [innerHTML]="post.post"></div>
                </div>
                <ul class="buttons">
                  <li><a href="https://www.identityrpg.com/community/index.php?/topic/{{ post.topic_id }}-{{ post.title_seo }}/&amp;do=findComment&amp;comment={{ post.pid }}"><i class="licon licon-enter-right"></i></a></li>
                </ul>
                <div class="clear"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="align showMobile"><a class="info-button red" routerLink="/devtracker"><span>See more posts</span></a></div>
          <!--.controls
          .next( (click)="tracker = tracker+1", *ngIf="tracker < 2 ")
          	span next
          	i.fa.fa-fleupdates-right
          .prev( (click)="tracker = tracker-1", *ngIf="tracker > 0" )
          	span prev
          	i.fa.fa-flex-left
          
          -->
        </div>
        <div class="news-side">
          <div class="title">
            <div class="title-container">
              <h2>UPDATES</h2>
            </div>
            <div class="decoration">
              <div class="title-marker"></div>
              <div class="sub-marker"></div>
            </div>
          </div>
          <div class="load" *ngIf="loadedUpdates == 'null'">
            <div class="news-box loader" *ngFor="let item of createRange(2); let i = index;">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
              <div class="swooshy"></div>
              <time>
                <div class="background"></div><a class="news-title">
                  <h5></h5></a><span></span>
                <div class="date"></div>
              </time>
              <div class="news-content"></div>
              <div class="clear"></div>
            </div>
          </div>
          <div class="loading-updates" *ngIf="loadedUpdates == 'true'">
            <div class="news-box" *ngFor="let item of news | slice:updates:updates+5; let i = index;">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
              <time [attr.datetime]="item.start_date">
                <div class="background"></div><a class="news-title" href="http://www.identityrpg.com/{{ item.url }}">
                  <h5>{{ item.title }}</h5></a><span>{{item.start_date*1000 | date:'d'}}th</span>
                <div class="date">{{item.start_date*1000 | date:'MMM y'}}</div>
              </time>
              <div class="news-content">
                <p [innerHTML]="item.post | truncate:limit[i]" [@newsContent]="(limit[i] &gt; condensedChars)? true : false"></p>
                <div class="news-link" (click)="showMore(i)" *ngIf="limit[i] &gt; condensedChars"><span>Show less...</span></div>
                <div class="news-link" (click)="showMore(i)" *ngIf="limit[i] == condensedChars"><span>Show more...</span></div>
              </div>
              <ul class="news-links-list">
                <li><span class="fa fa-user"></span><a [attr.href]="item.author_url">{{ item.starter_name }}</a></li>
                <li><span class="fa fa-weixin"></span><a [attr.href]="item.comments_url">Comments</a></li>
              </ul>
              <div class="clear"></div>
            </div>
            <div class="align showMobile updates"><a class="info-button blue" routerLink="/devtracker"><span>See all updates</span></a></div>
            <!--.controls
            a.prev.btn-primary( (click)="updates = updates-1", *ngIf="updates > 0" )
            	span prev
            	i.fa.fa-flex-left
            a.prev.btn-primary( routerLink="/updates/", *ngIf="updates == 0" ) See all updates
            a.next.btn-primary( (click)="updates = updates+1", *ngIf="updates < 5 ")
            	span next
            	i.fa.fa-fleupdates-right
            
            -->
          </div>
        </div>
        <div class="clear"></div>
        <div class="align hideMobile">
          <div class="left"><a class="info-button red" routerLink="/devtracker"><span>See all updates</span></a></div>
          <div class="right"> <a class="info-button blue" routerLink="/updates"><span>See all updates</span></a></div>
        </div>
      </div>
    </section>
    <section class="show_state" id="BottomSection" #Stats><a name="signup"></a>
      <div id="statistics">
        <div id="customerfunnel" [attr.class]="(bottomSignup == true) ? 'active' : ''">
          <div class="container">
            <div id="MiniSignup">
              <div class="border-bottom"></div><img class="smokeblow" src="/assets/img/patterns/smoke_spot.png"><img class="smoke_spot" src="/assets/img/patterns/smoke_box.png"><img class="smokeylines left" src="/assets/img/patterns/smokey_line.png"><img class="smokeylines right" src="/assets/img/patterns/smokey_right_line.png">
              <mini-signup></mini-signup>
            </div>
          </div>
        </div>
        <div class="background_video"><a class="credit" href="https://www.youtube.com/watch?v=PK3jeK8ghVg">Video Credit: Wailander</a>
          <video class="html video-player ng-tns-c2-1" autoplay="true" loop="true" muted="true">
            <source class="ng-tns-c2-1" src="/assets/video/looping_movie.mp4" type="video/mp4">
          </video>
        </div>
        <div class="container statscontainer">
          <div class="title">
            <div class="title-container">
              <h2 class="statistics_header">STATISTICS</h2>
            </div>
            <div class="decoration">
              <div class="sub-marker"></div>
              <div class="title-marker"></div>
              <div class="title-marker-bottom"></div>
            </div>
          </div>
          <div class="statistics_panel">
            <div id="StatFigures" (swipeleft)="nextStats()" (swiperight)="prevStats()">
              <div class="stat" *ngFor="let statistic of statistics | slice:s:s+statsOnScreen">
                <div class="box-1">
                  <div class="skill-number">{{ statistic.name == 'Total Raised' ? '$' : '' }} {{ statistic.statistic | number }}</div>
                  <!--.skill-number( [countUp]="{useEasing: true}", [startVal]="0", [endVal]="{{ statistic.statistic }}", [reanimateOnClick]="false" ) {{ statistic.statistic | number }}-->
                  <div class="skill-title">{{ statistic.name }}</div>
                </div>
              </div>
              <div class="controls" *ngIf="statsOnScreen &lt; numberOfStatistics">
                <div class="next" (click)="nextStats()"><i class="licon licon-chevron-right"></i></div>
                <div class="prev" (click)="prevStats()"><i class="licon licon-chevron-left"></i></div>
              </div>
            </div>
            <div class="stat become-a-citizen" (click)="bottomSignup = ( bottomSignup == true ) ? false : true">
              <div class="box-1 box-1__active">
                <div class="bg"></div>
                <div class="skill-header">JOIN</div>
                <div class="skill-title">Become a Citizen!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>