
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div class="hashAncor Four0Four" id="contentpage">
  <div class="container">
    <div class="row"></div>
    <div class="row title-row">
      <div class="border">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="bottom-line"></div>
      </div>
      <div class="container">
        <div class="background"></div>
        <div class="backgroundtwo"></div>
        <h5 class="left crowd-title">
          <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/">Page Not Found</a>
        </h5><a class="right shop-link" routerLink="/shop">
          <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">Shop</span></a>
      </div>
    </div>
    <div id="AdditionalItems">
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div>
      <ul>
        <li class="community"><a href="http://www.identityrpg.com/community/">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
            <div class="background"></div>
            <div class="title"> 
              <div class="titlebackground"></div><span>Community</span>
            </div></a></li>
        <li class="game-shop"><a routerLink="/shop">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
            <div class="background"></div>
            <div class="title">
              <div class="titlebackground"></div><span>Game Shop</span>
            </div></a></li>
        <li class="about-identity"><a routerLink="/info">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
            <div class="background"></div>
            <div class="title">
              <div class="titlebackground"></div><span>About Identity</span>
            </div></a></li>
      </ul>
    </div>
  </div>
</div>