
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="ActivatePage">
  <div class="hashAncor" id="contentpage">
    <div class="container">
      <div class="row">
        <div id="error" *ngIf="activationerr == true" (click)="activationerr = false">{{ activationerr }}</div>
        <div id="success" *ngIf="activationsuccess == true" (click)="activationsuccess = ''">{{ activationsuccess }}</div>
        <div class="row active-container">
          <h2 class="actication-title">Activate Your Account</h2>
          <p class="activation-prompt">Enter the activation key below that you have received via email:</p>
          <form id="ActivationKey">
            <input type="text" [(ngModel)]="activation.key" name="activationkey">
            <button class="button activate-account" (click)="activate()">Activate</button>
          </form>
        </div>
        <div class="row">
          <div class="account-active" *ngIf="accountactive">
            <div class="icon-container"><i class="icon icon-shield"></i></div>
            <h2>Account Activated</h2>
            <p class="success">Your account has been activated. Thank you for your cooperation!</p>
            <ul class="post-activation-options">
              <li><a routerLink="/shop">Shop Claims</a></li>
              <li><a routerLink="/account/view">Your Identity</a></li>
              <li><a href="http://www.identityrpg.com/community/">Community Forums</a></li>
            </ul>
          </div>
          <div class="account-inactive" *ngIf="!accountactive">
            <h2>Account Not Active</h2>
            <p>Your account has not been linked with an e-mail address. Certain account privileges may be restricted. Please use the buttons below to help activate your account. Access to your email account is requested. Please copy the activation key from your email into this website. </p>
          </div>
        </div>
        <!-- Login Options-->
        <login-options></login-options>
      </div>
    </div>
  </div>
</div>