
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<section id="AboutTheGame">
  <div class="master-slider ms-skin-default features" id="featureSlider">
    <div class="ms-bullets"></div>
    <div class="Information ms-slide" data-delay="30">
      <video data-mute="true" data-loop="true" data-fill-mode="fill">
        <source src="/assets/video/video.mp4" type="video/mp4">
        <source src="/assets/video/video.webm" type="video/webm">
      </video>
      <div class="topFront ms-layer">
        <div [attr.class]="(titlePanel == true) ? 'logo hidden' : 'logo'"><img src="/assets/img/IdentityText_shadow.png"></div>
      </div>
      <div class="Feature ms-layer">
        <section>
          <div [attr.class]="(titlePanel == true) ? 'titlePanel open titleClosed' : 'titlePanel open'">
            <div class="slogan blue">
              <div class="close" (click)="titlePanel = (titlePanel == true) ? false : true"><i class="licon licon-cross" *ngIf="titlePanel == false"></i><i class="licon licon-rank" *ngIf="titlePanel == true"></i></div>
              <div class="coloured"></div>
              <div class="background" [style.background-position]="bgPos"></div><span>
                <h4>Changing what it means to be an MMORPG</h4>
                <p>For over a decade the term MMORPG (massively multiplayer role-playing game) has been associated with grinding and repetitive gameplay. With the help of its supporters, Identity is changing that and restoring the genre to its roots: an open world free of restrictions with a destiny determined by the players. </p>
                <div class="center"><a routerLink="/create">
                    <div class="background"></div>
                    <div class="learn">Sign Up</div></a><a class="right" routerLink="/info">
                    <div class="background"></div>
                    <div class="learn">Learn More</div></a></div></span>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="Law ms-slide" data-delay="30">
      <video class="sliderVideo" #copvideo data-loop="true" data-autopause="false" autoplay="true" muted>
        <source src="/assets/video/cops.mp4" type="video/mp4">
        <source src="/assets/video/cops.webm" type="video/webm">
      </video>
      <div class="ms-layer marqueeFeature">
        <div class="gameFeatInfo">
          <div class="container">
            <div class="featureTitle">
              <div class="left"></div>
              <div class="top"></div>
              <div class="right"></div>
              <div class="background"></div>
              <h2 class="title">Fight for Control</h2>
            </div>
          </div>
        </div>
        <div class="gameDescription">
          <div class="container">
            <p class="featureDescription">Identity’s world features the ever prominent power struggle between law enforcement and criminal organizations. Take shape as a heroic police officer to protect the innocent, or lose yourself to greed in a life of nefarious activity. Will you assist the common good, or contribute to the madness?</p>
            <div class="border-top"></div>
            <div class="border-bottom"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="Home ms-slide" data-delay="30">
      <video class="sliderVideo" data-loop="true" data-autopause="false" autoplay="true" muted>
        <source src="/assets/video/apartment.mp4" type="video/mp4">
        <source src="/assets/video/apartment.webm" type="video/webm">
      </video>
      <div class="ms-layer marqueeFeature">
        <div class="gameFeatInfo">
          <div class="container">
            <div class="featureTitle">
              <div class="left"></div>
              <div class="top"></div>
              <div class="right"></div>
              <div class="background"></div>
              <h2 class="title">Express Yourself</h2>
            </div>
          </div>
        </div>
        <div class="gameDescription">
          <div class="container">
            <p class="featureDescription">Cultivate a unique atmosphere by purchasing a living space and decorating it as you see fit! Players can show off their creative side by writing books for the library, singing karaoke for the bar crowds, and painting works of art that can be displayed in the local gallery. Leave your mark on the world, and establish your true Identity.</p>
            <div class="border-top"></div>
            <div class="border-bottom"></div>
          </div>
        </div>
      </div>
      <div class="ms-layer centre-image">
        <div class="SliderVideoContainer" data-effect="bottom(long)" data-duration="1500" data-delay="500" data-ease="easeOutQuint" data-hide-effect="fade"></div>
      </div>
    </div>
    <div class="Money ms-slide" data-delay="30">
      <video class="sliderVideo" #shopvideo data-loop="true" data-autopause="false" autoplay="true" muted>
        <source src="/assets/video/shop.mp4" type="video/mp4">
        <source src="/assets/video/shop.webm" type="video/webm">
      </video>
      <div class="Feature ms-layer">
        <section>
          <div class="titlePanel large">
            <div class="slogan">
              <div class="coloured"></div>
              <div class="background" [style.background-position]="bgPos"></div><span>
                <h4>Big Money &amp; Big Business</h4>
                <p>Show the world you mean business by establishing your own. Keep the money rolling by supplying your storefront with inventory, or launch a corporation that specializes in one of four particular industries. Undercut your rivals by offering the superior service and reap the benefits that come with having an excellent reputation and massive income.</p>
                <div class="center"><a class="right" routerLink="/info/corp">
                    <div class="background"></div>
                    <div class="learn">Corporate Information</div></a></div></span>
            </div>
          </div>
        </section>
      </div>
      <div class="ms-layer centre-image">
        <div class="SliderVideoContainer" data-effect="bottom(long)" data-duration="1500" data-delay="500" data-ease="easeOutQuint" data-hide-effect="fade"></div>
      </div>
    </div>
    <!-- Video Popup Panel-->
  </div>
</section>