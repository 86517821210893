
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="faq">
  <div class="hashAncor" id="contentpage">
    <h2 class="heading_color">
      <div class="background"></div>
      <div>Questions and Answers</div>
    </h2>
    <div class="container">
      <div class="row">
        <p style="margin-top:0;">Below are the most commonly asked questions which we receive followed by a current answer. If you have another question for the Identity development team, feel free to ask on our forums or on our </p><a href="http://twitter.com/identityrpg?lang=en">Twitter</a>
        <div class="faq-section" id="General">
          <h5 (click)="easter($event)"><span>General</span></h5>
          <accordion>
            <accordion-group heading="What is the expected release date of Identity?">
              <p> <span>While there’s no ETA for the full release of Identity, users will be able to monitor Asylum’s progress on the game through the use of our “Task Trackers”. Each of Identity’s Modules will have an extensive list of tasks required for its completion, and you can follow along with Town Square’s progress </span><a routerLink="/info/modules/townsquare/">here</a><span>. Identity will have three Modules release throughout its development before the full game is available.</span></p>
            </accordion-group>
            <accordion-group heading="What are the Identity modules?">
              <p>The Modules are small, playable pieces of Identity that are available to those who have purchased the "Beta Access"  item from the shop. Each Module will explore a particular theme of features that will later be found in the final game. With this, users are given a chance to play segments of Identity early while also providing critical feedback on how we can improve the experience.</p>
            </accordion-group>
            <accordion-group heading="What exactly is Identity all about?">
              <p>Identity is a cops and robbers game at heart, but it is a life simulator in so many other ways; immersion is one of our key focuses. We're catering to role-play in a way that is possibly the first of its kind. Players have the freedom to pursue careers that will impact the player economy in their own unique way. Become a taxi driver, deliver goods to player-owned businesses, fight fires, save lives, argue a case in criminal court, or just hold out your hand and beg for money. Find, or take by force, your <em>'identity'!</em></p>
            </accordion-group>
            <accordion-group heading="What can you tell us about the world? World size? Player limit?">
              <p>We anticipate that the playable world size will be 225 square kilometers, or approximately 87 square miles in comparison. This means that the whole map is 15 km wide, and would take about an hour and a half to walk across. We hope to support somewhere between 300 and 500 concurrent players per server.</p>
            </accordion-group>
          </accordion>
        </div>
        <div class="faq-section" id="Environment">
          <h5 (click)="easter($event)"><span>Environment</span></h5>
          <accordion>
            <accordion-group heading="Where does Identity take place?">
              <p>The world in Identity is based on the southeastern United States, specifically parts of North and South Carolina and Georgia. This allows us a lot of variety in environment biomes, from snowy mountains to wet swamps.</p>
            </accordion-group>
            <accordion-group heading="Can you expand on how the housing system will work? Size and availability of apartments and houses?">
              <p>Players can choose to own or rent housing in Identity, while apartments can also be shared with a friend or roommate depending on the size of the property. For instance, two friends, or a married couple can choose to purchase a 2 bedroom apartment where they both can live comfortably with each other. Apartments and houses are fully customizable, allowing players to place furniture and objects anywhere in the room. Home-owners can invite other players into their apartment or house at any time. Players cannot access another players home without an invitation.</p>
            </accordion-group>
            <accordion-group heading="What if all of the houses have been bought by other players?">
              <p>While houses in the world are unique and can only be owned by one person at a time, apartments are always available. Furthermore, players who own houses in the world can list them on a property market or rent them out so that they can still be yours if you can afford it.</p>
            </accordion-group>
            <accordion-group heading="Will there be any specific safe areas where people can go AFK or sit and socialize without being killed or robbed?">
              <p>There are safe zones within certain types of buildings in Identity. Metal detectors serve to preserve immersion, and will temporarily remove any weapons upon entry, allowing all players in the area to safely relax, socialize and meet new friends. These safe zones are known to us as “Sub-Hubs” or social gathering points that reflect on the lifestyle theme of their environment (biker bar, business club, bum alley, etc). In contrast, some areas are considered hostile and violence is completely uncontrolled.</p>
            </accordion-group>
          </accordion>
        </div>
        <div class="faq-section" id="Careers">
          <h5 (click)="easter($event)"><span>Careers and Jobs</span></h5>
          <accordion>
            <accordion-group heading="What's the difference between a career and a job?">
              <p>A job is a task which anybody can attempt at any given time, while a career is a dedicated path players can follow with its own progression and growth. For example, hunting animals and delivering pizza are jobs, although the police force and fire department are careers with ranks, rewards and achievements of their own.</p>
            </accordion-group>
            <accordion-group heading="Can I have many careers at once?">
              <p>You can only belong to one career at a time. You can change careers when you wish, but you may face a small penalty in the career you're abandoning.</p>
            </accordion-group>
            <accordion-group heading="If I become a police officer, can I only play as a cop?">
              <p>No, by default you are always a civilian and you need to activate your career mode. For police this means visiting a police station and suiting up at your locker. You will then be in your career mode until you return to civilian status the same way.</p>
            </accordion-group>
          </accordion>
        </div>
        <div class="faq-section" id="Interaction">
          <h5 (click)="easter($event)"><span>Player Interaction</span></h5>
          <accordion>
            <accordion-group heading="How will player identification work in game? Will names appear above players heads?">
              <p>As our design stands right now, the official servers do display names over heads, within certain ranges, only when your cursor/crosshair is directly over them. Some things like masks and balaclavas can affect this. Moreover, private servers have an option where they can hide names from all players unless greeted. By this I mean you won’t see the name over another player until they perform an emote action, after which you’ll always see their name.</p>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</div>