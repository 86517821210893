
<div id="Hood"></div>
<div class="TopRight" *ngIf="(menuHood &gt; 0) ? true : false"></div>
<div id="TopBar">
  <loading-bar #loadingBar [height]="3" color="#1e62d7" [animationTime]="0.5"></loading-bar>
</div>
<div class="menu-switcher-panel" id="Menu" [@menu]="scrollState" [style.top]="(menuHood) ? menuHood+'px' : '0px'">
  <div id="TopMarker" *ngIf="(menuHood &gt; 0) ? true : false">
    <div class="left"></div>
    <div class="right"></div>
  </div>
  <div id="LeftMarker" [@marker]="scrollState"><i class="search fa fa-search" (click)="toggleSearch()"></i>
    <input class="search-bar" type="text" placeholder="search identity" [(ngModel)]="query.search" [@searchMenu]="search" (keyup)="doSearch($event)" #sitesearch>
  </div>
  <ul id="SearchResults" [@searchResults]="searching" (click)="searching = 'false';query.search = '';">
    <li *ngFor="let account of searchResultsAccount">
      <div class="underglow"></div><span class="image"><img src="{{ account.image }}"></span><a routerLink="/id/{{ account.username }}"><span class="username">{{ account.username }}</span></a>
    </li>
    <li *ngFor="let item of searchResultsItem">
      <div class="underglow"></div><a routerLink="/item/{{ item.id }}"><span>{{ item.title }}</span></a>
    </li>
    <li *ngFor="let product of searchResultsProduct">
      <div class="underglow"></div><a routerLink="/product/{{ product.id }}"><span>{{ product.title }}</span></a>
    </li>
  </ul>
  <div id="RightMarker" [@marker]="scrollState"></div>
  <div id="BottomMarker" [@bottommarker]="scrollState">
    <div class="background_one"></div>
    <div class="background_two"></div>
  </div>
  <div class="menu-content">
    <div id="TopBackground"></div>
    <div id="BottomBackground" [@bottombar]="scrollState">
      <div class="centralMarkers">
        <div class="bottomleft"></div>
        <div class="bottomright"></div>
      </div>
    </div><a class="nav-link" id="IdentityLogo" routerLink="/home" tabindex="1" [@logostate]="scrollState"><img class="white" id="IDLogo" src="/assets/img/logo-small.png"></a>
    <ul id="Menus" [@mainMenu]="scrollState" [@mobilemenu]="showMainMenu">
      <div class="background"></div>
      <div class="army-background"></div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <li>
        <ul class="aside-menu" id="LeftMenu" [@leftmenu]="scrollState">
          <li [@leftmenuitem]="scrollState"><a routerLink="/">
              <div class="title"><span>Home</span>
                <div class="underline"></div>
              </div>
              <!--.image( style="background: url(/assets/img/concept/Final3.jpg);background-size: cover; background-position-y: 90% !important;")--></a></li>
          <li [@leftmenuitem]="scrollState" (click)="hideMenus()"><a tabindex="4" routerLink="/shop">
              <div class="title"> <span>Shop</span>
                <div class="underline"></div>
              </div></a></li>
          <li [@leftmenuitem]="scrollState"><a routerLink="/modules">
              <div class="title"><span>Modules</span>
                <div class="underline"></div>
              </div></a></li>
        </ul>
      </li>
      <li>
        <ul class="aside-menu" id="RightMenu" [@rightmenu]="scrollState">
          <li [@rightmenuitem]="scrollState"><a routerLink="/info">
              <div class="title"> <span>About</span>
                <div class="underline"></div>
              </div>
              <div class="image" style="background: url(/assets/img/concept/Final3.jpg);background-size: cover; background-position-y: 90% !important;"></div></a></li>
          <li [@rightmenuitem]="scrollState" (click)="hideMenus()"><a routerLink="/faq">
              <div class="title"> <span>FAQ</span>
                <div class="underline"></div>
              </div>
              <div class="image" style="background: url(/assets/img/header-faq.jpg);background-size: cover;background-position-y: 90% !important;"></div></a></li>
          <li [@rightmenuitem]="scrollState"><a href="http://www.identityrpg.com/community/">
              <div class="title"> <span>Community</span>
                <div class="underline"></div>
              </div>
              <div class="image" style="background: url(/assets/img/concept/Final3.jpg);background-size: cover; background-position-y: 90% !important;"></div></a></li>
        </ul>
      </li>
    </ul>
    <div id="Expansion">
      <!-- incorporate a floating list of items-->
      <ul class="megamenu-static products thin" [@menuSubItem]="scrollState" [@submenu]="showShopSubmenu" [style.left]="menuOffset" (mouseleave)="hideMenus()">
        <div class="bottom-border"></div>
        <li class="menu-product" *ngFor="let listing of storeProducts"><a routerLink="/product/{{ listing.id }}">
            <div class="icon"><i class="fa {{listing.icon}}"></i></div>
            <div class="title">
              <h2 class="title">{{ listing.title }}</h2><span class="description">{{ listing.description }}</span>
            </div></a></li>
      </ul>
      <!-- incorporate a floating list of items-->
      <ul class="megamenu-static items thin" [@menuSubItem]="scrollState" [@submenu]="showItemsSubmenu" [style.left]="menuOffset" (mouseleave)="hideMenus()">
        <div class="bottom-border"></div>
        <li class="menu-product" *ngFor="let listing of storeItems"><a routerLink="/item/{{ listing.id }}">
            <div class="icon"><i class="fa {{listing.icon}}"></i></div>
            <div class="title">
              <h2 class="title">{{ listing.title }}</h2><span class="description">{{ listing.description }}</span>
            </div></a></li>
      </ul>
      <!-- Extra large feature menu-->
      <ul class="megamenu expand" [@largesubmenu]="showAboutSubmenu" (mouseleave)="hideMenus()">
        <li><a routerLink="/info" (click)="hideMenus()">
            <div class="title">
              <h2>Welcome to Identity</h2><span>A new kind of RPG</span>
            </div>
            <div class="image" style="background: url(/assets/img/concept/Final3.jpg);background-size: cover; background-position-y: 77% !important;"></div></a></li>
        <li><a routerLink="/modules" (click)="hideMenus()">
            <div class="title">
              <h2>Game Modules</h2><span>Play before release</span>
            </div>
            <div class="image" style="background: url('/assets/img/tsscreen.jpg'); background-position: 47% 39%;"></div></a></li>
        <li><a routerLink="/faq" (click)="hideMenus()">
            <div class="title">
              <h2>FAQ </h2><span>Answers from the team</span>
            </div>
            <div class="image" style="background: url(/assets/img/header-faq.jpg);background-size: cover;background-position-y: 90% !important;"></div></a></li>
      </ul>
    </div>
    <!-- Left Side-->
    <div id="LeftSide">
      <div class="background"></div>
      <div class="bottomLines">
        <div class="right">
          <!-- showLoginField = showLoginField ? false : true-->
        </div>
      </div>
      <div id="SearchMenu" [@login]="scrollState"> <a [attr.class]="(search=='true') ? 'active' : ''" tabindex="6" (click)="toggleSearch()" [@menuUser]="scrollState">
          <div class="profile-tag"><i class="licon licon-magnifier"></i>
            <div class="profile name">Search</div>
          </div></a></div>
      <div (click)="showMainMenu = showMainMenu == true ? false : true" [@menuBars]="scrollState" [attr.class]="(showMainMenu == true) ? 'mobile-menu active' : 'mobile-menu'">
        <div class="lines">
          <div class="one"></div>
          <div class="two"></div>
          <div class="three"></div>
        </div>
      </div>
    </div>
    <!-- Right Side -->
    <div id="RightSide" (mouseenter)="showAccountSubmenu=true;" (mouseleave)="showAccountSubmenu=false;">
      <div class="background"></div>
      <div class="bottomLines">
        <div class="right"></div>
      </div>
      <div id="User" *ngIf="loggedIn">
        <div id="UserMenu" *ngFor="let user of accounts" [@login]="scrollState"><a routerLink="/account/view/profile" [@menuUser]="scrollState" tabindex="6">
            <div class="box-borders">
              <div class="left"></div>
            </div>
            <div class="background"></div>
            <div class="profile-tag">
              <div class="profile pic"><img src="{{ user.image }}"></div>
              <div class="profile name">{{ user.username }}</div>
            </div></a></div>
      </div>
      <div id="User" *ngIf="!accounts">
        <div id="UserMenu" [@login]="scrollState"> <a routerLink="/login" [@menuUser]="scrollState" (click)="showLoginField = showLoginField ? false : true" tabindex="6">
            <div class="profile-tag">
              <div class="profile name"> Login or Sign Up</div>
            </div></a></div>
      </div>
      <div id="LoginFields" [@RightLogin]="showLoginField" *ngIf="!user">
        <div class="bars">
          <div class="bar one"></div>
          <div class="bar two"></div>
          <div class="bar three"></div>
        </div>
        <input type="text" name="username" [(ngModel)]="loginField.username" [attr.placeholder]="sft ? 'Email' : 'Username'" (keydown)="loginKey($event)" (keyup)="sft = true;">
        <input type="password" name="password" [(ngModel)]="loginField.password" placeholder="Password" (keydown)="loginKey($event)" (keyup)="sft = true;">
        <button id="Login" (click)="logMeIn();"> 
          <div class="inside"></div><span>Login</span>
        </button>
      </div>
    </div>
    <div id="Submenu" *ngFor="let user of accounts" [@usersubmenu]="showAccountSubmenu">
      <div class="options"><a routerLink="/account/view/profile">
          <div class="viewHover">View Profile</div></a></div>
      <div class="account-details">
        <div class="username" [@line1]="scrollState"><a routerLink="/account/view/profile"><span>{{ user.username }}</span></a></div>
        <div class="tagline" *ngIf="user.tagline" [@line2]="scrollState"><span>{{ user.tagline }}</span></div>
        <ul class="profile-links" [@line3]="scrollState">
          <li><a routerLink="/account/view/claims" (click)="this.view = 'claims'">Cliams</a></li>
          <li><a routerLink="/account/view/items" (click)="this.view = 'items'">Items</a></li>
          <li><a routerLink="/account/view/profile" (click)="this.view = 'profile'">Profile</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Center aligned inputs and details-->
<div #sitesearch [attr.class]="loaded ? 'page loaded '+activetedRoute : 'page '+activetedRoute">
  <div class="scene" #Scene></div>
  <div class="container">
    <div class="container background"></div>
  </div>
  <router-outlet (message)="notification($event)"></router-outlet>
</div>
<footer id="footer">
  <div class="graphics"></div>
  <div class="top"><a routerLink="/home">
      <h1>IDENTITY</h1></a></div>
  <div class="container">
    <div class="left">
      <ul class="footer-menu" *ngIf="accounts">
        <li class="account-info" *ngFor="let user of accounts"><a routerLink="/account/view"> 
            <div class="img"><img src="{{ user.image }}"></div><span>{{ user.username }}</span></a>
          <ul class="sub-footer-menu">
            <li><a routerLink="/account/view">Account</a></li>
            <li><a routerLink="/logout">Logout</a></li>
          </ul>
        </li>
      </ul>
      <ul class="footer-menu" *ngIf="!accounts">
        <li><a routerLink="/login">Login</a></li>
        <li><a routerLink="/account/new">Create Account</a></li>
      </ul>
    </div>
    <div class="middle">
      <ul class="footer-menu">
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/shop">Shop</a></li>
        <li><a routerLink="/faq">FAQ</a></li>
      </ul>
      <ul class="footer-menu">
        <li><a href="http://www.identityrpg.com/community/">Community</a></li>
        <li><a routerLink="/info">About</a></li>
        <li><a routerLink="/modules">Modules</a></li>
      </ul>
      <p><span>© Copyright {{ currentYear }} Asylum Entertainment Inc.</span><br><a routerLink="/privacy">Privacy Policy </a><span style="margin: 0 7px 0 5px;">|</span><a routerLink="/terms">Terms of Service </a></p>
    </div>
    <div class="right">
      <ul class="footer-menu">
        <li><a href="http://asylumentertainment.ca/" target="_blank">
            <div id="company-logo">
              <div class="loader"></div>
            </div></a></li>
      </ul>
    </div>
    <div class="clear"></div>
  </div>
</footer>