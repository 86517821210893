
<!-- Cart details-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section class="blue" id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false">
  <div class="container">
    <div class="row title-row">
      <div class="border">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="bottom-line"></div>
      </div>
      <div class="background"></div>
      <h5 class="left crowd-title">
        <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a>{{ title }}</a>
      </h5><a class="right shop-link" routerLink="{{ subtitleLink }}"><i class="subicon" [attr.class]="subtitleIcon"></i><span class="back-to-shop">{{ subtitle }}</span></a>
    </div>
  </div>
</section>
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="store-buttons"><a [attr.class]="flashCart ? 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue active' : 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue'" [@zFade]="flashCart" routerLink="/shop/checkout/">
    <div class="background"></div>
    <div class="background2"></div>
    <div class="cart-items">
      <div>{{ cart.length + ( custom ? custom.length:0 ) }}</div>
      <div class="small">{{ cart.length + ( custom ? custom.length:0 ) == 1 ? 'Item' : 'Items' }}</div>
    </div><i class="licon licon-cart-full"></i></a></div>
<!-- ShowPointbox:	boolean-->
<!-- pointOpen: 		int-->
<!-- items: 			Product-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="points_overlay">
  <div id="Overlay" [@overlay]="ShowPointbox" (click)="closePoint()">
    <div id="ItemPointsModal" *ngFor="let listing of items;">
      <div class="background"></div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div><i class="licon licon-cross2" (click)="closePoint()"></i>
      <ul *ngFor="let point of listing.points | slice:pointOpen:pointOpen+1">
        <li> 
          <h2>{{ point.label }}</h2>
          <p>{{ point.description }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Image Zoom-->
<div id="Overlay" [@overlay]="ShowLightbox">
  <div id="Darkness" [@overlay]="ShowLightbox" (click)="ShowLightbox = false"></div>
  <div id="ItemImageModal" *ngFor="let listing of items">
    <div class="box-borders">
      <div class="top"></div>
      <div class="right"></div>
      <div class="bottom"></div>
      <div class="left"></div>
    </div><i class="licon licon-cross2" (click)="ShowLightbox = false"></i>
    <div class="lines">
      <div class="top-left" *ngIf="showImage &gt; 0"></div>
      <div class="top-right" *ngIf="showImage &lt; listing.imagecount-1"></div>
    </div>
    <div class="sides" (swipeleft)="swipeImage($event.type, listing.imagecount)" (swiperight)="swipeImage($event.type, listing.imagecount)">
      <div class="left" (click)="showImage = (showImage &gt; 0) ? showImage-1  : showImage;"></div>
      <div class="right" (click)="showImage = (showImage &lt; imageslength-1) ? showImage+1 : showImage;"></div>
    </div>
    <div class="header highlight">
      <h2> <span>{{ listing.title }}</span><span *ngIf="listing.imagecount &gt; 1">( {{ showImage+1 }} / {{ listing.imagecount }} )</span></h2>
      <div class="border">
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
    <div id="ImageLightbox" *ngFor="let image of listing.images | imageSize:'original'| slice:showImage:showImage+1; let i = index;" (click)="ShowLightbox=false"><img src="{{ image.url }}"></div>
    <!--#ImageOptions
    +button('Download', 'downloadImage(image.url)', 'licon licon-download')
    +button('View', 'openImage(image.url)', 'licon licon-eye')
    
    -->
    <ul id="ItemImageThumbs" *ngIf="listing.imagecount &gt; 1">
      <li class="thumb" *ngFor="let image of listing.images | imageSize:'original'; let i = index;">
        <div class="box-borders">
          <div class="top"></div>
          <div class="right"></div>
          <div class="bottom"></div>
          <div class="left"></div>
        </div><img src="{{ image.url }}" (click)="showImage = i;">
      </li>
    </ul>
  </div>
</div>
<div id="Item">
  <div class="hashAncor" id="contentpage">
    <div class="title-row container" id="Subtitle">
      <div class="left">
        <div class="back">
          <div class="background"></div><a routerLink="/items">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i></div><span>Items</span></a>
        </div>
      </div>
      <div class="rightside">
        <share-menu></share-menu>
      </div>
    </div>
    <div class="container" *ngIf="loading">
      <!-- +mixname-->
      <!-- +imagebox('let listing of items')-->
      <div id="SingleItem">
        <div class="row">
          <div class="col-1">
            <div class="crowd-box" id="ItemDetails">
              <div class="swoosh"></div>
              <div class="background"></div>
                    <div class="borders">
                      <div class="top"></div>
                      <div class="left"></div>
                      <div class="bottom"></div>
                      <div class="right"></div>
                      <div class="topline"></div>
                    </div>
              <div class="crowd-description"> </div>
            </div>
            <div id="ProductPrices">
              <div class="background"></div>
                    <div class="borders">
                      <div class="top"></div>
                      <div class="left"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="bottomline"></div>
                    </div>
              <div class="swoosh"></div>
            </div>
          </div>
          <div class="col-2">
            <div id="NoImages">
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div>
              <div class="swoosh"></div>
            </div>
            <div class="buttoncontrols">
              <div class="next">
                <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><span>next</span><i class="licon licon-chevron-right"></i>
              </div>
              <div class="prev">
                <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><i class="licon licon-chevron-left"></i><span>prev</span>
              </div>
              <div class="zoom">
                <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><i class="licon licon-loupe-zoom-in"></i><span>zoom</span>
              </div>
            </div>
          </div>
        </div>
        <div id="AdditionalProducts">
          <div id="AdditionalItems">
            <div class="background"></div>
            <div class="borders">
              <div class="top"></div>
              <div class="left"></div>
              <div class="bottom"></div>
              <div class="right"></div>
            </div>
            <ul class="item_listings" id="StoreItems">
              <div class="title">
                <h4 class="highlight"> <span>Loading...</span>
                  <div class="border">
                    <div class="bottom"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                  </div>
                </h4>
              </div>
              <li class="row grid_4 crowd-box">
                <ul id="ItemImages"><a>
                    <li>
                      <div class="swoosh"></div><img class="slide">
                    </li></a></ul>
                <h5 class="crowd-title"><a>Loading</a></h5>
              </li>
              <li class="row grid_4 crowd-box">
                <ul id="ItemImages"><a>
                    <li>
                      <div class="swoosh"></div><img class="slide">
                    </li></a></ul>
                <h5 class="crowd-title"><a>Loading</a></h5>
              </li>
            </ul>
          </div>
        </div>
        <div class="links" id="Links">
          <div class="swoosh"></div>
          <div class="background"></div>
          <div class="borders">
            <div class="top"></div>
            <div class="left"></div>
            <div class="bottom"></div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="!loading">
      <div id="SingleItem" *ngFor="let listing of items; let i = index;">
        <!-- auto-seperator-->
        <div class="Seperator" *ngIf="i &gt; 0"></div>
        <!-- Product Details-->
        <div class="row">
          <!-- Left Columns-->
          <div class="col-1">
            <div class="crowd-box" id="ItemDetails">
              <div class="background"></div>
                    <div class="borders">
                      <div class="top"></div>
                      <div class="left"></div>
                      <div class="bottom"></div>
                      <div class="right"></div>
                      <div class="topline"></div>
                    </div>
              <div class="crowd-description">{{ listing.description ? listing.description : ' > null' }}</div>
            </div>
            <div id="PointsList" *ngIf="listing.countPoints &gt; 0">
              <div class="background"></div>
                    <div class="borders">
                      <div class="top"></div>
                      <div class="left"></div>
                      <div class="leftline"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                    </div><span class="PointsTitle">Usage Details:</span>
              <ul> 
                <li *ngFor="let point of listing.points; let l = index;"><i *ngIf="point.icon" [attr.class]="point.icon"></i><span (click)="openPoint(l)">{{ point.title }}</span></li>
              </ul>
            </div>
            <div id="ProductPrices" *ngIf="purchaseButton">
              <div class="background"></div>
                    <div class="borders">
                      <div class="top"></div>
                      <div class="left"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="bottomline"></div>
                    </div>
              <div id="Stock" [attr.class]="( (listing.stock &gt; 0 || listing.stock == '-1') ? 'instock' : 'nostock' )">
                <div [attr.class]="( (listing.stock &gt; 0 || listing.stock == '-1') ? 'indicator green' : 'indicator red' )"></div><span class="stocknumber" *ngIf="listing.stock &gt; 0">{{ listing.stock }} </span><span>{{ ( (listing.stock > 0 || listing.stock == '-1') ? 'In Stock' : 'Out of Stock' ) }}</span>
              </div>
              <div id="Currency" *ngIf="currency &amp;&amp; enableCurrencySelector" (click)="currencyOpen = currencyOpen ? false : true"><i class="fa fa-angle-right" [@rotateOpen]="currencyOpen"></i><span>{{ currency.symbol }}</span>
                <ul class="currencies" [@animateOpen]="currencyOpen" (click)="currencyOpen = currencyOpen ? false : true">
                  <li *ngFor="let c of currencies" (click)="changeCurrency(c)"><span>{{ c.symbol }}</span></li>
                </ul>
              </div>
              <div id="Price" *ngIf="currency"><span>{{ this.currency.value*listing.price | number:'1.2-2' }} {{ currency.symbol }}</span></div>
              <div id="Price" *ngIf="!currency"><span>$ {{ listing.price | number:'1.2-2' }}</span></div>
            </div>
            <div id="ProductPoints">
              <ul>
                <li *ngIf="purchaseButton">
                        <div id="PurchaseButton" [attr.class]="inCart(listing.id) ? 'active' : ''">
                          <button class="buy button add-to-cart" (click)="addToCart( listing )">
                                  <div class="box-borders">
                                    <div class="top"></div>
                                    <div class="right"></div>
                                    <div class="bottom"></div>
                                    <div class="left"></div>
                                  </div>
                            <div class="background"></div>
                            <div class="activeborders">
                              <div class="top"></div>
                              <div class="left"></div>
                              <div class="bottom"></div>
                              <div class="right"></div>
                            </div>
                            <div class="highlight"></div><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; !inCart(listing.id)">Add to Cart</span><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; inCart(listing.id)">Checkout</span><span *ngIf="(listing.limit != 0 &amp;&amp; listing.limit &lt;= listing.owns)">Player Owned</span>
                          </button>
                        </div>
                </li>
                <li class="notice" *ngIf="stockNotice">
                        <div id="Stock" [@stock]="listing.instock"><i [attr.class]="( listing.instock ? 'licon licon-check-square' : 'licon licon-cross-square' )"></i><span>{{ ( listing.instock ?  (listing.stock > 0 ? listing.stock : '') + ' In Stock' : 'No Stock' ) }}</span></div>
                </li>
                <li class="notice" *ngIf="limitNotice">
                        <div id="Limit" *ngIf="listing.limit != false"><i class="licon licon-menu-square"></i><b>Limit {{ listing.limit }} Per Account</b></div>
                </li>
                <li class="notice" *ngIf="requirementsNotice">
                        <div id="SystemRequirements"><a><i class="licon licon-notification"></i><b>System Requirements</b></a></div>
                </li>
                <li class="notice" *ngIf="listing.build_state">
                  <div id="BuildStatus"><a *ngIf="listing.build_state == 1"><i class="licon licon-speed-slow"></i><b>In Concept</b></a><a *ngIf="listing.build_state == 2"><i class="licon licon-speed-medium"></i><b>In Production</b></a><a *ngIf="listing.build_state == 3"><i class="licon licon-speed-fast"></i><b>Development Complete</b></a></div>
                </li>
                <li class="feature">
                  <div id="PassportFeat" *ngFor="let item of allitems | slice:0:1"><a routerLink="{{ item.url }}"><img src="{{ item.images[0].url }}"><b>{{ item.title }}</b></a></div>
                </li>
              </ul>
            </div>
          </div>
          <!-- Right Column-->
          <div class="col-2">
            <div id="NoImages" *ngIf="listing.imagecount == 0">
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div><i class="licon licon-picture"></i>
            </div>
            <div id="ItemImages" *ngIf="listing.imagecount &gt; 0">
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div>
              <div id="Images" *ngIf="!displayModel &amp;&amp; listing.imagecount &gt; 0">
                <!-- Top page indicator-->
                <ul class="indicator" *ngIf="listing.imagecount &gt; 0">
                  <li *ngFor="let image of listing.images; let i = index;" [attr.class]="(showImage==i) ? 'active' : 'inactive'" [style.width]="100 / listing.imagecount+'%'">
                    <div class="right-hook"></div>
                    <div class="left-hook"></div>
                  </li>
                </ul>
                <!-- Images-->
                <ul class="main">
                  <li *ngFor="let image of listing.images | imageSize:'listing' | slice:showImage:showImage+1; let i = index;" (swipeleft)="swipeImage($event.type, listing.imagecount)" (swiperight)="swipeImage($event.type, listing.imagecount)" (mousemove)="imageMove($event, image)" (click)="ShowLightbox = true;">
                    <!-- #ImageSize {{ image.size }}--><img class="slide" [style.transform]="translateImage" (click)="openLightbox(image)" src="{{ image.url }}">
                  </li>
                </ul>
              </div>
              <div id="Models" *ngIf="displayModel &amp;&amp; listing.models.length &gt; 0">
                <!-- Top page indicator-->
                <ul class="indicator">
                  <li *ngFor="let image of listing.models; let i = index;" [attr.class]="(showModel==i) ? 'active' : 'inactive'" [style.width]="100/listing.models.length+'%'">
                    <div class="right-hook"></div>
                    <div class="left-hook"> </div>
                  </li>
                </ul>
                <!-- 3D Models-->
                <ul class="main">
                  <li *ngFor="let model of listing.models | slice:showModel:showModel+1; let i = index;"></li>
                </ul>
                <div class="buttoncontrols">
                  <div class="next" (click)="showModel=(showModel &lt; listing.models.length-1) ? showModel+1 : showModel" *ngIf="showModel &lt; listing.models.length-1">
                    <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><span>next</span><i class="licon licon-chevron-right"></i>
                  </div>
                  <div class="prev" (click)="showModel=(showModel &gt; 0) ? showModel-1 : showModel" *ngIf="showModel &gt; 0">
                    <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><i class="licon licon-chevron-left"></i><span>prev</span>
                  </div>
                  <div class="model" *ngIf="listing.models.length &gt; 0" (click)="displayModel = displayModel ? false : true">
                    <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><i class="licon licon-camera-flip"></i><span>image</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Controls-->
            <div class="buttoncontrols">
              <div class="next" (click)="showImage=(showImage &lt; listing.imagecount-1) ? showImage+1 : showImage" *ngIf="showImage &lt; listing.imagecount-1">
                <div class="swooshy"></div>
                <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><span>next</span><i class="licon licon-chevron-right"></i>
              </div>
              <div class="prev" (click)="showImage=(showImage &gt; 0) ? showImage-1 : showImage" *ngIf="showImage &gt; 0">
                <div class="swooshy"></div>
                <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><i class="licon licon-chevron-left"></i><span>prev</span>
              </div>
              <div class="zoom" *ngIf="listing.imagecount &gt; 0" [style.margin]="listing.imagecount &gt; 0 ? '0 3%' : '0'" (click)="ShowLightbox = true;">
                <div class="swooshy"></div>
                <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><i class="licon licon-loupe-zoom-in"></i><span>zoom</span>
              </div>
              <div class="model" *ngIf="listing.models.length &gt; 0" (click)="displayModel = displayModel ? false : true">
                <div class="swooshy"></div>
                <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><i class="licon licon-3d-rotate"></i><span>model</span>
              </div>
            </div>
            <div id="LongHTML" *ngIf="listing.html">
              <div class="background"></div>
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div>
              <div id="HTML" [innerHtml]="listing.html"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <div *ngIf="!loading">
        <div id="AdditionalProducts">
          <div id="AdditionalItems" (swipeleft)="x = (x &lt; 6) ? x+1 : x" (swipedown)="x = (x &lt; 6) ? x+1 : x" (swiperight)="x = (x &gt; 0) ? x-1 : x" (swipeup)="x = (x &gt; 0) ? x-1 : x" [style.touch-action]="'pan-y'">
            <div class="background"></div>
            <div class="borders">
              <div class="top"></div>
              <div class="left"></div>
              <div class="bottom"></div>
              <div class="right"></div>
              <div class="right-line" [style.top]="((x/6)*70)+'%'"></div>
              <!--.left-line( [style.bottom]="((x/6)*66)+'%'" )-->
            </div>
            <ul class="item_listings" id="StoreItems">
              <div class="title">
                <h4 class="highlight"> <span>Additional Items</span>
                  <div class="border">
                    <div class="bottom"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                  </div>
                </h4>
              </div>
              <li class="row grid_4 crowd-box" *ngFor="let listing of features | hiddenFilter:'0' | slice:x:x+2; let i = index;">
                <ul id="ItemImages"><a routerLink="/shop/item/{{ listing.id }}">
                    <li *ngFor="let image of listing.images | imageSize:'listing' | slice:0:1 "><img class="slide" src="{{ image.url }}"></li></a></ul>
                <h5 class="crowd-title"><a routerLink="/shop/item/{{ listing.id }}">{{ listing.title }}</a></h5>
                <!--.crowd-description
                a( routerLink="/shop/item/{{ listing.id }}" ) {{ listing.html }}
                
                -->
                <!--.crowd-pleaser
                span.onsale $ {{ listing.price | number:'.2-2' }}
                .buttons( *ngIf="this.inCart(listing) == false" )
                	button( (click)="addToCart( listing )", [attr.class]="listing.owned ? 'buy button add-to-cart bought' : 'buy button add-to-cart'" )
                		.army( *ngIf="listing.owned" )
                			.shine
                		span {{ ( listing.owned ? 'Owned' : 'Buy' ) }}
                .buttons( *ngIf="this.inCart(listing) == true", routerLink="/checkout/" )
                	button.buy.button.add-to-cart
                		span In Cart
                
                -->
                <div class="Price" *ngIf="currency"><span>{{ this.currency.value*listing.price | number:'1.2-2' }} {{ currency.symbol }}</span></div>
              </li>
            </ul>
            <div class="controls">
              <div class="next" (click)="x = x+1" *ngIf="x &lt; 6"><i class="licon licon-chevron-right"></i></div>
              <div class="prev" (click)="x = x-1" *ngIf="x &gt; 0"><i class="licon licon-chevron-left"></i></div>
            </div>
          </div>
        </div>
        <!-- +mixname-->
        <!-- +imagebox('let listing of items')-->
        <div id="Links">
          <div class="background"></div>
          <div class="borders">
            <div class="top"></div>
            <div class="left"></div>
            <div class="bottom"></div>
            <div class="right"></div>
          </div>
          <ul>
            <li class="community"><a routerLink="/categories">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="background"></div>
                <div class="title">
                  <div class="titlebackground"></div><span>Categories</span>
                </div></a></li>
            <li class="game-shop"><a routerLink="/items">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="background"></div>
                <div class="title"> 
                  <div class="titlebackground"></div><span>Items</span>
                </div></a></li>
            <li class="about-identity"><a routerLink="/products">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="background"></div>
                <div class="title">
                  <div class="titlebackground"></div><span>Packages</span>
                </div></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>