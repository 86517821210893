
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<section id="mainhead" [style.background-image]="header" [style.height]="headerHeight"></section>
<div class="hashAncor" id="contentpage">
  <div class="row title-row top">
    <div class="box-borders">
      <div class="top"></div>
      <div class="right"></div>
      <div class="bottom"></div>
      <div class="left"></div>
    </div>
    <div class="background"></div>
    <div class="row container">
      <h5 class="left crowd-title">
        <div class="large-arrows arrow"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/shop/">Thank You</a>
      </h5><a class="right shop-link" routerLink="/shop/">
        <div class="small-arrows arrow"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">Back to Shop</span></a>
    </div>
  </div>
  <div id="Thankyou">
    <div class="container">
      <div class="top_header">
        <h2>Thank You</h2>
        <p>Congratulations on your purchase of Identity!</p>
      </div>
      <ul>
        <li class="product-listing" id="ProductSell" *ngFor="let product of products | priceFilter:'asc'; let i = index;">
          <div class="background"></div>
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <div class="product-images" *ngIf="product.images"><a routerLink="/shop/product/{{ product.id }}"><img *ngFor="let image of product.images | imageSize:'listing' | slice:0:1" src="{{ image.url }}"></a></div>
          <div class="product-details">
            <h5 class="crowd-title"><i class="licon licon-diamond3" *ngIf="product.type == 'item'"></i><i class="licon licon-chevron-right" *ngIf="product.type == 'package'"></i><a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h5>
            <div class="crowd-description">{{ product.description }}</div>
          </div>
          <div class="product-controls" *ngIf="currency"><span class="price">{{ product.quantity * (product.price * this.currency.value) | number:'1.2-2' }} {{ currency.symbol }}</span></div>
          <div class="product-features" *ngIf="product.items.length &gt; 0">
            <ul>
              <li *ngFor="let item of product.items | slice:0:2"><a routerLink="/shop/item/{{ item.id }}"><i class="licon licon-diamond2"></i>
                  <div class="backing"></div><span>{{ item.title }}</span></a></li>
              <div class="clear"></div>
            </ul>
          </div><a class="customize-link" (click)="removeCompletely(product, i )"> 
            <div class="underline"></div>
            <div class="overline"></div><span>Remove Product</span></a>
        </li>
        <li class="item-listing" id="ItemSell" *ngFor="let item of items | priceFilter:'asc'; let i = index;">
          <div class="background"></div>
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <div class="product-images" *ngIf="item.images"><a routerLink="/shop/item/{{ item.id }}"><img *ngFor="let image of item.images | imageSize:'listing' | slice:0:1" src="{{ image.url }}"></a></div>
          <div class="product-details">
            <h5 class="crowd-title"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h5>
            <div class="crowd-description">{{ item.description }}</div>
          </div>
          <div class="product-controls" *ngIf="currency"><span class="price">{{ item.quantity * item.price * currency.value | number:'1.2-2' }} {{ currency.symbol }}</span>
            <div class="quantity-controls" *ngIf="item.limit &gt; 0">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
              <div class="quantity">
                <input class="quantity-counter" (keyup)="changeItemQuantity(item)" [(ngModel)]="item.quantity">
              </div><a class="add-product fa fa-plus" (click)="addItemToCart(item)"></a><a class="subtract-product fa fa-minus" (click)="removeItemFromCart(item)"></a><span class="limit" *ngIf="item.limit == item.quantity">Limit: {{ item.limit }}</span>
            </div>
          </div>
          <div class="in-game-item"><i class="gameicon licon licon-download"></i><span>In-Game Item</span></div><a class="customize-link" (click)="removeItemCompletely(item, i)"> 
            <div class="underline"></div>
            <div class="overline"></div><span>Remove Item</span></a>
        </li>
      </ul>
    </div>
  </div>
</div>