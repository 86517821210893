
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div class="store-buttons"><a [attr.class]="flashCart ? 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue active' : 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue'" [@zFade]="flashCart" routerLink="/shop/checkout/">
    <div class="background"></div>
    <div class="background2"></div>
    <div class="cart-items">
      <div>{{ cart.length + ( custom ? custom.length:0 ) }}</div>
      <div class="small">{{ cart.length + ( custom ? custom.length:0 ) == 1 ? 'Item' : 'Items' }}</div>
    </div><i class="licon licon-cart-full"></i></a></div>
<div id="CustomBuild">
  <!--include cart.pug-->
  <div class="hashAncor">
    <div class="container">
      <div class="row">
        <div id="Items">
          <div class="item" *ngFor="let cat of groups">
            <h2>{{ cat.name }}</h2>
            <ul class="item-list">
              <li *ngFor="let item of items | categoryFilter:cat.id | priceFilter:'asc'" (click)="toggleItem(item)" [attr.name]="item.title" [attr.data]="item.id">
                <div class="name">{{ item.title }}</div>
                <div class="price">{{ item.price | currency:'USD':true:'1.2-2' }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>