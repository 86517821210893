
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="Gift">
  <div class="hashAncor" id="contentpage">
    <div class="container">
      <div class="row">
        <div class="login-options" *ngIf="loggedIn">
          <h2 class="purchase-title">Claim Gifts</h2>
          <div id="error" *ngIf="showerror"><span id="error-message">{{ gifterror }}</span></div>
          <div id="success" *ngIf="showsuccess"><span id="error-message">Success! Gifts have been added to your account.</span></div>
          <!-- Enter a Gift Key-->
          <h2 class="giftkey-title">Gift Key</h2>
          <form id="GiftKey"><i class="fa fa-gift gift-icon"></i>
            <input type="text" placeholder="Enter Your Gift Key" [(ngModel)]="gift.key" name="key">
            <button class="button find-gifts" (click)="getGifts()">Find Gifts</button>
          </form>
          <div id="Product" *ngIf="hasProducts == true">
            <ul id="PurchasedItems">
              <li class="purchase" *ngFor="let listing of products">
                <div id="ProductImages" *ngFor="let image of listing.images | slice:0:1"><img class="slide" src="{{ image.url }}"></div>
                <div class="crowd-box" id="ProductDetails">
                  <div class="crowd-details">
                    <h5 class="crowd-title"><a href="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
                  </div>
                  <div class="crowd-description" [innerHTML]="listing.html"></div>
                </div>
                <div id="Items">
                  <h4>Included</h4>
                  <ul>
                    <li *ngFor="let item of listing.items"><a routerLink="/shop/item/{{item.id}}">{{ item.title }}</a></li>
                  </ul>
                </div>
                <div class="clear"></div>
              </li>
            </ul>
            <div class="clear"></div>
          </div>
          <div id="Items" *ngIf="hasItems == true">
            <ul class="item_listings">
              <li class="row grid_4 crowd-box" *ngFor="let listing of items; let i = index">
                <div class="bg"></div>
                <div class="background"></div>
                <ul id="ProductImages">
                  <li *ngFor="let image of listing.images | imageSize:'listing' | slice:0:1"><a (click)="showProduct(i)"><img class="slide" src="{{ image.url }}"></a></li>
                </ul>
                <div id="ProductDetails">
                  <div class="corners">
                    <div class="left"></div>
                    <div class="right"></div>
                    <div class="bottomleft"></div>
                    <div class="bottomright"></div>
                    <div class="topline"></div>
                  </div>
                  <div class="crowd-title">
                    <div class="borders">
                      <div class="top"></div>
                      <div class="bottom"></div>
                    </div>
                    <h5><a routerLink="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
                    <div class="crowd-description"><a routerLink="/shop/product/{{ listing.id }}">{{ listing.description }}</a></div>
                  </div>
                  <div class="actions-menu" (mouseenter)="showActions[i] = true" (mouseleave)="showActions[i] = false"><i class="menu-icon fa fa-ellipsis-v"></i>
                    <ul class="crowd-actions" [@actionsMenu]="showActions[i]">
                      <li class="share" (click)="shareItem(listing);"><span class="licon licon-share3"></span><span>Share Link</span></li>
                      <li class="gift" (click)="giftItem(listing);"><span class="licon licon-gift"></span><span>Send as Gift</span></li>
                    </ul>
                  </div>
                </div>
                <div class="clear"></div>
              </li>
            </ul>
          </div>
          <div id="NoProduct" *ngIf="hasProducts == false &amp;&amp; hasItems == false">
            <div id="Product">
              <li class="product-listing">
                <div class="store-link">
                  <h5>No Items Found</h5><span>The gift key you entered has already been used or is not valid.</span>
                </div>
              </li>
            </div>
          </div>
          <div id="ProductActions" *ngIf="hasProducts == true || hasItems == true">
            <div class="crowd-pleaser">
              <button class="buy button add-to-cart" (click)="acceptGiftItems()">Claim Items</button>
            </div>
          </div>
          <div class="row">
            <ul class="post-activation-options">
              <li><a routerLink="/account/view/items/">View Items</a></li>
              <li><a routerLink="/account/view/products/">View Products</a></li>
              <li><a routerLink="/account/view/">Your Identity</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <login-options></login-options>
      </div>
    </div>
  </div>
</div>