
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="TownSquare">
  <div id="modules">
    <div class="hashAncor" id="contentpage">
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Welcome to Town Square</span>
          </h5>
        </div>
      </div>
      <h2><span>Town Square</span></h2>
      <div class="row" id="tsheader"></div>
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Explore the Attractions</span>
          </h5>
        </div>
      </div>
      <div class="row first">
        <div class="container">
          <p>
            The Town Square is the first Identity module set for release, available to all pledgers with access to the game beta testing phases. As a social module, the Town Square will allow you to create and customize your characters, meet new friends and enjoy a handful of interesting and fun things to do. This is your first preview of what's to come with Identity!
            
          </p>
          <p>
            Identity's Town Square, a closed-off section of the Ash Hill city center, gives you a sneak peek at some of the items you've received from pledging to Identity. With a few dozen people per town square server, you can hang out and play games with other Identity fans while waiting on future modules and the full Identity world.
            
          </p>
        </div>
      </div>
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Claim your own Identity</span>
          </h5>
        </div>
      </div>
      <div class="row image TownSquareConcept"></div>
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Claim your own world</span>
          </h5>
        </div>
      </div>
      <div class="row no-top-margin">
        <div class="desc crop-top">
          <div class="title">Character customization</div>
          <div class="container">After creating your character you'll find tattoo parlors, barber shops and more to help achieve a unique look.</div>
        </div>
        <div class="title">All those social bits</div>
        <div class="desc">
          <div class="container">You'll have no trouble making friends with Identity's world-class voice communication system. When you do meet new people, add them to your friends list using your in-game smartphone. Your contacts will even carry over into the full Identity game!</div>
        </div>
        <div class="title">Access to player owned housing</div>
        <div class="desc">
          <div class="container">If you've pledged to Identity's development and received an apartment or penthouse, you'll be able to explore all of its features. From decorating to furnishing, see what you can do with custom housing. Make your home your own or invite friends to join you.</div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Interact with the World</span>
          </h5>
        </div>
      </div>
      <div class="row cinema image TownSquareCinema"></div>
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Explore the Attractions</span>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="desc crop-top">
          <div class="title">Cinema playing real movies</div>
          <div class="container">The Ash Hill cinema has only one screen, in contrast to the enormous cinema in Roseport, although you'll find real movies playing around the clock. It's a great place to enjoy the classics with new and old friends.</div>
        </div>
        <div class="title">Food, drinks and drugs</div>
        <div class="desc">
          <div class="container">Popcorn for a movie, coffee and beer, or maybe something not on the menu; it's all here in the Town Square for those looking to quench their thirst and have some fun.</div>
        </div>
        <div class="title">Art and drawing, including an art gallery</div>
        <div class="desc">
          <div class="container">Grab a canvas and some paint to show your artistic talents (or whatever it is you have). Those who really know what they're doing could find their work on the walls of the prestigous Ash Hill art gallery for all to see.</div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Interact with the World</span>
          </h5>
        </div>
      </div>
      <div class="row image TownSquareWorld"></div>
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Be Original</span>
          </h5>
        </div>
      </div>
      <div class="row no-bottom-margin">
        <div class="desc crop-top">
          <div class="title">Read real books or write your own</div>
          <div class="container">
            A small library is filled with real classic stories and novels for you to enjoy. For a nice place to read you might find others in the coffee shop, listening to real-world radio stations. If you want to share your own adventures, not only can you write your own books but you can even submit them to the library for thousands of other players to read.
            
          </div>
        </div>
        <div class="title">A fully functional karaoke bar</div>
        <div class="desc">
          <div class="container">The karaoke bar is full of absolutely terrible singers, and a few decent ones! Whether you're talented or as horrible as the Asylum team, karaoke with a bunch of other players is always fun and usually hilarious.</div>
        </div>
        <div class="title">Plus additional content added regularly!</div>
        <div class="desc">
          <div class="container">The great thing about Identity's modules is that it lets us share some of the cool and fun stuff we create before Identity is ready itself. We'll continue to expand the Town Square with new content and games all the time, right up until Identity hits the shelves!</div>
        </div>
        <div class="clear"></div>
      </div>
      <!-- +mixname-->
      <!-- +imagebox('let listing of items')-->
      <div id="Tasks">
        <h2>Development Tasks Remaining
          <div class="header">Take a look inside the Asylum Entertainment studio; here you see a summary of the tasks our developers are working on for the release of the Town Square module. <b>Release is very close, and now you can follow development as it happens.</b></div>
          <div class="divider">Tasks listed are those as of April 19, 2018.<br>Last updated
            {{hoursToday}} hour(s) ago.
          </div>
          <div class="taskblock">
            <h4 style="clear:both;margin-top:1em;">Art</h4>
            <h6 style="clear:both">2D Graphics <span style="color: #666">[1 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [1]" style="width:100%">Completed [1]</div>
            </div>
            <h6 style="clear:both">Animation <span style="color: #666">[3 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [3]" style="width:100%">Completed [3]</div>
            </div>
            <h6 style="clear:both">Environment <span style="color: #666">[5 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [5]" style="width:100%">Completed [5]</div>
            </div>
            <h6 style="clear:both">Props <span style="color: #666">[1 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [1]" style="width:100%">Completed [1]</div>
            </div>
            <h6 style="clear:both">Technical Art <span style="color: #666">[13 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [13]" style="width:100%">Completed [13]</div>
            </div>
            <h6 style="clear:both">UI and UX <span style="color: #666">[3 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [3]" style="width:100%">Completed [3]</div>
            </div>
          </div>
          <div class="taskblock bugtracker">
            <h4 style="clear:both;margin-top:1em;">Bug Tracker</h4>
            <h6 style="clear:both">Bugs<span style="color: #666">[7 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [7]" style="width:100%">Completed [7]</div>
            </div>
          </div>
          <div class="taskblock">
            <h4 style="clear:both;margin-top:1em;">Programming</h4>
            <h6 style="clear:both">Gameplay <span style="color: #666">[10 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [10]" style="width:100%">Completed [10]</div>
            </div>
            <h6 style="clear:both">Systems <span style="color: #666">[5 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-completed" title="Completed [5]" style="width:100%">Completed [5]</div>
            </div>
            <h6 style="clear:both">User Interface <span style="color: #666">[14 Tasks]</span></h6>
            <div class="task-probar">
              <div class="taskstatus tasks-intesting" title="In Testing [1]" style="width:7.1428571428571%">In Testing [1]</div>
              <div class="taskstatus tasks-completed" title="Completed [13]" style="width:92.857142857143%">Completed [13]</div>
            </div>
          </div>
          <div class="clear"></div>
        </h2>
              <div class="row title-row">
                <div class="border">
                  <div class="top"></div>
                  <div class="bottom"></div>
                  <div class="bottom-line"></div>
                </div>
                <div class="container">
                  <div class="background"></div>
                  <div class="backgroundtwo"></div>
                  <h5 class="left crowd-title">
                    <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/products/">Claim your place in town square</a>
                  </h5><a class="right shop-link" routerLink="/shop/">
                    <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">Shop Identity</span></a>
                </div>
              </div>
      </div>
      <div class="clear"></div>
      <!-- +titlebar("Claim your place in town square", "/products/", "Shop Identity", "/shop/")-->
    </div>
  </div>
</div>