
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="cart-details user-item-view" id="Overlay" [@cartoverlay]="showItemDetails">
  <div id="Darkness" (click)="hideDetails()"></div>
  <div class="ItemDetails" id="CartDetails" [@cartoverlay]="showItemDetails">
    <div class="title"><i class="licon licon-cross close" (click)="hideDetails()"></i>
      <h2 *ngFor="let item of allitems | slice:selectedItem:selectedItem+1;"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h2>
    </div>
    <div id="InternalDetails"> 
      <div id="Item" *ngFor="let item of allitems | priceFilter:itemquery.sort | categoryFilter:itemquery.category | slice:selectedItem:selectedItem+1; let i = index;">
        <div class="row">
          <div id="SelectedImages">
            <!-- ( *ngIf="item.imagecount > 0" )-->
            <ul>
              <li *ngFor="let image of item.images | imageSize:'listing'"><img src="{{ image.url }}"></li>
            </ul>
            <!-- Image page logic			-->
            <div id="ImageControls" *ngIf="item.imagecount &gt; 1">
              <div class="direction-controls">
                <div class="next" (click)="selectedImage = (selectedImage &lt; item.imagecount-1) ? selectedImage+1 : selectedImage">
                  <div class="background"></div>
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div><span>next</span><i class="licon licon-chevron-right"></i>
                </div>
                <div class="prev" (click)="selectedImage = (selectedImage &gt; 0) ? selectedImage-1 : selectedImage">
                  <div class="background"></div>
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div><i class="licon licon-chevron-left"></i><span>prev</span>
                </div>
              </div>
              <div class="FullImage" *ngFor="let image of item.images | slice:selectedImage:selectedImage+1 | imageSize:'original'">
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div><a href="{{ image.url }}" target="_blank"><i class="licon licon-expand"></i></a>
              </div>
            </div>
          </div>
          <div class="crowd-box" id="SelectedDetails">
            <div class="line-background"></div>
            <div class="crowd-details">
              <h5 class="crowd-title"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h5>
            </div>
            <div class="crowd-description description">{{ item.description }}</div>
            <!--.price {{ item.originalprice }}-->
          </div>
          <div class="clear"></div>
          <div class="overlay-actions">
            <div class="window-controls direction-controls">
              <div class="prev" (click)="hideDetails()">
                <div class="background"></div>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div><i class="licon licon-cross2"></i><span>Close</span>
              </div>
              <div class="next" routerLink="/shop/item/{{ item.id }}">
                <div class="background"></div>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div><i class="licon licon-bookmark2"></i><span>View</span>
              </div>
            </div>
            <div class="center" *ngIf="currency"><span>{{ this.currency.value * item.price | number:'1.2-2' }}</span><span class="currency">{{ this.currency.symbol }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="title-row">
  <h5>Your</h5>
  <h1>Items</h1>
</div>
<div class="clear"></div>
<ng-select class="sort-options selector" [options]="sortOptions" (selected)="changeSort($event)" placeholder="Price Sort"></ng-select>
<ul id="PurchasedItems">
  <li class="row grid_4 crowd-box" *ngFor="let listing of allitems | priceFilter:itemquery.sort | categoryFilter:itemquery.category; let i = index;" [attr.data-id]="listing.id">
    <div class="item-image" *ngFor="let image of listing.images | imageSize:'thumbnail' | slice:0:1" (click)="openItem(i)"><img class="slide" src="{{ image.url }}"></div>
    <h5 class="crowd-title"><a routerLink="/shop/item/{{ listing.id }}">{{ listing.title }} </a></h5>
    <div class="details">{{ listing.description }}</div>
    <div class="quantity" *ngIf="listing.quantity &gt; 1">{{ listing.quantity }} In Inventory</div>
    <div class="actions-menu" (click)="showActions[i] = (showActions[i] == true) ? false : true">
      <div class="menu-button"> 
        <div class="circle"></div>
      </div>
      <ul class="crowd-actions" [@actionsMenu]="showActions[i]">
        <li class="share" (click)="shareItem(listing);"><span class="licon licon-share3"></span><span>Share Link</span></li>
      </ul>
    </div>
    <div class="clear"></div>
  </li>
  <li class="row grid_4 crowd-box for-more" *ngIf="hasProducts == 'true'">
    <div class="topline"></div><a routerLink="/categories"><i class="licon licon-plus"></i></a>
    <h5 class="crowd-title"><a routerLink="/categories">More Items</a></h5>
    <div class="details">You can purchase additional items in the Identity store.</div>
  </li>
  <li class="row grid_4 crowd-box for-more large" *ngIf="numberOfItems == 0 &amp;&amp; hasProducts != 'true'">
    <div class="topline"></div><a routerLink="/categories"><i class="licon licon-plus"></i></a>
    <h5 class="crowd-title"><a routerLink="/categories">Get Items</a></h5>
    <div class="details">You can purchase items directly from the Identity store.</div>
  </li>
  <div *ngIf="hasProducts == &quot;null&quot;">
    <li class="row grid_4 crowd-box loader" *ngFor="let item of createRange(numberOfItems); let i = index;">
      <div class="topline"></div><i class="image"></i><i class="lines licon licon-text-align-left"></i>
      <div class="background"></div>
    </li>
  </div>
</ul>
<div class="clear"></div>