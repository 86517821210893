
<div class="title-row">
  <h5>Brand New </h5>
  <h1>Features</h1>
</div>
<div class="clear"></div>
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="ProductNews">
  <div class="swoosh" *ngIf="loading"></div>
  <div class="top-row">
    <div class="icon icon-newspaper header-icon"></div>
    <h3>News</h3>
  </div>
  <div class="news-slider" [@newsWindow]="showNews" (swipeleft)="y = y&lt;1 ? y+1 : y" (swiperight)="y = y&gt;0 ? y-1 : y">
    <div class="news" *ngFor="let item of news | slice:y:y+1; let i = index;">
      <time [attr.datetime]="item.start_date"><span>{{item.start_date*1000 | date:'d'}}th</span>
        <div>{{item.start_date*1000 | date:'MMM y'}}</div>
      </time>
      <div class="news-body">
        <h5><a class="news-title" href="http://www.identityrpg.com{{ item.url }}">{{ item.title }}</a></h5>
        <p [innerHTML]="item.post | truncate:limit[i]"></p>
        <ul class="news-links-list">
          <li class="left"><span class="fa fa-user"></span><a [attr.href]="item.author_url">{{ item.starter_name }}</a></li>
          <li class="right"><span class="fa fa-arrow-right"></span><a href="http://www.identityrpg.com{{ item.url }}">Read more</a></li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>
<div class="controls">
  <div class="direction-controls">
    <div class="next" (click)="nextItem();">
      <div class="background"></div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div><span>next</span><i class="licon licon-chevron-right"></i>
    </div>
    <div class="prev" (click)="prevItem();">
      <div class="background"></div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div><i class="licon licon-chevron-left"></i><span>prev</span>
    </div>
  </div>
</div>
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="UserProducts">
  <ul class="product_listings">
    <li class="row grid_4 crowd-box hiddenStagger" *ngFor="let listing of products; let y = index" [@staggeredCards]="showCards &lt; y ? false : true">
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div>
      <div class="corners">
        <div class="left"></div>
        <div class="right"></div>
        <div class="bottomleft"></div>
        <div class="bottomright"></div>
        <div class="topline"></div>
        <div class="bottomline"></div>
      </div>
      <div class="background2"></div>
      <div class="Face" [@viewDesc]="viewPanel[y]">
        <div class="inner-box">
          <div class="background"></div>
          <div class="brighter-background"></div>
          <div class="crowd-images">
            <li *ngFor="let image of listing.images | imageSize:'listing' | slice:0:1; let y = index;"><a (click)="showDetails = true; selected = y"><img class="slide" src="{{ image.url }}"></a></li>
          </div>
          <h5 class="crowd-title"><a routerLink="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
          <div class="description">{{ listing.description }}</div>
          <div id="Beta"><i class="licon licon-warning"></i><span *ngIf="listing.beta &gt; 0">Includes Module Play</span></div>
          <div id="Stock" *ngIf="listing.instock"><i [attr.class]="( listing.instock ? 'licon licon-check-square' : 'licon licon-cross-square' )"></i><span>{{ ( listing.instock ?  (listing.stock > 0 ? listing.stock : '') + ' In Stock' : 'No Stock' ) }}</span></div>
          <div id="Sale"><small *ngIf="listing.originalprice &gt; listing.price"> <i class="licon licon-tags"></i><span (click)="flips = (flips ? false : true)">
                <div [attr.class]=" flips ? 'line-through' : 'line-through rev' "></div><span>${{ listing.originalprice }}</span><sup>.00</sup></span></small></div>
          <div class="crowd-pleaser">
            <div id="Currency" *ngIf="currency &amp;&amp; enableCurrencySelector"><span (click)="openCurrency(y)">{{ currency.name }}</span><i class="fa fa-angle-left" [@rotateDown]="(currencyOpen == y) ? true : false"></i>
              <ul class="currencies" [@animateOpen]="(currencyOpen == y) ? true : false" (click)="currencyOpen = ( currencyOpen &gt;= 0) ? -1 : y">
                <li *ngFor="let c of currencies" (click)="changeCurrency(c)"><span>{{ c.name }}	</span></li>
              </ul>
            </div>
            <div class="crowd-buy" *ngIf="currency"><span class="onsale">{{ listing.price*this.currency.value | currency:this.currency.name:true:'1.2-2' }}</span></div>
            <button (click)="addToCart( listing, i )" [attr.class]="(listing.owned) ? 'buy button add-to-cart bought' : (!listing.instock ? 'buy button add-to-cart disabled' : (flashCart ? 'buy button add-to-cart active' : 'buy button add-to-cart' ))"><span *ngIf="inCart( listing.id )">In Cart</span><span *ngIf="listing.owned">Owned</span><span *ngIf="!listing.owned &amp;&amp; listing.instock &amp;&amp; !inCart( listing.id )">Add to Cart</span><span *ngIf="!listing.instock">Out of Stock</span>
              <div class="background"></div>
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
            </button>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="Back" [@viewInfo]="viewPanel[i]">
        <div class="inner-box">
          <div class="back-view-icon" (click)="viewPanel[i] = (viewPanel[i]=='desc') ? 'info' : 'desc'">
            <div class="left-arrow"></div>
          </div>
          <h5 class="crowd-title"><a routerLink="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
          <hr>
          <div class="crowd-items">
            <ul>
              <li *ngFor="let item of listing.items"><span>{{ item.title }}</span></li>
            </ul>
          </div>
          <div class="crowd-pleaser">
            <div class="crowd-buy"><span class="onsale"><span *ngIf="currency">{{ listing.price*this.currency.value | currency:this.currency.name:true:'1.2-2' }}</span></span>
              <!--small( *ngIf="listing.originalprice > listing.price" ) {{ listing.originalprice*this.currency.value | currency:this.currency.name:true:'1.2-2' }}-->
            </div>
            <button class="buy button add-to-cart" (click)="addToCart( listing, i )">
              {{ ( listing.incart ? 'In Cart' : 'Add to Cart' ) }}
              
            </button>
          </div>
          <div class="beta available" *ngIf="listing.beta &gt; 0"><span>Includes Module Play</span></div>
          <div class="stock" [@stock]="listing.instock">{{ ( listing.instock ? 'In Stock' : 'No Stock' ) }}</div>
          <div class="clear"></div>
        </div>
      </div>
    </li>
    <div class="clear"></div>
  </ul>
</div>
<div class="clear"></div>