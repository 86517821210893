
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="modules">
  <div class="hashAncor" id="contentpage">
    <h2 class="heading_color">
      <div>Identity Modules</div>
    </h2>
    <div class="container">
      <section class="row"><a class="module-box module-1" routerLink="/modules/townsquare">
          <div class="licon licon-crown service-iconlarge"></div>
          <div class="module-box-indent">
            <div class="module-title">Town Square</div>
            <div class="module-subtitle orange">Status: In Production</div>
            <div class="module-description">The Town Square will be the first module released and will allow players to create their characters, interact socially and enjoy some of the social features.</div>
          </div></a><a class="module-box module-2" routerLink="/modules">
          <div class="licon licon-gun service-iconlarge"></div>
          <div class="module-box-indent">
            <div class="module-title">S.W.A.T.</div>
            <div class="module-subtitle orange">Status: Design</div>
            <div class="module-description">Put your weapons and tactical skills to the test in an online competitive environment of hyper-realistic SWAT vs. criminal action. Only the best will prevail.</div>
          </div></a><a class="module-box module-3" routerLink="/modules">
          <div class="licon licon-car service-iconlarge"></div>
          <div class="module-box-indent">
            <div class="module-title">Racing</div>
            <div class="module-subtitle orange">Status: Design</div>
            <div class="module-description">Build and tune the car which will take you to the top in this online competitive and realistic racing simulator, right on the streets and tracks of Identity.</div>
          </div></a></section>
      <section class="infobox">
        <div class="background"></div>
        <div class="container">
          <div class="row">
            <div class="inside">Modules are standalone pieces of Identity which will be released prior to the full game release, giving our backers something to play with while we're applying finishing touches and polishing the full game.</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>