
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- Checkout Mixins-->
<!-- Page header-->
<section id="mainhead" [style.background-image]="header" [style.height]="headerHeight"></section>
<div class="hashAncor" id="contentpage">
  <div class="row title-row top">
    <div class="border">
      <div class="top"></div>
      <div class="bottom"></div>
      <div class="bottom-line"></div>
    </div>
    <div class="background"></div>
    <div class="row container">
      <h5 class="left crowd-title">
        <div class="large-arrows arrow"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/shop/">Checkout</a>
      </h5><a class="right shop-link" routerLink="/shop/">
        <div class="small-arrows arrow"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">Back to Shop</span></a>
    </div>
  </div>
  <div id="Checkout">
    <div class="container">
      <div id="Controls">
        <div class="box-borders">
          <div class="bottom"></div>
          <div [attr.class]=" hoverLeft ? 'bottom-left active' : 'bottom-left' "></div>
          <div [attr.class]=" hoverRight ? 'bottom-right active' : 'bottom-right' "></div>
        </div>
        <div class="top_subtitle">Identity</div>
        <div class="removeAll" (mouseover)="hoverLeft = true" (mouseleave)="hoverLeft = false">
          <div class="emptylink" (click)="emptyCart();" *ngIf="!recoverProducts &amp;&amp; !recoverItems"><i class="licon licon-trash"></i><span>Empty Cart</span></div>
          <div class="restorelink" (click)="restoreCart();" *ngIf="recoverItems || recoverProducts"><i class="licon licon-arrow-return"></i><span>Restore Cart</span></div>
        </div>
        <div class="sharemenu" (mouseover)="hoverRight = true" (mouseleave)="hoverRight = false">
          <share-menu urlParams="p={{this.cartProducts}}&amp;i={{this.cartItems}}" clickToActivate="true" singleArrow="true"></share-menu>
        </div>
        <div class="currency" id="Currency" *ngIf="currency &amp;&amp; enableCurrencySelector" (mouseover)="hoverRight = true" (mouseleave)="hoverRight = false" (click)="currencyOpen = currencyOpen ? false : true"><span>{{ currency.symbol }}</span><i class="fa fa-angle-right" [@rotateDown]="currencyOpen"></i>
          <ul class="currencies" [@animateOpen]="currencyOpen" (mouseleave)="currencyOpen = false">
            <li *ngFor="let c of currencies" (click)="changeCurrency(c)"><span>{{ c.symbol }}</span></li>
          </ul>
        </div>
      </div>
      <div class="required-products" [@specialItem]="(!requiredloading &amp;&amp; hasPassport != true &amp;&amp; cartLength &gt; 0) ? true : false">
        <li class="product-listing" id="Passport" *ngFor="let item of requiredItems | slice:0:1">
          <div class="box-borders">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="rightline"></div>
          </div>
          <div class="bg" [style.background-position]="bgPos"></div>
          <div class="background"></div>
          <div class="product-images" *ngIf="item.images"><a routerLink="/shop/item/{{ item.id }}"><img *ngFor="let image of item.images | imageSize:'listing' | slice:0:1" src="{{ image.url }}"></a></div>
          <div class="product-details">
            <h5 class="crowd-title"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h5>
            <div class="crowd-description">{{ item.description }}</div>
          </div>
          <div class="product-controls" *ngIf="currency"><span class="price">{{ item.quantity * (item.price * this.currency.value) | number:'1.2-2' }} {{ currency.symbol }}</span></div>
          <div class="product-required"><i class="fa fa-angle-right"></i><span>You require a Passport to purchase these items</span></div>
          <div class="add-required-to-cart"><a class="customize-link" (click)="addItemToCart(item); resetUndo();"> 
              <div class="underline"></div>
              <div class="overline"></div><span>Add to Cart</span></a></div>
        </li>
      </div>
      <!-- Left Column-->
      <div class="row large">
        <div class="cart-products">
          <ul class="cart-products">
            <li class="product-listing active no-product" *ngIf="(total == 0 &amp;&amp; cartLength == 0) ? true : false">
              <div class="product-details cart-empty-title">
                <div class="background"></div>
                <h5>Your Cart Is Empty</h5><a routerLink="/shop/">Browse Identity items and products in the store.</a>
              </div>
            </li>
            <li class="cartLoader" *ngIf="(cartloading == true &amp;&amp; cartLength &gt; 0) ? true : false">
              <ul>
                <li class="loading-item" *ngFor="let item of createRange(cartLength); let i = index;">
                  <div class="swoosh"></div>
                  <div class="background"></div>
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                    <div class="rightline"></div>
                  </div>
                  <div class="picture-circle"></div>
                  <div class="title-line"></div>
                  <div class="description-line"></div>
                  <div class="items-circle"></div>
                  <div class="items-line"></div>
                  <div class="price-line"></div>
                  <div class="remove-line"></div>
                </li>
                <li class="clear"></li>
              </ul>
            </li>
            <li class="item-listing undo-remove" *ngFor="let product of removedproducts; let y = index;" [@checkoutRemoved]="removedproducts.length &gt; 0 ? true : false">
              <div class="background"></div>
              <div class="product-details"><img class="product-images" *ngFor="let image of product.images | imageSize:'thumbnail' | slice:0:1" src="{{ image.url }}">
                <h5 class="crowd-title"><a routerLink="/shop/item/{{ product.id }}">{{ product.title }}</a></h5>
              </div>
              <div class="product-controls"><a class="customize-link" (click)="resetUndo(); addToCart(product)"> 
                  <div>Undo Removal</div><i class="licon licon-delete"></i></a></div>
            </li>
            <li class="item-listing undo-remove" *ngFor="let item of removeditems; let y = index;" [@checkoutRemoved]="removeditems.length &gt; 0 ? true : false">
              <div class="background"></div>
              <div class="product-details"><img class="product-images" *ngFor="let image of item.images | imageSize:'thumbnail' | slice:0:1" src="{{ image.url }}">
                <h5 class="crowd-title"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h5>
              </div>
              <div class="product-controls"><a class="customize-link" (click)="resetUndo(); addItemToCart(item)"> 
                  <div>Undo Removal</div><i class="licon licon-delete"></i></a></div>
            </li>
            <div class="cartProducts" *ngIf="!cartloading &amp;&amp; cartLength &gt; 0">
              <li class="product-listing" id="ProductSell" *ngFor="let product of products | priceFilter:'asc'; let i = index;" [@checkoutItem]="product.quantity &gt; 0 ? true : false">
                <div class="background"></div>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                  <div class="right"></div>
                  <div class="rightline"></div>
                </div>
                <div class="product-images" *ngIf="product.images"><a routerLink="/shop/product/{{ product.id }}"><img *ngFor="let image of product.images | imageSize:'listing' | slice:0:1" src="{{ image.url }}"></a></div>
                <div class="product-details">
                  <h5 class="crowd-title"><i class="licon licon-diamond3" *ngIf="product.type == 'item'"></i><i class="licon licon-chevron-right" *ngIf="product.type == 'package'"></i><a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h5>
                  <div class="crowd-description">{{ product.description }}</div>
                </div>
                <div id="Sale" *ngIf="product.originalprice &gt; product.price"><small><span (click)="flips = (flips ? false : true)">
                      <div [attr.class]=" flips ? 'line-through' : 'line-through rev' "></div><span *ngIf="currency">$ {{ product.originalprice*currency.value | number:'1.2-2' }} {{ currency.symbol }}</span><span *ngIf="!currency">$ {{ product.originalprice | number:'1.2-2' }}</span></span></small></div>
                <div class="product-controls" *ngIf="currency"><span class="price">$ {{ product.quantity * (product.price * this.currency.value) | number:'1.2-2' }} {{ currency.symbol }}</span></div>
                <div class="product-controls" *ngIf="!currency"><span class="price">$ {{ product.quantity * product.price | number:'1.2-2' }}</span></div>
                <div class="product-features" *ngIf="product.items.length &gt; 0">
                  <ul>
                    <li *ngFor="let item of product.items | slice:0:2"><a routerLink="/shop/item/{{ item.id }}"><i class="licon licon-diamond2"></i>
                        <div class="backing"></div><span>{{ item.title }}</span></a></li>
                    <li class="theresmore"> <a routerLink="/shop/product/{{ product.id }}">More</a></li>
                    <div class="clear"></div>
                  </ul>
                </div><a class="customize-link" (click)="removeCompletely(product, i )"> 
                  <div class="underline"></div><span>Remove Product</span></a>
              </li>
              <li class="item-listing" id="ItemSell" *ngFor="let item of items | priceFilter:'asc'; let i = index;" [@checkoutItem]="item.quantity &gt; 0 ? true : false">
                <div class="background"></div>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                  <div class="right"></div>
                  <div class="rightline"></div>
                </div>
                <div class="product-images" *ngIf="item.images"><a routerLink="/shop/item/{{ item.id }}"><img *ngFor="let image of item.images | imageSize:'listing' | slice:0:1" src="{{ image.url }}"></a></div>
                <div class="product-details">
                  <h5 class="crowd-title"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h5>
                  <div class="crowd-description">{{ item.description }}</div>
                </div>
                <div class="product-controls" *ngIf="currency"><span class="price">{{ item.quantity * item.price * currency.value | number:'1.2-2' }} {{ currency.symbol }}</span></div><a class="customize-link" (click)="removeItemCompletely(item, i)"> 
                  <div class="underline"></div><span>Remove Item</span></a>
              </li>
            </div>
            <li class="cash-listing" id="CashSell" *ngIf="gift.cash &gt; 0">
              <div class="background"></div>
              <div class="box-borders">
                <div class="top"></div>
                <div class="bottom"></div>
                <div class="left"></div>
                <div class="right"></div>
                <div class="rightline"></div>
              </div>
              <div class="product-details">
                <h5 class="crowd-title"><i class="licon licon-cash-dollar"></i><a routerLink="/shop/item/6">${{ gift.cash }} Game Cash</a></h5>
              </div><a class="customize-link" (click)="gift.cash = 0"> 
                <div class="underline"></div><span>Remove</span></a>
            </li>
            <li class="clear"></li>
            <li class="gifting-warn" *ngIf="orderIsGift == true &amp;&amp; gifting == true">
              <div class="background"></div>
              <div class="box-borders">
                <div class="top"></div>
                <div class="bottom"></div>
                <div class="left"></div>
                <div class="right"></div>
                <div class="rightline"></div>
              </div><i class="left licon licon-diamond4"></i><span>You are gifting this entire order to </span><b>{{ gift.username }}</b>
            </li>
            <li class="clear"></li>
          </ul>
          <div class="clear"></div>
        </div>
      </div>
      <!-- Right Column-->
      <div class="row small">
        <!-- Cart Discounts-->
        <div class="row title-row cart-info discount-box" *ngIf="discount &gt; 0">
          <div class="border bottom">
            <div class="left"></div>
            <div class="right"></div>
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
          <div class="background"></div>
          <div class="totals">
            <div class="left">Discount</div>
            <div class="right">
              <div *ngIf="currency">{{ discount | number:'1.2-2' }} {{ currency.symbol }}</div>
            </div>
          </div>
        </div>
        <!-- Cart Info Sidebar Box-->
        <div class="row title-row cart-info total-box">
          <div class="border bottom">
            <div class="left"></div>
            <div class="right"></div>
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="top-right-line"></div>
          </div>
          <div class="background"></div>
          <div class="totals">
            <div class="left">Total</div>
            <div class="right">
              <!--div {{ total }} -->
              <div *ngIf="currency">{{ total | number:'1.2-2' }} {{ currency.symbol }}</div>
            </div>
          </div>
        </div>
        <!-- Gift Item-->
        <!-- Gift Recipients-->
        <!-- Add In-Game Cash-->
      </div>
      <div class="clear"></div>
      <div class="row">
        <!-- Shipping-->
        <div class="row payment-options" *ngIf="loggedIn &amp;&amp; (cart &gt; 0) &amp;&amp; shipping" [@fadeIn]="in">
          <div class="box-borders">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="top-line"></div>
            <div class="top-right-line"></div>
          </div>
          <div id="Error" *ngIf="shippingError">{{ shippingError }}</div>
          <h2>Shipping Details</h2>
          <div class="row editable-row" id="Shipping">
            <div class="preview" (click)="editingShipping = editingShipping ? false : true">
              <div class="top"><i class="licon licon-mailbox-empty"></i><span>Ship to:</span></div><span class="to"><span class="name">{{ profile.firstname }} {{ profile.surname }}</span></span><span class="address"><span class="housenumber">{{ profile.housenumber }} {{ profile.street }}</span><span class="city">{{ profile.city  }} {{ profile.state }} {{ profile.countrycode }} {{ profile.zip || 'None' }}</span></span>
              <div class="right">
                <button [@rotateDown]="editingShipping"><i class="licon licon-chevron-left"></i></button>
              </div>
            </div>
            <div class="inside" [@showShipping]="editingShipping">
              <div class="editable firstname">
                <label for="firstname">First Name:</label>
                <input class="input-normal" type="text" placeholder="first name" name="firstname" [(ngModel)]="profile.firstname">
              </div>
              <div class="editable surname">
                <label for="surname">Surname:</label>
                <input class="input-normal" type="text" placeholder="surname" name="surname" [(ngModel)]="profile.surname">
              </div>
              <div class="editable housenumber">
                <label for="housenumber">House / Unit Number:</label>
                <input class="input-normal" type="text" placeholder="house number" name="housenumber" [(ngModel)]="profile.housenumber">
              </div>
              <div class="editable Street">
                <label for="street">Street:</label>
                <input class="input-normal" type="text" placeholder="street" name="street" [(ngModel)]="profile.street">
              </div>
              <div class="editable zip">
                <label for="zip">ZIP / Postal Code:</label>
                <input class="input-normal" type="text" placeholder="zip" name="zip" [(ngModel)]="profile.zip">
              </div>
              <div class="editable city">
                <label for="city">City:</label>
                <input class="input-normal" type="text" placeholder="city" name="city" [(ngModel)]="profile.city">
              </div>
              <div class="editable state">
                <label for="state">State / Provence:</label>
                <input class="input-normal" type="text" placeholder="state" name="state" [(ngModel)]="profile.state">
              </div>
              <div class="editable countrycode">
                <label for="countrycode">Country:</label>
                <select class="selectable" name="countrycode" [(ngModel)]="profile.countrycode">
                  <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
                </select>
              </div>
              <div class="editable save">
                <button class="selectable" (click)="updateAddress()">Update Address</button>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
        <!-- Info Bar-->
        <div class="row title-row cart-info">
          <div class="border bottom">
            <div class="left"></div>
            <div class="right"></div>
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="top-line"></div>
            <div class="top-right-line"></div>
          </div>
          <div class="background"></div>
          <div class="left crowd-title">
            <div>{{ cartLength }} {{ cartLength == 1 ? 'Item' : 'Items' }}</div>
          </div>
          <div class="right">
            <div *ngIf="currency">{{ total | number:'1.2-2' }} {{ currency.symbol }}</div>
          </div>
        </div>
        <!-- Payment-->
        <div [attr.class]="(hasPassport == true) ? 'row payment-options green' : 'row payment-options'" *ngIf="loggedIn &amp;&amp; cartLength &gt; 0" [@fadeIn]="in">
          <div class="box-borders">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="right"></div>
            <div class="top-line"></div>
            <div class="top-right-line"></div>
          </div>
          <div class="passport_warn" *ngIf="hasPassport != true &amp;&amp; cartLength &gt; 0"> <i class="licon licon-notification-circle"></i><span>A Passport is required in order to complete this purchase.</span></div>
          <div [attr.class]="(hasPassport == true) ? 'shown' : 'hidden'">
            <h2>Order Payment</h2>
            <ul *ngFor="let user of accounts">
              <!-- Payment Method container-->
              <li class="idcredit" *ngFor="let paymentMethod of method" (click)="openPaymentWindow(paymentMethod.service)">
                <div [attr.class]="( paymentMethod.service == 'account' &amp;&amp; user.balance &lt; total ) ? 'disabled accent' : 'accent'">
                  <div class="shadow"></div>
                  <div class="texture"></div>
                  <div class="stripe"></div>
                </div>
                <div class="payment-icon"><i class="fa fa-paypal" *ngIf="paymentMethod.service == 'paypal'" aria-hidden="true"></i><i class="fa fa-paypal" *ngIf="paymentMethod.service == 'paypal-hosted'" aria-hidden="true"></i>
                  <div *ngIf="paymentMethod.service == 'gc'"><i class="fa fa-cc-mastercard" aria-hidden="true"></i><i class="fa fa-cc-visa" aria-hidden="true"></i></div>
                </div>
                <!-- Display the amount of credits associated with the account-->
                <div class="credits" *ngIf="paymentMethod.service == 'account'"><span>{{ user.balance | number:'1.2-2' }} {{ currency.symbol }}</span></div>
                <!-- Display the name of payment method--><span class="payment-label">{{ paymentMethod.name }}</span>
              </li>
              <li *ngFor="let product of paymentProducts">{{ product.displayHints.label }}</li>
            </ul>
          </div>
        </div>
        <div class="row" *ngIf="!loggedIn">
          <login-options></login-options>
        </div>
      </div>
    </div>
  </div>
  <!-- Overlay and Payment Window-->
  <div class="is-visible-on-mobile" id="Overlay" [@overlay]="showPaymentFixture">
    <div class="is-visible-on-mobile" id="Darkness" (click)="showPaymentFixture = 'hidden'"></div>
    <div id="PaymentWindow" [@paymentwindow]="paymentFixture" *ngIf="currency">
      <div id="Details">
        <div class="close" *ngIf="paymentFixture != '4'" (click)="showPaymentFixture = 'hidden'">x</div>
        <div class="close active" *ngIf="paymentFixture === '4'" (click)="backToOrder()"><i class="fa fa-angle-left"></i></div><span *ngFor="let user of accounts">
          <div class="picture"><img src="{{ user.image }}"></div>
          <div class="username">{{ user.username }}</div></span>
        <div class="order"><span>{{ cartLength }} {{ cartLength == 1 ? 'Item' : 'Items' }}</span><span>
             ( {{ total | number:'1.2-2' }} <small>{{ currency.symbol }}</small>)</span></div>
        <div id="Errors" [@paymenterror]="errorCode">{{ errorMsg }}</div>
      </div>
      <div id="EntryWindow">
        <form id="OrderForm" [@payment]="paymentFixture">
          <input class="input-normal cc-input" type="text" name="cardholderName" placeholder="Name" required="" autocomplete="cc-name" [(ngModel)]="credit.cardholderName" #name="ngModel">
          <input class="input-normal cc-input" type="text" name="cardNumber" placeholder="Card Number" required="" autocomplete="cc-number" [(ngModel)]="credit.cardNumber" #name="ngModel">
          <select class="order-selector cc-input" name="paymentProductId" [(ngModel)]="credit.paymentProductId" autocomplete="cc-type" #name="ngModel">
            <option value="1" selected> Visa </option>
            <option value="3"> MasterCard </option>
            <option value="2"> American Express </option>
            <option value="128"> Discover </option>
            <option value="132"> Diners Club </option>
            <option value="114"> Visa Debit </option>
            <option value="119"> MasterCard Debit </option>
            <option value="122"> Visa Electron </option>
            <option value="125"> JCB </option>
            <option value="117"> Maestro </option>
            <option value="130"> Carte Bancaire </option>
            <option value="135"> Cabal </option>
            <option value="136"> Naranja </option>
            <option value="137"> Nevada </option>
            <option value="139"> Italcred </option>
            <option value="140"> Argencard </option>
            <option value="141"> Consumax </option>
            <option value="142"> Mas </option>
            <option value="144"> Pyme Nacion </option>
            <option value="145"> Nativa </option>
            <option value="146"> Aura </option>
            <option value="147"> ELO </option>
            <option value="148"> Hipercard </option>
            <option value="149"> Tarjeta Shopping </option>
          </select>
          <input class="input-normal cc-input half-width" type="text" placeholder="CCV" name="cvv" autocomplete="cc-csc" required="" [(ngModel)]="credit.cvv" #name="ngModel">
          <input class="input-normal cc-input half-width" type="text" placeholder="MM/YY" name="expiryDate" autocomplete="cc-exp" required="" [(ngModel)]="credit.expiryDate" #name="ngModel">
          <div class="purchase-button-container">
            <button class="buy button purchase-cart" (click)="purchase()">Pay {{ total | number:'1.2-2' }} {{ currency.symbol }}</button>
          </div>
          <p class="terms">
            <input id="cc-terms" required="" type="checkbox" name="cc-terms" [checked]="credit.terms" (change)="credit.terms = !credit.terms">
            <label for="cc-terms">I agree with the</label><a (click)="showTerms()">Terms and Conditions</a>.
          </p>
        </form>
        <div id="PostPayment" [@paymentresult]="paymentFixture">
          <div class="spinningLogo"><img src="/assets/img/identity_head.png"></div>
          <h2 *ngIf="!this.orderIsGift">Order Complete</h2>
          <h2 *ngIf="this.orderIsGift">Gift Sent!</h2>
          <div class="paid">
            <p>You have paid <strong>{{ total | number:'1.2-2' }} {{ currency.symbol }}</strong> for {{ cartLength }} {{ (cartLength > 1) ? 'Items' : 'Item' }}.</p>
          </div>
          <div class="post-purchase-action"><a class="buy button purchase-cart" *ngIf="!this.orderIsGift" routerLink="/account/view/">View Your Items </a><a class="buy button purchase-cart" *ngIf="this.orderIsGift" routerLink="/store/">Back to Store</a></div>
        </div>
        <div id="PayPalPayment" [@paypalpayment]="paymentFixture">
          <div class="account-credits">
            <div class="cart-total">Total In Cart</div>
            <div class="cart-amount">{{ total | number:'1.2-2' }} {{ currency.symbol }}</div>
          </div>
          <p>Complete your order below.</p>
          <div id="PayPalLink" *ngIf="hosted == true" (click)="checkHostedPayPal()">
            <div class="paypal-loading" *ngIf="!paypalCheckout"><i class="fa fa-spin fa-spinner fa-2x" *ngIf="credit.terms"></i></div><a [attr.href]="paypalCheckout" target="_blank"></a>
          </div>
          <div id="paypal-button-container" *ngIf="hosted == false"></div>
          <p class="terms">
            <input required="" type="checkbox" name="paypal-terms" [checked]="credit.terms" (change)="credit.terms = !credit.terms;paypalHostedCheckout();">
            <label for="paypal-terms" (click)="credit.terms = !credit.terms;paypalHostedCheckout();">I agree with the</label><a (click)="this.showTerms()">Terms and Conditions</a>.  
          </p>
        </div>
        <div id="AccountPayment" [@accountpayment]="paymentFixture" *ngFor="let user of accounts">
          <div class="account-credits">
            <div class="credit-title">Account Credits</div>
            <div class="amount">{{ user.balance | number:'1.2-2' }} {{ currency.symbol }}</div>
            <hr>
            <div class="cart-total">Total In Cart</div>
            <div class="cart-amount">{{ total | number:'1.2-2' }} {{ currency.symbol }}</div>
          </div>
          <p class="account-prompt" *ngIf="user.balance &lt; total">Your account does not have enough credits.</p>
          <p class="account-prompt" *ngIf="user.balance &gt;= total">Pay for your order with account credits.</p>
          <div class="purchase-button-container">
            <button class="buy button purchase-cart" (click)="purchaseWithAccountCredits()">Pay {{ total | number:'1.2-2' }} {{ currency.symbol }}</button>
          </div>
          <p class="terms">
            <input id="cc-terms" required="" type="checkbox" name="cc-terms" [checked]="credit.terms" (change)="credit.terms = !credit.terms">
            <label for="cc-terms">I agree with the</label><a (click)="this.showTerms()">Terms and Conditions</a>.
          </p>
        </div>
        <div id="Terms" [@terms]="paymentFixture">
          <iframe src="/assets/terms.html"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>