
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="Corp">
  <div class="hashAncor" id="contentpage">
    <div class="slim-heading">
      <div class="background"></div>
      <h2>Corporations in Identity</h2>
    </div>
    <div class="container">
      <div class="row">
        <p class="large">You've made a lot of cash working odd jobs, or maybe even the odd <i>less-than-legal task;</i><br>Now that you've had a taste of success, <b>it's time to forge an empire</b>.</p>
        <div class="corp-box"> 
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <p>Corporations will allow the successful with a little extra cash to become driving forces in Identity's dynamic economy. Corporations are organizations similar to, but separate from, gangs. They have the added ability of owning assets and issuing work orders to their real-player employees. Some assets, such as the largest cargo trucks, are available only to corporations.</p>
        </div>
        <div class="corp-box">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <p>Once formed, the corporation is assigned a telephone number for public contact and the director (owner) can build a logo using an in-game design tool. It's then time to hire a staff and get to work.</p>
        </div>
        <div class="class-container">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <h4 class="corp-classes-title"><span>CLASSES OF CORPORATIONS</span></h4>
          <div class="corp-classes">
            <div class="corp-class" id="Delivery" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-truck"></i></div>
              <div class="title">Delivery</div>
              <div class="desc">Delivery corporations can buy large vehicles for big hauls, vehicles not available to the general public. While any player can deliver goods in vehicles such as vans, to get the big-bucks you'll need to join a corporation.</div>
            </div>
            <div class="corp-class" id="Legal" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-balance"></i></div>
              <div class="title">Legal</div>
              <div class="desc">Let's face it, crimes happen and criminals are going to be arrested. They'll need someone on the outside to convince the jury that they did nothing wrong. This is where legal corporations come into play, and their services aren't always cheap.</div>
            </div>
            <div class="corp-class" id="Transportation" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-plane"></i></div>
              <div class="title">Transportation</div>
              <div class="desc">People need to get from point A to point B and they don't always have the means to do that themselves. Transportation corporations own special vehicle assets including taxis and more, helping those in need get where they need to be.</div>
            </div>
            <div class="corp-class" id="Manufacturing" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-hammer-wrench"></i></div>
              <div class="title">Manufacturing</div>
              <div class="desc">From the shoes on your feet to the sofa under your seat, almost everything in Identity is built and crafted by other players. Manufacturing corporations give their employees access to a wider variety of constructables and resources, for a cut of the profits.</div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="slim-heading bottom">
      <div class="background"></div>
      <div class="background2"></div>
      <div class="background3"></div>
      <h2>Always Expanding</h2>
      <div class="container bottom">
        <div class="corp-box">
          <p>As with most things in Identity, we at Asylum will constantly be expanding this system adding new classes of corporations and building on those already available. Identity's release is only the beginning. If you have any ideas for a well-suited corporation class, let us know on the forums.</p>
        </div>
        <div class="corp-box">
          <div class="align-center learn-more"><a class="btn-primary" href="http://www.identityrpg.com/community/"> <span>Go to the forums</span></a><a class="btn-primary" routerLink="/shop"><span>Purchase Identity</span></a></div>
        </div>
      </div>
    </div>
  </div>
</div>