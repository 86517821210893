
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section class="blue" id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false">
  <div class="container">
    <div class="row title-row">
      <div class="border">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="bottom-line"></div>
      </div>
      <div class="background"></div>
      <h5 class="left crowd-title">
        <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a>{{ title }}</a>
      </h5><a class="right shop-link" routerLink="{{ subtitleLink }}"><i class="subicon" [attr.class]="subtitleIcon"></i><span class="back-to-shop">{{ subtitle }}</span></a>
    </div>
  </div>
</section>
<div class="store-buttons"><a [attr.class]="flashCart ? 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue active' : 'cart-button mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-blue'" [@zFade]="flashCart" routerLink="/shop/checkout/">
    <div class="background"></div>
    <div class="background2"></div>
    <div class="cart-items">
      <div>{{ cart.length + ( custom ? custom.length:0 ) }}</div>
      <div class="small">{{ cart.length + ( custom ? custom.length:0 ) == 1 ? 'Item' : 'Items' }}</div>
    </div><i class="licon licon-cart-full"></i></a></div>
<!-- Cart details-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="cart-details" id="Overlay" [@cartoverlay]="showDetails" *ngIf="selectedProduct != null">
  <div id="Darkness" (click)="showDetails = 'hide'"></div>
  <div id="CartDetails" [@cartoverlay]="showDetails">
    <div class="box-borders">
      <div class="top"></div>
      <div class="left"></div>
      <div class="right"></div>
      <div class="bottom"></div>
    </div>
    <div class="title">
      <div class="backgrounds">
        <div class="gradients"></div>
        <div class="stripes"></div>
      </div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="right"></div>
        <div class="bottom"></div>
        <div class="left"></div>
      </div><i class="licon licon-cross-square close" (click)="showDetails = 'hide'"></i>
      <h2 *ngFor="let product of products | slice:selectedProduct:selectedProduct+1"> <a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h2>
    </div>
    <div id="Frame">
      <div id="InternalDetails">
        <div id="Loading" *ngIf="loadingCart"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>
        <div id="Item" *ngFor="let product of products | slice:selectedProduct:selectedProduct+1">
          <div class="row">
            <div id="ProductImages" *ngIf="product.images">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div><a routerLink="/shop/product/{{ product.id }}"><img class="slide" *ngFor="let image of product.images | imageSize:'listing'" [attr.src]="image.url"></a>
            </div>
            <div class="crowd-box" id="ProductDetails">
              <div class="crowd-details">
                <h5 class="crowd-title"><a routerLink="/shop/product/{{ product.id }}">{{ product.title }}</a></h5>
              </div>
              <div class="crowd-description">{{ product.html }}</div>
            </div>
            <h5 class="included_items_title">Items Included</h5>
            <div class="included_items" id="Items" *ngIf="product.items.length &gt; 0">
              <div class="box-borders">
                <div class="top"></div>
                <div class="right"></div>
                <div class="bottom"></div>
                <div class="left"></div>
              </div>
              <ul>
                <li *ngFor="let item of product.items"><a routerLink="/shop/item/{{ item.id }}">
                    <div *ngIf="item.groupinfo"><i *ngIf="item.groupinfo.icon" [attr.class]="item.groupinfo.icon"></i></div><span>{{ item.title }}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="Actions">
        <div class="direction-controls lightbox-controls">
          <div class="background"></div>
          <div class="double-borders">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="left-side">
              <div class="left"></div>
              <div class="top-left"></div>
              <div class="bottom-left"></div>
            </div>
            <div class="right-side">
              <div class="right"></div>
              <div class="top-right"></div>
              <div class="bottom-right"></div>
            </div>
          </div>
          <div class="next" *ngFor="let product of products | slice:selectedProduct+1:selectedProduct+2; let i = index;" (click)="nextCartItem()"><span>next</span><i class="licon licon-chevron-right"></i></div>
          <div class="prev" *ngFor="let product of products | slice:selectedProduct-1:selectedProduct; let i = index;" (click)="prevCartItem()"><i class="licon licon-chevron-left"></i><span>prev</span></div>
        </div>
        <div class="direction-controls">
          <div class="next" routerLink="/shop/product/{{ selectedProduct.id }}">
            <div class="background"></div>
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div><span>View</span><i class="licon licon-bookmark2"></i>
          </div>
          <div class="prev" (click)="showDetails = 'hide'">
            <div class="background"></div>
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div><i class="licon licon-cross2"></i><span>Close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Image Zoom-->
<!-- ShowLightbox:	boolean-->
<!-- showImage: 		int-->
<!-- products: 		Product-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="Overlay" [@overlay]="ShowLightbox">
  <div id="Darkness" [@overlay]="ShowLightbox" (click)="ShowLightbox = false"></div>
  <div id="ItemImageModal" *ngFor="let listing of products">
    <div class="box-borders">
      <div class="top"></div>
      <div class="right"></div>
      <div class="bottom"></div>
      <div class="left"></div>
    </div><i class="licon licon-cross2" (click)="ShowLightbox = false"></i>
    <div class="lines">
      <div class="top-left" *ngIf="showImage &gt; 0"></div>
      <div class="top-right" *ngIf="showImage &lt; totalImages-1"></div>
    </div>
    <div class="sides" (swipeleft)="swipeImage($event.type, totalImages)" (swiperight)="swipeImage($event.type, totalImages)">
      <div class="left" (click)="swipeImage('swiperight', totalImages)"></div>
      <div class="right" (click)="swipeImage('swipeleft', totalImages)"></div>
    </div>
    <div class="header highlight">
      <h2> <span>{{ listing.title }}</span><span *ngIf="totalImages &gt; 1">( {{ showImage+1 }} / {{ totalImages }} )</span></h2>
      <div class="border">
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
    <div id="ItemImages">
      <div id="ImageLightbox" *ngFor="let image of listing.images | imageSize:'listing' | slice:showImage:showImage+1; let i = index;"><a routerLink="image.url"><img src="{{ image.url }}"></a></div>
    </div>
    <ul id="ItemImageThumbs">
      <li *ngFor="let image of listing.images | imageSize:'listing'; let i = index;" [attr.class]="(showImage == i) ? &quot;thumb active&quot; : &quot;thumb&quot;">
        <div class="box-borders">
          <div class="top"></div>
          <div class="right"></div>
          <div class="bottom"></div>
          <div class="left"></div>
        </div><img src="{{ image.url }}" (click)="showImage = i;">
      </li>
    </ul>
  </div>
</div>
<!-- Product Points-->
<!-- ShowPointbox:	boolean-->
<!-- pointOpen: 		int-->
<!-- products: 		Product-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="Overlay" [@overlay]="ShowPointbox">
  <div id="Darkness" [@overlay]="ShowPointbox" (click)="closePoint()"></div>
  <div id="ItemPointsModal" *ngFor="let listing of products;">
    <div class="box-borders">
      <div class="top"></div>
      <div class="right"></div>
      <div class="bottom"></div>
      <div class="left"></div>
    </div><i class="licon licon-cross2" (click)="closePoint()"></i>
    <ul *ngFor="let point of listing.points | slice:pointOpen:pointOpen+1">
      <li> 
        <h2>{{ point.label }}</h2>
        <p>{{ point.description }}</p>
      </li>
    </ul>
  </div>
</div>
<div class="Loader" *ngIf="loading">
  <div class="background"></div>
  <div class="spinner"><i class="licon licon-spinner"></i></div>
</div>
<div id="Product">
  <div class="hashAncor" id="contentpage">
    <div class="container">
      <div class="container title-row" id="Subtitle">
        <div class="left">
          <div class="back">
            <div class="background"></div><a routerLink="/products">
              <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i></div><span>Products</span></a>
          </div>
        </div>
        <div class="rightside">
          <share-menu sharelisting="this.products[0]"></share-menu>
        </div>
      </div>
      <div class="container" *ngIf="loading">
        <!-- +mixname-->
        <!-- +imagebox('let listing of items')-->
        <div class="loader" id="SingleItem">
          <div class="row">
            <div class="col-1">
              <div id="ProductPrices">
                <div class="background"></div>
                        <div class="borders">
                          <div class="top"></div>
                          <div class="left"></div>
                          <div class="bottom"></div>
                          <div class="right"></div>
                          <div class="topline"></div>
                        </div>
                <div class="swoosh"></div>
              </div>
              <div id="ProductPrices">
                <div class="background"></div>
                        <div class="borders">
                          <div class="top"></div>
                          <div class="left"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="bottomline"></div>
                        </div>
                <div class="swoosh"></div>
              </div>
              <div class="large" id="ProductPrices">
                <div class="background"></div>
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="swoosh"></div>
              </div>
            </div>
            <div class="col-2">
              <div id="NoImages">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="swoosh"></div>
              </div>
              <div class="buttoncontrols">
                <div class="next">
                  <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><span>next</span><i class="licon licon-chevron-right"></i>
                </div>
                <div class="prev">
                  <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><i class="licon licon-chevron-left"></i><span>prev</span>
                </div>
                <div class="zoom">
                  <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><i class="licon licon-loupe-zoom-in"></i><span>zoom</span>
                </div>
              </div>
            </div>
          </div>
          <div id="AdditionalProducts">
            <div id="AdditionalItems">
              <div class="background"></div>
              <div class="borders">
                <div class="top"></div>
                <div class="left"></div>
                <div class="bottom"></div>
                <div class="right"></div>
              </div>
              <ul class="item_listings" id="StoreItems">
                <div class="title">
                  <h4 class="highlight"> <span>Loading...</span>
                    <div class="border">
                      <div class="bottom"></div>
                      <div class="left"></div>
                      <div class="right"></div>
                    </div>
                  </h4>
                </div>
                <li class="row grid_4 crowd-box">
                  <ul id="ItemImages"><a>
                      <li>
                        <div class="swoosh"></div><img class="slide">
                      </li></a></ul>
                  <h5 class="crowd-title"><a>Loading</a></h5>
                </li>
                <li class="row grid_4 crowd-box">
                  <ul id="ItemImages"><a>
                      <li>
                        <div class="swoosh"></div><img class="slide">
                      </li></a></ul>
                  <h5 class="crowd-title"><a>Loading</a></h5>
                </li>
              </ul>
            </div>
          </div>
          <div class="links" id="Links">
            <div class="swoosh"></div>
            <div class="background"></div>
            <div class="borders">
              <div class="top"></div>
              <div class="left"></div>
              <div class="bottom"></div>
              <div class="right"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="!loading">
        <div id="SingleProduct" *ngFor="let listing of products; let i = index;">
          <div class="Seperator" *ngIf="i &gt; 0"></div>
          <!-- Auto add sidebar...-->
          <!--( [style.width]="(numberOfProducts > 0) ? '75%' : '100%'" )-->
          <!-- Product Details-->
          <div class="row">
            <!-- Left Columns -->
            <div class="col-1">
              <div class="crowd-box" id="ProductDetails">
                <div class="background"></div>
                        <div class="borders">
                          <div class="top"></div>
                          <div class="left"></div>
                          <div class="bottom"></div>
                          <div class="right"></div>
                          <div class="topline"></div>
                        </div>
                <div class="crowd-description">{{ listing.description }}</div>
              </div>
              <div id="PointsList" *ngIf="listing.points.length &gt; 0">
                <div class="background"></div>
                        <div class="borders">
                          <div class="top"></div>
                          <div class="left"></div>
                          <div class="leftline"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                        </div><span class="PointsTitle">Points:</span>
                <ul> 
                  <li *ngFor="let point of listing.points; let l = index;"><i *ngIf="point.icon" [attr.class]="point.icon"></i><span (click)="openPoint(l)">{{ point.label }}</span></li>
                </ul>
              </div>
              <div id="ProductPrices">
                <div class="background"></div>
                        <div class="borders">
                          <div class="top"></div>
                          <div class="left"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="bottomline"></div>
                        </div>
                <div id="Currency" *ngIf="currency &amp;&amp; enableCurrencySelector" (click)="currencyOpen = currencyOpen ? false : true"><i class="fa fa-angle-right" [@rotateOpen]="currencyOpen"></i><span>{{ currency.symbol }}</span>
                  <ul class="currencies" [@animateOpen]="currencyOpen">
                    <li *ngFor="let c of currencies" (click)="changeCurrency(c)"><span>{{ c.symbol }}</span></li>
                  </ul>
                </div>
                <div id="Stock" [attr.class]="( (listing.stock &gt; 0 || listing.stock == '-1') ? 'instock' : 'nostock' )">
                  <div [attr.class]="( (listing.stock &gt; 0 || listing.stock == '-1') ? 'indicator green' : 'indicator red' )"></div><span class="stocknumber" *ngIf="listing.stock &gt; 0">{{ listing.stock }} </span><span>{{ ( (listing.stock > 0 || listing.stock == '-1') ? 'In Stock' : 'Out of Stock' ) }}</span>
                </div>
                <div id="Sale" *ngIf="currency &amp;&amp; listing.price &gt; 0"><small *ngIf="listing.originalprice &gt; listing.price"><span (click)="flips = (flips ? false : true)">
                      <div [attr.class]=" flips ? 'line-through' : 'line-through rev' "></div><span>$ {{ listing.originalprice*currency.value | number:'1.2-2' }} {{ currency.symbol }}</span></span></small></div>
                <div id="Sale" *ngIf="!currency &amp;&amp; listing.price &gt; 0"><small *ngIf="listing.originalprice &gt; listing.price"><span (click)="flips = (flips ? false : true)">
                      <div [attr.class]=" flips ? 'line-through' : 'line-through rev' "></div><span>$ {{ listing.originalprice | number:'1.2-2' }}</span></span></small></div>
                <div id="Price" *ngIf="listing.price &gt; 0"><span *ngIf="currency">{{ this.currency.value*listing.price | number:'1.2-2' }} {{ currency.symbol }}</span><span *ngIf="!currency">$ {{ listing.price | number:'1.2-2' }}</span></div>
                <div id="Price" *ngIf="listing.price == 0"><span *ngIf="currency">{{ this.currency.value*listing.originalprice | number:'1.2-2' }} {{ currency.symbol }}</span><span *ngIf="!currency">$ {{ listing.originalprice | number:'1.2-2' }}</span></div>
              </div>
              <div id="ProductPoints">
                <ul>
                  <li *ngIf="purchaseButton">
                            <div id="PurchaseButton" [attr.class]="inCart(listing.id) ? 'active' : ''">
                              <button class="buy button add-to-cart" (click)="addToCart( listing )">
                                        <div class="box-borders">
                                          <div class="top"></div>
                                          <div class="right"></div>
                                          <div class="bottom"></div>
                                          <div class="left"></div>
                                        </div>
                                <div class="background"></div>
                                <div class="activeborders">
                                  <div class="top"></div>
                                  <div class="left"></div>
                                  <div class="bottom"></div>
                                  <div class="right"></div>
                                </div>
                                <div class="highlight"></div><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; !inCart(listing.id)">Add to Cart</span><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; inCart(listing.id)">Checkout</span><span *ngIf="(listing.limit != 0 &amp;&amp; listing.limit &lt;= listing.owns)">Player Owned</span>
                              </button>
                            </div>
                  </li>
                  <li class="notice" *ngIf="stockNotice">
                            <div id="Stock" [@stock]="listing.instock"><i [attr.class]="( listing.instock ? 'licon licon-check-square' : 'licon licon-cross-square' )"></i><span>{{ ( listing.instock ?  (listing.stock > 0 ? listing.stock : '') + ' In Stock' : 'No Stock' ) }}</span></div>
                  </li>
                  <li class="notice" *ngIf="limitNotice">
                            <div id="Limit" *ngIf="listing.limit != false"><i class="licon licon-menu-square"></i><b>Limit {{ listing.limit }} Per Account</b></div>
                  </li>
                  <li class="notice" *ngIf="betaNotice">
                            <div class="beta-notice" id="Beta"><a routerLink="/modules"><i class="licon licon-warning"></i><b>Includes Module Play</b></a></div>
                  </li>
                  <li class="notice limitedTime" *ngIf="listing.limited &gt; 0">
                            <div id="LimitedTimeNotice"><i class="licon licon-timer"></i><b>Limited Time Offer</b></div>
                  </li>
                  <li class="notice requirementsNotice" *ngIf="requirementsNotice">
                            <div id="SystemRequirements"><a><i class="licon licon-notification"></i><b>System Requirements</b></a></div>
                  </li>
                </ul>
              </div>
              <div id="ProductItems" (swiperight)="itemBoxLeft = (itemBoxLeft &gt; 0) ? itemBoxLeft-1 : itemBoxLeft" (swipeleft)="itemBoxLeft = (itemBoxLeft &lt; listing.itemsCount-1) ? itemBoxLeft+1 : itemBoxLeft">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="background"></div>
                <div class="indicator">
                  <div class="next" (click)="itemBoxLeft = itemBoxLeft+1" *ngIf="itemBoxLeft &lt; listing.itemsCount-1"><i></i></div>
                  <div class="prev" (click)="itemBoxLeft = itemBoxLeft-1" *ngIf="itemBoxLeft &gt; 0"><i></i></div>
                </div>
                <div class="controls">
                  <div class="next" (click)="itemBoxLeft = itemBoxLeft+1" *ngIf="itemBoxLeft &lt; listing.itemsCount-1"><i></i></div>
                  <div class="prev" (click)="itemBoxLeft = itemBoxLeft-1" *ngIf="itemBoxLeft &gt; 0"><i></i></div>
                </div>
                <ul> 
                  <li *ngFor="let item of listing.items | slice:itemBoxLeft:itemBoxLeft+1">
                    <div class="title"><a routerLink="/item/{{ item.id }}">{{ item.title }}</a></div>
                    <div *ngFor="let image of item.images | imageSize:'thumbnail' | slice:0:0+1"><img src="{{ image.url }} "></div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-2">
              <div id="NoImages" *ngIf="listing.imagecount == 0">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div><i class="licon licon-picture"></i>
              </div>
              <div id="ProductImages" *ngIf="listing.imagecount &gt; 0">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="background"></div>
                <ul class="indicator" *ngIf="listing.imagecount &gt; 2">
                  <li *ngFor="let image of listing.images | imageSize:'listing'; let i = index;" [attr.class]="(showImage==i) ? 'active' : 'inactive'" [style.width]="100/listing.imagescount+'%'">
                    <div class="right-hook"></div>
                    <div class="left-hook"></div>
                  </li>
                </ul>
                <ul class="main">
                  <li *ngFor="let image of listing.images | imageSize:'listing' | slice:showImage:showImage+1" (swipeleft)="swipeImage($event.type, listing.imagescount)" (swiperight)="swipeImage($event.type, listing.imagescount)" (click)="ShowLightbox = true"><img class="slide" (click)="openLightbox(image)" src="{{ image.url }}"></li>
                </ul>
              </div>
              <div class="buttoncontrols">
                <div class="next" (click)="showImage = showImage+1" *ngIf="showImage &lt; listing.imagescount-1">
                  <div class="swoosh"></div>
                  <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><span>next</span><i class="licon licon-chevron-right"></i>
                </div>
                <div class="prev" (click)="showImage = showImage-1" *ngIf="showImage &gt; 0">
                  <div class="swoosh"></div>
                  <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><i class="licon licon-chevron-left"></i><span>prev</span>
                </div>
                <div class="zoom" (click)="ShowLightbox = true" [style.margin]="showImage &gt; 0 ? '0 3%' : '0'" *ngIf="zoomable">
                  <div class="swoosh"></div>
                  <div class="background"></div>
                          <div class="box-borders">
                            <div class="top"></div>
                            <div class="right"></div>
                            <div class="bottom"></div>
                            <div class="left"></div>
                          </div><i class="licon licon-loupe-zoom-in"></i><span>zoom</span>
                </div>
              </div>
              <div class="crowd-box" id="ProductDetails">
                <div class="background"></div>
                        <div class="borders">
                          <div class="top"></div>
                          <div class="left"></div>
                          <div class="bottom"></div>
                          <div class="right"></div>
                          <div class="topline"></div>
                        </div>
                <div class="crowd-description" *ngIf="listing.html" [innerHTML]="listing.html"></div>
              </div>
              <div id="Items" *ngIf="listing.items.length &gt; 0">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <div class="background"></div>
                <h4 class="highlight"> <span>{{ listing.title }} Includes:</span>
                  <div class="border">
                    <div class="bottom"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                  </div>
                </h4>
                <ul>
                  <li *ngFor="let item of listing.items"><a routerLink="/shop/item/{{ item.id }}"><i class="licon licon-diamond2"></i>
                      <div class="backing"></div><span>{{ item.title }}</span></a></li>
                  <div class="clear"></div>
                </ul>
                <div class="total">{{ listing.itemtotal }}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!loading">
          <div id="AdditionalProducts">
            <div id="AdditionalItems" (swipeleft)="x = (x &lt; featureCount-2) ? x+1 : x" (swipedown)="x = (x &lt; featureCount-2) ? x+1 : x" (swiperight)="x = (x &gt; 0) ? x-1 : x" (swipeup)="x = (x &gt; 0) ? x-1 : x" [style.touch-action]="'pan-y'">
              <div class="background"></div>
              <div class="borders">
                <div class="top"></div>
                <div class="left"></div>
                <div class="bottom"></div>
                <div class="right"></div>
                <!--.right-line( [style.top]="((x/featureCount-2)*100)+'%'" )-->
                <!--.left-line( [style.bottom]="((x/featureCount-2)*66)+'%'" )-->
              </div>
              <ul class="item_listings" id="StoreItems">
                <div class="title">
                  <h4 class="highlight"> <span>Additional Products</span>
                    <div class="border">
                      <div class="bottom"></div>
                      <div class="left"></div>
                      <div class="right"></div>
                    </div>
                  </h4>
                </div>
                <li class="row grid_4 crowd-box" *ngFor="let listing of features | slice:x:x+2; let i = index;">
                  <ul id="ItemImages">
                    <li *ngFor="let image of listing.images | imageSize:'listing' | slice:0:1"><a routerLink="/shop/product/{{ listing.id }}"><img class="slide" src="{{ image.url }}"></a></li>
                  </ul>
                  <h5 class="crowd-title"><a routerLink="/shop/product/{{ listing.id }}">{{ listing.title }}</a></h5>
                  <!--.crowd-descriptiona( routerLink="/shop/product/{{ listing.id }}" ) {{ listing.html }}
                  -->
                  <!--.crowd-pleaser
                  span.onsale $ {{ listing.price | number:'.2-2' }}
                  .buttons( *ngIf="this.inCart(listing) == false" )
                  	button( (click)="addToCart( listing )", [attr.class]="listing.owned ? 'buy button add-to-cart bought' : 'buy button add-to-cart'" )
                  		.army( *ngIf="listing.owned" )
                  			.shine
                  		span {{ ( listing.owned ? 'Owned' : 'Buy' ) }}
                  .buttons( *ngIf="this.inCart(listing) == true", routerLink="/checkout/" )
                  	button.buy.button.add-to-cart
                  		span In Cart
                  
                  -->
                  <div class="Price" *ngIf="currency"> <span>{{ this.currency.value*listing.price | number:'1.2-2' }} {{ currency.symbol }}</span></div>
                </li>
              </ul>
              <div class="controls">
                <div class="next" (click)="x = x+1" *ngIf="x &lt; featureCount-2"><i class="licon licon-chevron-right"></i></div>
                <div class="prev" (click)="x = x-1" *ngIf="x &gt; 0"><i class="licon licon-chevron-left"></i></div>
              </div>
            </div>
          </div>
          <!-- +mixname-->
          <!-- +imagebox('let listing of items')-->
          <div id="Links">
            <div class="background"></div>
            <div class="borders">
              <div class="top"></div>
              <div class="left"></div>
              <div class="bottom"></div>
              <div class="right"></div>
            </div>
            <ul>
              <li class="community"><a routerLink="/categories">
                            <div class="box-borders">
                              <div class="top"></div>
                              <div class="right"></div>
                              <div class="bottom"></div>
                              <div class="left"></div>
                            </div>
                  <div class="background"></div>
                  <div class="title">
                    <div class="titlebackground"></div><span>Categories</span>
                  </div></a></li>
              <li class="game-shop"><a routerLink="/items">
                            <div class="box-borders">
                              <div class="top"></div>
                              <div class="right"></div>
                              <div class="bottom"></div>
                              <div class="left"></div>
                            </div>
                  <div class="background"></div>
                  <div class="title"> 
                    <div class="titlebackground"></div><span>Items</span>
                  </div></a></li>
              <li class="about-identity"><a routerLink="/products">
                            <div class="box-borders">
                              <div class="top"></div>
                              <div class="right"></div>
                              <div class="bottom"></div>
                              <div class="left"></div>
                            </div>
                  <div class="background"></div>
                  <div class="title">
                    <div class="titlebackground"></div><span>Packages</span>
                  </div></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>