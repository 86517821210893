
<div class="title-row">
  <h5>Identity's</h5>
  <h1>Discord</h1>
</div>
<div class="clear"></div>
<div id="Discord">
  <div class="Members">
    <ul>
      <li *ngFor="let member of users" [attr.class]="member.presence.status">
        <div class="background"></div>
        <div class="avatar"><img [attr.src]="getAvatar(member)"></div>
        <div class="author">{{ member.username }}</div>
      </li>
    </ul>
  </div>
  <div class="Messages">
    <ul>
      <li *ngFor="let message of messages">
        <div class="header">
          <div id="UserOverlay">
            <h1>{{ message.author }}</h1>
          </div>
          <div class="avatar"><img [attr.src]="getAvatar(message)"></div>
          <div class="author" (click)="showAuthor(message)">{{ message.author }}</div>
          <div class="date">{{ message.date }}</div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
        <div class="message">{{ message.content }}</div>
      </li>
    </ul>
    <div class="composer">
      <div class="emoticons"></div>
      <textarea class="compose" (keyup)="composeKey($event);" [(ngModel)]="compose.body"></textarea>
    </div>
  </div>
  <div class="clear"></div>
</div>