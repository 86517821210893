
<div class="title-row">
  <div class="left">
    <h1>People</h1>
  </div>
</div>
<div class="store-filter" id="StoreFilter">
  <form>
    <input class="people-finder" type="text" placeholder="Search" [(ngModel)]="query.filter" name="filter" (keyup)="queryPeople()">
  </form><i class="fa fa-search search-icon" (click)="queryPeople()"></i>
</div>
<ul id="PeopleList">
  <li *ngFor="let user of people"><img class="profile-pic" src="{{ user.image }}">
    <div class="add-friend" *ngIf="!user.friend.status" (click)="addFriend(user.accountid)"><i class="fa fa-user"></i><span>Add Friend</span></div>
    <div class="add-friend" *ngIf="user.friend.status == 'pending'" (click)="addFriend(user.accountid)"><i class="fa fa-user-plus"></i><span>Pending</span></div>
    <div class="nametag" [style.height]="user.tagline ? '57px' : '30px'"><a routerLink="/id/{{ user.username }}">
        <div class="username">{{ user.username }}</div></a>
      <div class="tagline">{{ user.tagline }}</div>
      <div class="active" *ngIf="user.active == 1">Active Now</div>
      <div class="since-active" *ngIf="!user.active">{{ user.active }}</div>
    </div>
  </li>
</ul>
<div id="controls">
  <div class="next" (click)="next()" *ngIf="offset &lt; 12 "><span>next</span><i class="fa fa-flex-right"></i></div>
  <div class="prev" (click)="prev()" *ngIf="offset &gt; 0"><span>prev</span><i class="fa fa-flex-left"></i></div>
  <div class="clear"></div>
</div>