
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section class="viewUserHeader right-align" id="mainhead" [style.background-image]="header" [style.height]="headerHeight ? headerHeight : false">
  <div id="BackgroundPreview"></div>
  <div class="container" *ngFor="let account of accounts">
    <div class="slogan">
      <h1>{{ title }}</h1>
    </div>
  </div>
</section>
<div class="viewUser" id="contentpage">
  <div class="container">
    <div id="Account" *ngFor="let account of accounts">
      <div id="AccountPicture"><img src="{{ account.image }}"></div>
      <ul id="AccountMenu">
        <li [attr.class]="accountPage == 'about' ? 'active' : 'inactive'" (click)="changePage('about')">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <div class="background"></div><span>About</span>
        </li>
        <li [attr.class]="accountPage == 'posts' ? 'active' : 'inactive'" (click)="changePage('posts')">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <div class="background"></div><span>Posts</span>
        </li>
        <li [attr.class]="accountPage == 'activity' ? 'active' : 'inactive'" (click)="changePage('activity')">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <div class="background"></div><span>Activity</span>
        </li>
        <li [attr.class]="accountPage == 'statistics' ? 'active' : 'inactive'" (click)="changePage('statistics')">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <div class="background"></div><span>Statistics</span>
        </li>
      </ul>
      <div id="AccountInfo" *ngIf="accountPage == 'about'">
        <!-- +mixname-->
        <!-- +imagebox('let listing of items')-->
        <div id="AboutUser">
          <div class="title-row">
            <h5>{{ title }}'s</h5>
            <h1>Identity</h1>
          </div>
          <div class="mid-sub" id="UserProfile">
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div>
            <p class="UserProfile no_content" *ngIf="writtenProfile == ''">No profile content</p>
            <p class="UserProfile" *ngIf="writtenProfile != ''" [innerHTML]="writtenProfile"></p>
          </div>
          <div class="mid-sub" id="UserItems">
            <div class="title-row"><i class="licon licon-chevron-right one"></i><i class="licon licon-chevron-right two"></i><i class="licon licon-chevron-right three"></i>
              <div class="background"></div>
              <div class="count" *ngIf="accountItemNumber">{{ showUserItem+1 }} / {{ accountItemNumber }}</div>
              <h5>Items</h5>
            </div>
            <ul class="items">
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div>
              <div class="item" *ngIf="!loadingItems">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <li class="no_content" *ngIf="!hasItems">No Items found in Account</li>
                <li *ngFor="let item of accountItems | slice:showUserItem:showUserItem+1" (swipeleft)="nextItem()" (swiperight)="prevItem()">
                  <div class="title"><span>{{ item.title }}</span>
                    <div class="left licon licon-rank" *ngIf="showUserClaims &gt; 0" (click)="prevClaim()"></div>
                    <div class="right licon licon-rank" *ngIf="showUserClaims+1 &lt; accountClaimsNumber" (click)="nextClaim()"></div>
                  </div>
                  <ul class="images">
                    <li *ngFor="let image of item.images | imageSize:'listing'"><img class="slide" src="{{ image.url }}"></li>
                    <li *ngIf="!item.images"><i class="licon licon-cross-square"></i></li>
                  </ul>
                </li>
              </div>
            </ul>
          </div>
          <div class="mid-sub" id="UserClaims"> 
            <div class="title-row"><i class="licon licon-chevron-right one"></i><i class="licon licon-chevron-right two"></i><i class="licon licon-chevron-right three"></i>
              <div class="background"></div>
              <div class="count" *ngIf="accountClaimsNumber">{{ showUserClaims+1 }} / {{ accountClaimsNumber }}</div>
              <h5>Claims </h5>
            </div>
            <ul class="items">
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div>
              <div class="item" *ngIf="!loadingClaims">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                <li class="no_content" *ngIf="!hasClaims">No Claims found in Account</li>
                <li *ngFor="let item of accountClaims | slice:showUserClaims:showUserClaims+1" (swipeleft)="nextClaim()" (swiperight)="prevClaim()">
                  <div class="title"><span>{{ item.title }}</span>
                    <div class="left licon licon-rank" *ngIf="showUserClaims &gt; 0" (click)="prevClaim()"></div>
                    <div class="right licon licon-rank" *ngIf="showUserClaims+1 &lt; accountClaimsNumber" (click)="nextClaim()"></div>
                  </div>
                  <ul class="images">
                    <li *ngFor="let image of item.images | imageSize:'listing'"><img class="slide" src="{{ image.url }}"></li>
                    <li *ngIf="!item.images"><i class="licon licon-cross-square"></i></li>
                  </ul>
                </li>
              </div>
            </ul>
          </div>
          <div class="clear"></div>
          <div class="sub" id="UserPackages"></div>
          <div class="sub" id="UserImages">
            <div class="images">
              <div class="carousel" *ngFor="let image of account.screenshots"><img src="{{ image.url }}"></div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div id="AccountPosts" *ngIf="accountPage == 'posts'">
        <div id="SocialCommunity">
          <div id="Articles">
            <h1>Hello</h1>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div id="AccountActivity" *ngIf="accountPage == 'view'">
        <div id="Articles">
          <article *ngFor="let post of feed">
            <div class="opening-post">
              <div class="top-right dimmable"><a (click)="removePost(post.postid)"><i class="licon licon-cross"></i></a></div>
              <div class="author"><img src="{{ post.author.image }}">
                <div class="user">{{ post.author.username }}</div>
                <div class="time">{{ post.time | amAdd : '1' : 'hours' | amTimeAgo }}</div>
              </div>
              <div class="post">
                <div class="content body" [innerHTML]="post.body"></div>
              </div>
              <ul class="reactions"></ul>
              <div class="comments">
                <form class="addComment"><img class="myPicture" src="{{ account.image }}">
                  <input type="text" name="body" value="Reply to..." (keyup)="commenting($event, post)" placeholder="Reply to..." [(ngModel)]="comment.body">
                </form>
              </div>
              <ul class="actions">
                <li class="reactions">
                  <div class="like">
                    <div class="likepad" (click)="like(post)"></div><i class="licon licon-thumbs-up"></i><span>Like</span>
                  </div>
                  <ul class="subreactions">
                    <li *ngFor="let act of reactions" (click)="like(post, act.kind)"><i [attr.class]="'licon ' + act.icon"> </i><span>{{ act.name }}</span></li>
                  </ul>
                </li>
                <li class="share" (click)="share(post)"><i class="licon licon-share2"></i><span>Share</span></li>
                <li class="clear"></li>
              </ul>
            </div>
            <div class="clear"></div>
            <div class="commentsList">
              <ul>
                <li class="comment" *ngFor="let comment of post.comments"> 
                  <div class="author"><img class="comment-profile" src="{{ comment.author.image }}"><span class="comment-author">{{ comment.author.username }}</span><i class="right licon licon-cross" (click)="removeComment(postid)"></i><span class="comment-time">{{ comment.created | amAdd : '1' : 'hours' | amTimeAgo  }}</span></div>
                  <div class="comment-body" [innerHTML]="comment.body"></div>
                  <ul class="actions">
                    <li class="reactions">
                      <div class="like">
                        <div class="likepad" (click)="likeComment(comment)"></div><i class="licon licon-thumbs-up"></i><span>Like</span>
                      </div>
                      <ul class="subreactions">
                        <li *ngFor="let act of reactions" (click)="like(post, act.kind)"><i [attr.class]="'licon ' + act.icon"> </i><span>{{ act.name }}</span></li>
                      </ul>
                    </li>
                    <li class="quote" (click)="quote(post)"><i class="licon licon-share2"></i><span>Quote</span></li>
                    <div class="comments"></div>
                  </ul>
                </li>
              </ul>
              <form class="addComment" *ngIf="post.comments_count &gt; 0"><img class="myPicture" src="{{ account.image }}">
                <input type="text" name="body" value="Reply to..." (keyup)="commenting($event, post)" placeholder="Reply to..." [(ngModel)]="comment.body">
              </form>
            </div>
          </article>
        </div>
        <div class="clear"></div>
      </div>
      <div id="AccountStatistics" *ngIf="accountPage == 'statistics'">
        <div id="Articles">
          <h2>Hello</h2>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</div>