
<div *ngIf="hideHeader == false">
  <!-- +mixname-->
  <!-- +imagebox('let listing of items')-->
  <!-- app.component contains all the header variables-->
  <section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
</div>
<div id="LoginPage">
  <div class="hashAncor" id="contentpage">
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <div class="backgroundtwo"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/login/">Login or Sign Up</a>
          </h5><a class="right shop-link" routerLink="/create">
            <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">Sign Up</span></a>
        </div>
      </div>
    <div class="container logincontainer">
      <div class="row" *ngIf="showCount">
        <div id="Teaser" *ngIf="hideExtras == false">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
          <div class="carbon_background"></div>
          <div class="stat" *ngFor="let statistic of statistics | slice:0:1">
            <h2> {{ statistic.statistic | number }} Members and Counting</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div id="NewIdentity" *ngIf="hideExtras == false">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
          <div class="carbon_background"></div>
          <div class="licon licon-diamond3 background-icon"></div>
          <div class="box-borders">
            <div class="topline"></div>
          </div>
          <div id="AddIdentity">
            <h2>New Identity</h2>
            <p>If you're new to Identity and haven't made an account yet, click the button below. Having an account is free with no obligations.</p><a class="button btn-primary" routerLink="/account/new/"> <span>Make a new account</span>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div></a>
          </div>
        </div>
        <div id="Login">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
          <div class="carbon_background"></div>
          <div class="licon licon-aim background-icon"></div>
          <div class="box-borders">
            <div class="topline"></div>
          </div>
          <div id="ExistingIdentity">
            <h2>Login</h2>
            <div id="error" *ngIf="error"><span id="error-message">{{error }}</span></div>
            <form id="LoginForm">
              <label for="username">{{ sft?'Email':'Username' }}</label>
              <input class="input-normal" type="text" name="username" [placeholder]="sft?'email':'username'" autocomplete="username" [(ngModel)]="model.username" (keydown)="loginKey($event)" (keyup)="sft=false" #UsernameField required>
              <label for="password">Password</label>
              <input class="input-normal" type="password" name="password" placeholder="password" autocomplete="password" (keydown)="loginKey($event)" (keyup)="sft=false" [(ngModel)]="model.password" #PasswordField required><a class="button btn-primary" (click)="logIn()"> 
                <div class="loggingIn" *ngIf="loggedIn">
                  <div class="ico-box">
                    <div class="icon-spin"><i class="icon icon-spinner2"></i></div>
                  </div>
                </div><span>{{ button }}</span>
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div></a>
            </form>
            <div id="OAuthLogins" *ngIf="hideOAuth == false">
              <h2>Account Login</h2>
              <div class="auth steam" (click)="oauthLogin('steam')"> 
                <div class="background"></div>
                <div class="light"></div>
                <div class="tryingOauth" *ngIf="tryingOAuth['steam']">
                  <div class="ico-box">
                    <div class="icon-spin"><i class="icon icon-spinner2"></i></div>
                  </div>
                </div><a [attr.href]="oauthSteam" target="_self">
                  <div class="ico-box"><i class="fa fa-steam-square"></i></div>
                  <div class="label">Steam</div></a>
              </div>
              <div class="auth facebook" (click)="oauthLogin('facebook')"> 
                <div class="background"></div>
                <div class="light"></div>
                <div class="tryingOauth" *ngIf="tryingOAuth['facebook']">
                  <div class="ico-box">
                    <div class="icon-spin"><i class="icon icon-spinner2"></i></div>
                  </div>
                </div><a [attr.href]="oauthFacebook" target="_blank">
                  <div class="ico-box"><i class="fa fa-facebook"></i></div>
                  <div class="label">Facebook</div></a>
              </div>
            </div>
          </div>
        </div>
        <div id="AdditionalItems">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
          <ul>
            <li class="community"><a href="http://www.identityrpg.com/community/">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div>
                <div class="background"></div>
                <div class="title"> 
                  <div class="titlebackground"></div><span>Community</span>
                </div></a></li>
            <li class="game-shop"><a routerLink="/shop">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div>
                <div class="background"></div>
                <div class="title">
                  <div class="titlebackground"></div><span>Game Shop</span>
                </div></a></li>
            <li class="about-identity"><a routerLink="/info">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div>
                <div class="background"></div>
                <div class="title">
                  <div class="titlebackground"></div><span>About Identity</span>
                </div></a></li>
          </ul>
        </div>
        <div id="AccountHelp" *ngIf="hideExtras == false">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
          <ul (click)="recoveryCode = 0">
            <li [@ForgottenUsernameTab]="recoveryState" (click)="recoveryState = 'forgottenUsername'; showRecovery = true;">
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div>
              <div class="background"></div><span>Forgot Username</span>
            </li>
            <li [@RecoverPasswordTab]="recoveryState" (click)="recoveryState = 'forgottenPassword'; showRecovery = true;">
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div>
              <div class="background"></div><span>Reset Password</span>
            </li>
            <li [@VerifyEmailTab]="recoveryState" (click)="recoveryState = 'verifyEmail'; showRecovery = true;">
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div>
              <div class="background"></div><span>Verification E-Mail</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Account Recovery Overlay Panel ==>-->
<div id="Overlay" [@overlay]="showRecovery">
  <div id="Darkness" (click)="showRecovery = false; recoveryState = 'in'" [@overlay]="showRecovery"></div>
  <div id="AccountHelpWindow" [@accountHelpWindow]="recoveryState">
    <div class="close" (click)="showRecovery = false; recoveryState = 'in'">x</div>
    <div id="error" *ngIf="recoveryError != ''" [@recoveryerror]="recoveryCode">{{ recoveryError }}</div>
    <form>
      <div id="ResetPassword" [@ResetPassword]="recoveryState">
        <h1>Forgotten your Password?</h1>
        <p>Have you forgotten your chosen account username? Enter the e-mail address it was created under and we'll send it over right away.</p>
        <div class="input-group">
          <label for="recovery-username">Username</label>
          <input type="text" autocomplete="username" name="recovery-username" [(ngModel)]="recovery.username" placeholder="username">
          <label for="recovery-email">Email</label>
          <input type="text" name="recovery-email" [(ngModel)]="recovery.email" placeholder="youremail@address.com"><a class="button btn-primary" (click)="recoverPassword()">Recover Password</a>
        </div>
      </div>
      <div id="ForgotUsername" [@ForgotUsername]="recoveryState">
        <h1>Forgot your Username?</h1>
        <p>Have you forgotten your chosen account username? Enter the e-mail address it was created under and we'll send it over right away.</p>
        <div class="input-group">
          <label for="recovery-username">Email</label>
          <input type="text" autocomplete="email" name="recovery-email" [(ngModel)]="recovery.email" placeholder="youremail@address.com"><a class="button btn-primary" (click)="recoverUsername()">Recover Username</a>
        </div>
      </div>
      <div id="VerificationEmail" [@VerificationEmail]="recoveryState">
        <h1>Verify your Email</h1>
        <p>Didn't receive the verification mail? Enter your e-mail address below and we will resend the mail.</p>
        <div class="input-group">
          <label for="recovery-username">Email</label>
          <input type="text" autocomplete="email" name="recovery-email" [(ngModel)]="recovery.email" placeholder="youremail@address.com"><a class="button btn-primary" (click)="verfiyEmail()">Verfiy Email</a>
        </div>
      </div>
      <div id="FacebookLogin" [@FacebookLogin]="recoveryState"></div>
    </form>
  </div>
</div>