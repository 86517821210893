
<!-- app.component contains all the header variables-->
<section class="right-align" id="mainhead" [style.background-image]="header" [style.height]="headerHeight ? headerHeight : false" (mousedrag)="alignBackground($event);">
  <div id="BackgroundPreview" [style.background-image]="bgpreview"></div>
  <div class="container" *ngFor="let account of accounts">
    <div class="edit-background" (click)="editingBackground = (editingBackground==true) ? false : true"><i class="fa fa-camera-retro"></i></div>
    <div id="Backgrounds" *ngIf="editingBackground == true">
      <ul>
        <li *ngFor="let item of backgrounds">
          <div class="background" (mouseover)="previewBackground(item)" (click)="saveBackground(item)"><img class="images" [src]="item.url"></div>
          <div class="name">{{ item.name }}</div>
        </li>
      </ul>
    </div>
    <div class="slogan">
      <h1>{{ title }}</h1>
      <div class="placeholder">
        <div [@taglineSpan]="updatingTagline" (click)="editTagline()">{{ ( subtitle ) ? subtitle : '...' }}</div>
        <input type="text" [(ngModel)]="profile.tagline" [@taglineField]="updatingTagline" (keyup.enter)="updateTagline()" name="tagline" maxlength="35" autosize #tagline>
      </div>
    </div>
  </div>
  <div class="select-bar" *ngIf="editingBackground == true">
    <div class="handle"></div>
  </div>
</section>
<!-- This page gets rendered into user.component.html with all inclusions remaining intact-->
<div id="contentpage">
  <div class="container" id="Account" *ngFor="let account of accounts">
    <div id="AccountPicture"><img src="{{ account.image }}" (click)="uplodProfileImage()"></div>
    <div id="CommunityBar">
      <div class="border-bottom"></div>
      <div id="Bar">
        <div class="search-icon"><i class="licon licon-magnifier"></i></div>
        <div class="search">
          <form>
            <input class="search" [(ngModel)]="search.term" autocomplete="off" name="search" placeholder="search users, locations, names" (keyup)="dosearch()">
            <ul class="box searchResults"></ul>
          </form>
          <ul id="SearchResults" [@profileSearch]="searching" (click)="searching = 'false';">
            <li *ngFor="let account of searchResultsAccount">
              <div class="marker"></div>
              <div class="underglow"></div>
              <div class="image-container"><span class="image"><img src="{{ account.image }}"></span></div><a routerLink="/id/{{ account.username }}"><span class="username">{{ account.username }}</span></a>
            </li>
            <li *ngFor="let item of searchResultsItem">
              <div class="marker"></div>
              <div class="underglow"></div>
              <div class="image-container"><span class="image" *ngFor="let image of item.images | imageSize:'thumbnail' | slice:0:1"><img src="{{ image.url }}"></span></div><a routerLink="/item/{{ item.id }}"><span>{{ item.title }}</span></a>
            </li>
            <li *ngFor="let product of searchResultsProduct">
              <div class="marker"></div>
              <div class="underglow"></div>
              <div class="image-container"><span class="image" *ngFor="let image of product.images | imageSize:'thumbnail' | slice:0:1"><img src="{{ image.url }}"></span></div><a routerLink="/product/{{ product.id }}"><span>{{ product.title }}</span></a>
            </li>
          </ul>
        </div>
        <ul class="icons" *ngIf="this.account.social == true">
          <li class="licon licon-bubble chat" (click)="popopen = (popopen != 'chat') ? 'chat' : false">
            <ul [@popover]="popopen == 'chat'">
              <li *ngFor="let message of messages"><img src="#"><span>{{ message }}</span></li>
            </ul>
          </li>
          <li class="licon licon-users2 friends" (click)="popopen = (popopen != 'friends') ? 'friends' : false">
            <ul [@popover]="popopen == 'friends'">
              <li *ngFor="let message of messages"><img src="#"><span>{{ message }}</span></li>
            </ul>
          </li>
          <li class="licon licon-earth globe" (click)="popopen = (popopen != 'globe') ? 'globe' : false">
            <ul [@popover]="popopen == 'globe'">
              <li *ngFor="let message of messages"><img src="#"><span>{{ message }}</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div id="AccountDetails">
      <div class="lines" id="ProfileMenu" [@accountContainer]="this.view">
        <ul>
          <li class="community" *ngIf="this.account.social == true" [@menuitem]="this.view == 'community'" (click)="this.view = 'community'" tabindex="7"><span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-book"></i><span class="label">Social</span>
          </li>
          <li class="items" *ngIf="itemscount &gt; 0" [@menuitem]="this.view == 'items'" (click)="this.view = 'items'" tabindex="13"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-diamond2"></i><span class="label">Items</span>
          </li>
          <li class="claims" *ngIf="claimscount &gt; 0" [@menuitem]="this.view == 'claims'" (click)="this.view = 'claims'" tabindex="12"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-joystick"></i><span class="label">Claims</span>
          </li>
          <li class="chat" *ngIf="this.account.chat == true" [@menuitem]="this.view == 'chat'" (click)="this.view = 'chat'" tabindex="12"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-bubbles"></i><span class="label">Chat</span>
          </li>
          <li class="account" [@menuitem]="this.view == 'edit'" (click)="this.view = 'edit'" tabindex="10"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-clipboard-user"></i><span class="label">Account</span>
          </li>
          <li class="profile" [@menuitem]="this.view == 'profile'" (click)="this.view = 'profile'" tabindex="11"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-user"></i><span class="label">Profile</span>
          </li>
          <li class="characters" *ngIf="character_ready" [@menuitem]="this.view == 'character'" (click)="this.view = 'character'" tabindex="8"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-users"></i><span class="label">Characters</span>
          </li>
          <li class="features" [@menuitem]="this.view == 'features'" (click)="this.view = 'features'" tabindex="9"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-star"></i><span class="label">News</span>
          </li>
          <li class="people" *ngIf="isAdmin" [@menuitem]="this.view == 'people'" (click)="this.view = 'people'" tabindex="14"> <span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-users2"></i><span class="label">People</span>
          </li>
          <li class="people" tabindex="14"><span class="bar"></span>
            <div class="indicator"></div><i class="licon licon-exit"></i><a class="label" routerLink="/logout">Logout</a>
          </li>
        </ul>
      </div>
      <div id="ProfileItems" [@accountContainer]="view">
        <div id="Community" [@community]="view">
          <div class="socialNetwork" *ngIf="this.account.social == true">
            <social></social>
          </div>
          <div class="socialDisabled" *ngIf="this.account.social == false">
            <div class="title-row">
              <div class="Left">
                <h5>Social</h5>
                <h1>Network</h1>
              </div>
            </div>
            <form class="socialInvite">
              <h1>Invitation</h1>
              <h5>Only</h5>
              <label for="email">Email Address</label>
              <input [(ngModel)]="social.email" name="email">
              <button type="submit" (click)="invite()">Submit</button>
            </form>
          </div>
        </div>
        <div id="Character" [@character]="view">
          <user-character></user-character>
        </div>
        <div id="Chat" [@chat]="view" *ngIf="this.account.chat == true">
          <user-discord></user-discord>
        </div>
        <div id="FeaturesPage" [@features]="view">
          <user-features></user-features>
        </div>
        <!--include ./user-features/user.features.pug-->
        <div id="Edit" [@edit]="view">
          <div class="title-row">
            <h5>Welcome to</h5>
            <h1>Identity</h1>
          </div>
          <form>
            <div class="row" id="AccountBalance">
              <div class="inside">
                <div class="top-row">
                  <div class="icon icon-coin-dollar header-icon"></div>
                  <h3>Account Balance</h3>
                </div>
                <div class="account-balance">
                  <div class="left"><span class="blue">USD</span><span class="green">{{ account.balance | number:'1.2-2' }} </span></div>
                  <div class="right"> 
                    <div class="buttons" [@fundButtons]="fundButtons">
                      <ul>
                        <li *ngFor="let method of methods">
                          <button class="button" *ngIf="method.service != 'account'" (click)="overlay(method.service)">{{ method.name }}</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
            </div>
            <div class="row" id="ForumAccount">
              <div class="inside">
                <div class="forumlink-success" *ngIf="forumsuccess">{{ forumsuccess }}</div>
                <div class="forumlink-error" *ngIf="forumerr">{{ forumerr }}</div>
                <div class="top-row">
                  <div class="fa fa-id-card-o header-icon"></div>
                  <h3>Identity Forum</h3>
                </div>
                <div class="editable forum"><a class="itemName" href="http://identityrpg.com/community/{{ account.forum_id }}/{{ account.forum_username }}" *ngIf="account.forum_username"> <i class="licon licon-users2"></i><span>Forum Account Linked: </span><span class="color">{{ account.forum_username }}</span></a>
                  <div class="itemName" *ngIf="!account.forum_username"><a href="http://identityrpg.com/community/" target="_blank"> <i class="licon licon-users2"></i><span>Identity Community</span></a></div><span class="hoverunderline blue right" (click)="overlay('forum')" *ngIf="!account.forum_username"><i class="fa fa-info left"></i><span>Link Forum Account</span></span><span class="hoverunderline blue right" (click)="unlinkForum()" *ngIf="account.forum_username"><i class="fa fa-info left"></i><span>Unlink Forum Account</span></span>
                </div>
              </div>
            </div>
            <div class="row" id="gameAccess">
              <div class="inside">
                <div class="top-row">
                  <div class="fa fa-gamepad header-icon"></div>
                  <h3>Game Access</h3>
                </div>
              </div>
              <div class="line"> 
                <div class="line-title"> 
                  <div class="line-title-icon fa fa-steam-square"></div>Steam Product Key
                </div>
                <div class="line-value"> <span *ngIf="account.gameKey">{{ account.gameKey }}</span><span *ngIf="account.has_steam &amp;&amp; !account.has_beta_access">Beta item required</span><span *ngIf="!account['has_steam']">Steam account needs to be linked</span><span class="claim-game-key-btn" *ngIf="account['has_steam'] &amp;&amp; account.has_beta_access &amp;&amp; !account.gameKey"> <a (click)="claimGameKey()">Claim the key			</a></span></div>
              </div>
            </div>
            <div class="row" id="OAuthAccounts">
              <div class="inside">
                <div class="top-row">
                  <div class="icon icon-lock header-icon"></div>
                  <h3>Account Security</h3>
                </div>
                <div class="accounts">
                  <form>
                    <h4 class="title">Accounts Linked To This Identity:</h4>
                    <ul class="providers">
                      <li class="provider" *ngFor="let provider of authServices">
                        <div class="title"> <i [attr.class]="provider.icon"></i><span>{{ provider.name }}</span></div>
                        <!-- IF Provider is NOT linked-->
                        <ng-container *ngIf="!account[provider.id]"><a class="status" [attr.href]="provider.link" target="_blank">
                            <!-- Steam warning tooltip-->
                            <div class="steam-warning-tooltip" *ngIf="provider.name === 'steam'">
                              <div class="steam-warning-tooltip-title"> <i class="steam-warning-tooltip-title-icon fa fa-exclamation-circle"></i>Important Message</div>
                              <div class="steam-warning-tooltip-msg">Please remember, linking your Steam profile is a <u>one-time</u> procedure. You can not undo this in the future. </div>
                            </div><span class="label" (click)="toggleOauth(provider)">Click To Link</span></a></ng-container>
                        <!-- ELSE provider is linked-->
                        <ng-container *ngIf="account[provider.id]">
                          <div class="status" [ngSwitch]="provider.id">
                            <!-- Is STEAM--><a *ngSwitchCase="'steam_id'" href="http://steamcommunity.com/profiles/{{ account['steam_id'] }}" target="_blank"><span class="label">Linked </span></a>
                            <!-- Is SOMETHING ELSE--><span *ngSwitchDefault><span class="label">Linked </span></span>
                          </div>
                        </ng-container>
                      </li>
                    </ul>
                  </form>
                </div>
                <div class="passwordWarning" *ngIf="account.lastpassword == 'Never' &amp;&amp; !usingOAuth">
                  <div class="editable password">
                    <div class="itemName">Password:</div><span *ngIf="account.lastpassword != 'Never'">Last Changed: {{ account.lastpassword | amTimeAgo }}</span><span class="hoverunderline yellow" *ngIf="account.lastpassword == 'Never'" (click)="edit('password')"><i class="fa fa-warning left"></i><span>Add a Password</span></span>
                    <input class="input-normal" [@editPassword]="editing.password" type="password" placeholder="new password" name="password" [(ngModel)]="profile.password" style="position: relative;right: 75px;" #password>
                    <div class="edit">
                      <div class="edit-link" *ngIf="!editing.password" (click)="edit('password')">Edit Password</div>
                      <div class="icon icon-cross" *ngIf="editing.password" (click)="clearEdits()"></div>
                      <div class="icon icon-checkmark saveicon" *ngIf="editing.password" (click)="updateAccount()"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="Machines">
              <div class="inside">
                <div class="top-row">
                  <div class="icon icon-lock header-icon"></div>
                  <h3>Logged In</h3>
                </div>
                <ul class="machines">
                  <li *ngFor="let anvil of machines"><i [attr.class]="anvil.icon"></i>
                    <div class="system">{{ anvil.system }}</div><span class="time">{{ anvil.time | amAdd:'1':'hours' | amTimeAgo }}</span><span class="ip_address">{{ anvil.ip_address }}</span>
                    <div class="remove" (click)="removeMachine( anvil.id );"><span>Log out</span></div>
                  </li>
                  <li *ngIf="numberOfMachines == 0"><i class="fa fa-info left"></i>
                    <div class="system">Logged Out</div>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
        <div id="Profile" [@profile]="view">
          <div class="title-row">
            <h5>Change Your</h5>
            <h1>Identity</h1>
            <label for="hidden-upload-box"><a class="update-profile-pic" (click)="uplodProfileImage()">Update profile picture</a></label>
          </div>
          <div class="error" *ngIf="usererr">{{ usererr }}</div>
          <form>
            <div class="row" id="EditProfile">
              <div class="inside">
                <div class="top-row">
                  <div class="licon licon-pencil-ruler2 header-icon"></div>
                  <h3>Your Profile</h3>
                </div>
                <div class="editable profile">
                  <div class="itemName">Profile:</div>
                  <div class="edit">
                    <div class="edit-link" *ngIf="!editing.profile" (click)="edit('profile')"> 
                      <div class="underline"></div>
                      <div class="overline"></div><span>Edit Profile</span>
                    </div>
                    <div class="fa fa-spinner fa-spin" *ngIf="savingAccount"></div>
                    <div class="licon licon-cross2" *ngIf="editing.profile &amp;&amp; !savingAccount" (click)="clearEdits()"></div>
                    <div class="licon licon-check saveicon" *ngIf="editing.profile &amp;&amp; !savingAccount" (click)="updateAccount()"></div>
                  </div>
                  <div class="editor">
                    <div *ngIf="editing.profile" [froalaEditor] [(froalaModel)]="profile.profile"></div>
                  </div><span class="hoverunderline" *ngIf="!editing.profile &amp;&amp; !profile.profile" (click)="edit('profile')">
                    Describe yourself...
                    </span>
                  <div *ngIf="profile.profile">
                    <p class="UserProfile" *ngIf="!editing.profile" [innerHTML]="profile.profile" #profileinfo></p>
                  </div>
                </div>
                <div class="editable username">
                  <div class="itemName">Username:</div><span>{{ account.username || 'Add or change your username' }}</span>
                  <input class="input-normal" [@editUsername]="editing.username" type="text" placeholder="username" name="username" [(ngModel)]="profile.username" style="position: relative;right: 75px;" #username>
                  <div class="edit">
                    <div class="edit-link" *ngIf="!editing.username" (click)="edit('username')"> 
                      <div class="underline"></div>
                      <div class="overline"></div><span>Edit Username</span>
                    </div>
                    <div class="licon licon-cross2" *ngIf="editing.username" (click)="clearEdits()"></div>
                    <div class="licon licon-check saveicon" *ngIf="editing.username" (click)="updateAccount()"></div>
                    <input class="hidden-upload-box" (change)="updateProfile($event)" type="file" name="hidden-upload-box" #fileInput>
                  </div>
                </div>
                <div class="editable email">
                  <div class="itemName">Email:</div><span *ngIf="account.email">{{ account.email }}</span><span class="hoverunderline" *ngIf="!account.email" (click)="edit('email')">Add your Email Address</span>
                  <input class="input-normal" [@editEmail]="editing.email" type="text" placeholder="email" name="email" [(ngModel)]="profile.email" style="position: relative;right: 75px;" #email>
                  <div class="edit">
                    <div class="edit-link" *ngIf="!editing.email" (click)="edit('email')"> 
                      <div class="underline"></div>
                      <div class="overline"></div><span>Edit Email </span>
                    </div>
                    <div class="licon licon-cross2" *ngIf="editing.email" (click)="clearEdits()"></div>
                    <div class="licon licon-check saveicon" *ngIf="editing.email" (click)="updateAccount()"></div>
                  </div>
                </div>
                <div class="editable password">
                  <div class="itemName">Password:</div><span *ngIf="account.lastpassword != 'Never'">Last Changed: {{ account.lastpassword | amAdd : '1' : 'hours' | amTimeAgo }}	</span><span class="hoverunderline yellow" *ngIf="account.lastpassword == 'Never'" (click)="edit('password')"><i class="fa fa-warning left"></i><span>Add a Password</span></span>
                  <input class="input-normal" [@editPassword]="editing.password" type="password" placeholder="new password" name="password" [(ngModel)]="profile.password" style="position: relative;right: 75px;" #password>
                  <div class="edit">
                    <div class="edit-link" *ngIf="!editing.password" (click)="edit('password')"> 
                      <div class="underline"></div>
                      <div class="overline"></div><span>Edit Password</span>
                    </div>
                    <div class="licon licon-cross2" *ngIf="editing.password" (click)="clearEdits()"></div>
                    <div class="licon licon-check saveicon" *ngIf="editing.password" (click)="updateAccount()"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row editable-row" id="EditIdentity">
              <div class="inside">
                <div class="top-row">
                  <div class="icon icon-user header-icon"></div>
                  <h3>Your Identity</h3>
                  <div class="edit">
                    <div class="editlink" *ngIf="!editing.identity" (click)="edit('identity')"> 
                      <div class="underline"></div>
                      <div class="overline"></div><span>Edit Profile</span>
                    </div>
                    <div class="licon licon-cross2" *ngIf="editing.identity" (click)="clearEdits()"></div>
                    <div class="licon licon-check saveicon" *ngIf="editing.identity" (click)="updateAccount()"></div>
                  </div>
                </div>
                <div class="editable firstname">
                  <div class="itemName">First Name:</div><span>{{ account.firstname || 'None' }}</span>
                  <input class="input-normal" [@editProfile]="editing.identity" type="text" placeholder="firstname" name="firstname" [(ngModel)]="profile.firstname">
                </div>
                <div class="editable surname">
                  <div class="itemName">Surname:</div><span>{{ account.surname || 'None' }}</span>
                  <input class="input-normal" [@editProfile]="editing.identity" type="text" placeholder="surname" name="surname" [(ngModel)]="profile.surname">
                </div>
                <div class="editable birthdate">
                  <div class="itemName">Birthdate:</div><span>{{ birthdate || 'None' }}</span>
                  <my-date-picker class="birthday" [@editProfile]="editing.identity" name="birthdate" placeholder="yyyy-mm-dd" required [(ngModel)]="profile.birthdate"></my-date-picker>
                </div>
                <div class="editable sex">
                  <div class="itemName">Sex:</div><span>{{ account.sex || 'None' }}</span>
                  <div class="editable genders" [@editProfile]="editing.identity">
                    <input [checked]="profile.sex == c.value" #c (click)="profile.sex = c.value" name="sex" value="Custom" id="Custom" type="radio">
                    <label for="Custom">Other</label>
                    <input [checked]="profile.sex == f.value" #f (click)="profile.sex = f.value" name="sex" value="F" id="F" type="radio">
                    <label for="F">Female</label>
                    <input [checked]="profile.sex == m.value" #m (click)="profile.sex = m.value" name="sex" value="M" id="M" type="radio">
                    <label for="M">Male</label>
                    <div class="custom-gender" *ngIf="profile.sex != 'M' &amp;&amp; profile.sex != 'F' &amp;&amp; editing.identity">
                      <input class="input-normal custom" type="text" placeholder="custom sex" name="gender" [(ngModel)]="profile.sex" focus="profile.sex != 'M' &amp;&amp; profile.sex != 'F'">
                    </div>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
            </div>
            <div class="row editable-row" id="EditAddress">
              <div class="inside">
                <div class="top-row">
                  <div class="icon icon-office header-icon"></div>
                  <h3>Shipping Address</h3>
                  <div class="edit">
                    <div class="editlink" *ngIf="!editing.address" (click)="edit('address')"> 
                      <div class="underline"></div>
                      <div class="overline"></div><span>Edit Address</span>
                    </div>
                    <div class="licon licon-cross2" *ngIf="editing.address" (click)="clearEdits()"></div>
                    <div class="licon licon-check saveicon" *ngIf="editing.address" (click)="updateAccount()"></div>
                  </div>
                </div>
                <div class="editable housenumber">
                  <div class="itemName">House Number:</div><span>{{ account.housenumber || 'None' }}</span>
                  <input class="input-normal" [@editAddress]="editing.address" type="text" placeholder="housenumber" name="housenumber" [(ngModel)]="profile.housenumber">
                </div>
                <div class="editable Street">
                  <div class="itemName">Street:</div><span>{{ account.street || 'None' }}</span>
                  <input class="input-normal" [@editAddress]="editing.address" type="text" placeholder="street" name="street" [(ngModel)]="profile.street">
                </div>
                <div class="editable zip">
                  <div class="itemName">ZIP / Postal Code:</div><span>{{ account.zip || 'None' }}</span>
                  <input class="input-normal" [@editAddress]="editing.address" type="text" placeholder="zip" name="zip" [(ngModel)]="profile.zip">
                </div>
                <div class="editable city">
                  <div class="itemName">City:</div><span>{{ account.city || 'None' }}</span>
                  <input class="input-normal" [@editAddress]="editing.address" type="text" placeholder="city" name="city" [(ngModel)]="profile.city">
                </div>
                <div class="editable state">
                  <div class="itemName">State / Provence:</div><span>{{ account.state || 'None' }}</span>
                  <input class="input-normal" [@editAddress]="editing.address" type="text" placeholder="state" name="state" [(ngModel)]="profile.state">
                </div>
                <div class="editable countrycode">
                  <div class="itemName">Country:</div><span>{{ account.countrycode || 'None' }}</span>
                  <select class="selectable" [@editAddress]="editing.address" name="countrycode" [(ngModel)]="profile.countrycode">
                    <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
                  </select>
                </div>
                <div class="clear"></div>
              </div>
            </div>
          </form>
        </div>
        <div id="People" [@people]="view">
          <user-people></user-people>
        </div>
        <div id="Products" [@claims]="view">
          <user-claims (giftingproduct)="giftProduct($event)" [reloadClaims]="claimReset" (sharelisting)="shareItem($event)" (reloadClaims)="reload();"></user-claims>
        </div>
        <div id="Items" [@items]="view">
          <user-items (numberOfItems)="account.items" [reloadClaims]="claimReset" (giftingitem)="giftItem($event)" (sharelisting)="shareItem($event)"></user-items>
        </div>
      </div>
    </div>
    <friends></friends>
    <div id="Overlay" [@darkness]="showFixture">
      <div id="Darkness" (click)="showFixture = 'hidden'"></div>
      <div id="AccountPayments" [@overlay]="showFixture">
        <div id="PaymentWindow" [@giftpayment]="fixture">
          <div id="Details" [@details]="fixture">
            <div class="background"></div>
            <div class="close" *ngIf="fixture != 4" (click)="showFixture = 'hidden'">x</div>
            <div class="close active" *ngIf="fixture == '4'" (click)="backToOrder()"><i class="fa fa-angle-left"></i></div><span *ngFor="let user of accounts">
              <div class="picture" (click)="fixture = (fixture == '1') ? 4 : 1"><img src="{{ user.image }}"></div>
              <div class="username">{{ user.username }}</div>
              <div class="order"> <strong>Balance: </strong><span>{{ user.balance | number:'1.2-2' }}</span></div></span>
            <div id="Errors" [@paymenterror]="errorCode">{{ errorMsg }}</div>
          </div>
          <div class="container" id="EntryWindow" [@giftcontainer]="fixture">
            <div id="PayPalPayment" [@paypalpayment]="fixture">
              <h2>Add funds</h2>
              <div class="account-credits">
                <div>
                  <div class="cart-total">Add USD Funds:</div>
                  <form class="cart-amount"> <span class="currency">$</span>
                    <input class="addAmount" type="number" name="amount" required [(ngModel)]="addAmount.amount" #name="ngModel" (change)="paypalHostedCheckout()" (keyup)="paypalCheckout = false">
                  </form>
                </div>
              </div>
              <div id="PayPalLink" *ngIf="hosted" (click)="checkHostedPayPal()">
                <div class="paypal-loading" *ngIf="!paypalCheckout"><i class="fa fa-spin fa-spinner fa-2x" *ngIf="credit.terms"></i></div><a [attr.href]="paypalCheckout" target="_blank"></a>
              </div>
              <div id="paypal-button-container" *ngIf="!hosted"></div>
              <p class="terms">
                <input [checked]="credit.terms" (change)="credit.terms = !credit.terms;paypalHostedCheckout();" type="checkbox" name="cc-terms" id="cc-terms" required>
                <label for="cc-terms">I agree with the </label><a (click)="showTerms()">Terms and Conditions</a>
              </p>
            </div>
            <div id="AccountPayment" [@payment]="fixture">
              <div class="account-credits"> 
                <div class="cart-total">Add USD Funds:</div>
                <form class="cart-amount"> <span class="currency">$</span>
                  <input class="addAmount" type="number" name="amount" required [(ngModel)]="addAmount.amount" #name="ngModel">
                </form>
              </div>
              <form id="OrderForm">
                <input class="input-normal cc-input" type="text" name="cardholderName" placeholder="Name" required [(ngModel)]="credit.cardholderName">
                <input class="input-normal cc-input" type="text" name="cardNumber" placeholder="Card Number" required [(ngModel)]="credit.cardNumber">
                <select class="order-selector cc-input" name="credit.paymentProductId">
                  <option option value="1" ng-selected>Visa</option>
                  <option value="3">MasterCard</option>
                  <option value="2">American Express</option>
                  <option value="128">Discover</option>
                  <option value="132">Diners Club</option>
                  <option value="114">Visa Debit</option>
                  <option value="119">MasterCard Debit</option>
                  <option value="122">Visa Electron</option>
                  <option value="125">JCB</option>
                  <option value="117">Maestro</option>
                  <option value="130">Carte Bancaire</option>
                  <option value="135">Cabal</option>
                  <option value="136">Naranja</option>
                  <option value="137">Nevada</option>
                  <option value="139">Italcred</option>
                  <option value="140">Argencard</option>
                  <option value="141">Consumax</option>
                  <option value="142">Mas</option>
                  <option value="144">Pyme Nacion</option>
                  <option value="145">Nativa</option>
                  <option value="146">Aura</option>
                  <option value="147">ELO</option>
                  <option value="148">Hipercard</option>
                  <option value="149">Tarjeta Shopping</option>
                </select>
                <input class="input-normal cc-input half-width" type="text" placeholder="CCV" name="cvv" required [(ngModel)]="credit.cvv" #name="ngModel">
                <input class="input-normal cc-input half-width" type="text" placeholder="MM/YY" name="expiryDate" required [(ngModel)]="credit.expiryDate" #name="ngModel">
                <div class="purchase-button-container">
                  <button class="buy button purchase-cart" (click)="purchase('gc')">Pay {{ total | currency:'USD':true:'1.2-2' }}</button>
                </div>
                <p class="terms">
                  <input [checked]="credit.terms" (change)="credit.terms = !credit.terms" type="checkbox" name="cc-terms" id="cc-terms" required>
                  <label for="cc-terms">I agree with the </label><a (click)="showTerms()">Terms and Conditions</a>
                </p>
              </form>
            </div>
            <div id="PostPayment" [@paymentresult]="fixture">
              <div class="paid"><i class="order-complete icon icon-checkmark" [@paymenticon]="fixture"></i>
                <h2>Order Complete</h2>
                <p>You have added {{ addAmount.amount | currency:'USD':true:'1.2-2' }} to your account.</p>
              </div>
            </div>
            <div id="Agreement" [@agreement]="fixture">
              <iframe src="/assets/terms.html"></iframe>
            </div>
            <div id="LinkForum" [@forum]="fixture">
              <h2>Link forum account</h2>
              <p>Enter your forum username and password.</p>
              <form id="LinkForum">
                <input class="input-normal cc-input input-normal" [@editAddress]="editing.forum" type="text" placeholder="forum username" name="username" [(ngModel)]="profile.forum_account" #formusername>
                <input class="input-normal cc-input input-normal" [@editAddress]="editing.forum" type="password" placeholder="forum password" name="password" [(ngModel)]="profile.forum_password" #formpassword>
                <div class="purchase-button-container">
                  <button class="buy button purchase-cart" (click)="linkForumAccount()">Link Accounts</button>
                </div>
              </form>
            </div>
            <!-- +mixname-->
            <!-- +imagebox('let listing of items')-->
            <div id="GiftPayment" [@giftwindow]="fixture">
              <div class="account-credits" *ngIf="giftingitem">
                <div class="credit-icon">Item</div>
                <div class="credit-title">{{ giftingitem.title }}</div>
              </div>
              <div class="account-credits" *ngIf="giftingproduct">
                <div class="credit-icon">Gift</div>
                <div class="credit-title">{{ giftingproduct.title }}</div>
              </div>
              <form id="Gift" *ngIf="!giftConfirm">
                <label for="username">Send items to...</label>
                <input class="input-normal username-input" type="text" name="username" placeholder="Email or Username" required [(ngModel)]="gift.username" #name="ngModel">
                <p class="terms">
                  <input [checked]="gift.release" (change)="gift.release = !gift.release" required type="checkbox" name="releaseItems" id="releaseItems">
                  <label for="releaseItems"><span>Release these items from my account</span></label>
                </p>
                <div class="purchase-button-container">
                  <button class="buy button purchase-cart" (click)="sendGift()">Send Gift</button>
                </div>
              </form>
              <div class="confirmation" *ngIf="giftConfirm">
                <label>You are sending an item that is part of a package. The {{ confirmpackage.title }} package will be removed from your account.</label>
                <label>Are you sure you want to send this item?</label>
                <div class="purchase-button-container">
                  <button class="buy button purchase-cart" (click)="sendGift()">Yes</button>
                  <button class="buy button purchase-cart" (click)="sendGift()">Cancel</button>
                </div>
              </div>
            </div>
            <div id="PostGift" [@giftresult]="fixture">
              <div class="paid">
                <div class="order-complete icon icon-gift" [@gifticon]="fixture"></div>
                <h2>Thank you</h2>
                <p>You have sent a gift to</p>
                <h4>{{ gift.username }}</h4>
                <div class="center"><a (click)="showFixture = 'false'">Close</a></div>
              </div>
            </div>
            <div id="Copies" [@gamecopies]="fixture">
              <div class="paid">
                <div class="order-complete icon icon-gift" [@gifticon]="fixture"></div>
                <h2>Thank you</h2>
                <p>You have sent a gift to</p>
                <h4>{{ gift.username }}</h4>
                <div class="center"><a (click)="showFixture = 'false'">Close </a></div>
              </div>
            </div>
            <div id="Crypto" [@cryptocurrency]="fixture">
              <div class="crypto" *ngIf="cryptoAddress">
                <div class="coin_header"> 
                              <div class="box-borders">
                                <div class="top"></div>
                                <div class="right"></div>
                                <div class="bottom"></div>
                                <div class="left"></div>
                              </div>
                  <ul>
                    <li (click)="addWhatCrypto = addWhatCrypto-1"><i class="fa fa-chevron-left"></i></li>
                    <li *ngFor="let currency of currencies | slice:addWhatCrypto:addWhatCrypto+1">1 iD = {{ 1 / currency.value | number:'1.2-4' }} {{ currency.symbol }}</li>
                    <li (click)="addWhatCrypto = addWhatCrypto+1"><i class="fa fa-chevron-right"></i></li>
                  </ul>
                </div>
                <div class="qr" [@qrcode]="fixture">
                  <qr-code [level]="L" [size]="200" [backgroundAlpha]="0" [data]="cryptoAddress" [foregroundAlpha]="0.8"></qr-code>
                </div>
                <div class="address"><span>{{ cryptoAddress }}</span></div>
              </div>
            </div>
            <!-- Fixtures for Sharing Content //-->
            <div id="Share" [@share]="fixture">
              <div class="product" *ngIf="sharelisting">
                <div class="close" *ngIf="fixture != 4" (click)="showFixture = 'false'">x</div>
                <div class="image" *ngIf="sharelisting.imagecount &gt; 0"><img src="{{ sharelisting.images[0].url }}"></div>
                <div class="title">
                  <div class="small">Identity RPG</div><span>{{ sharelisting.title }}</span>
                </div>
                <div class="description">{{ sharelisting.description }}</div>
              </div>
              <ul class="social-buttons">
                <li class="share twitter" (click)="doShare('twitter')">
                  <div class="share-icon"><i class="fa fa-twitter"></i></div>
                  <div class="share-title">Twitter</div>
                </li>
                <li class="share facebook" (click)="doShare('facebook')">
                  <div class="share-icon"><i class="fa fa-facebook"></i></div>
                  <div class="share-title">Facebook</div>
                </li>
                <li class="share email" (click)="doShare('email')">
                  <div class="share-icon"><i class="fa fa-envelope"></i></div>
                  <div class="share-title">Email</div>
                </li>
              </ul>
            </div>
            <div id="DualFactorQR" [@dualfactorsection]="fixture">
              <div class="qr">
                <p>Google Authenticator</p>
                <qr-code [level]="L" [size]="150" [backgroundAlpha]="0" [data]="dualFactorQR" [foregroundAlpha]="0.8"></qr-code>
                <div class="key">{{ dualFactorKey }}</div>
              </div>
              <div class="setup">
                <h2>Download Google Authenticator</h2>
                <ul class="download">
                  <li><a href="https://itunes.apple.com/ca/app/google-authenticator/id388497605?mt=8" target="_blank"><i class="fa fa-apple"></i><span>App Store</span></a></li>
                  <li><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank"><i class="fa fa-google"></i><span>Google Play</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>