
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="LoginPanel">
  <div class="login-options" *ngIf="!loggedIn" [@fadeIn]>
    <h2 class="purchase-title">Claim your Identity</h2>
    <div id="error" *ngIf="signupError"><span id="error-message"> {{signupError }} </span></div>
    <div id="error" *ngIf="error"> {{ error }} </div>
    <div id="Login">
      <h2>Login</h2>
      <form>
        <label for="username">{{ sft?'Email':'Username' }}</label>
        <input class="input-normal" type="text" [placeholder]="sft?'email':'username'" name="username" required="" [(ngModel)]="model.username" #name="ngModel" (keydown)="loginKey($event)" (keyup)="sft=false" #UsernameField>
        <label for="password"> Password </label>
        <input class="input-normal" type="password" placeholder="password" name="password" required="" [(ngModel)]="model.password" #name="ngModel" (click)="loginKey($event)" (keydown)="loginKey($event)" (keyup)="sft=false" #PasswordField><a class="button btn-primary" (click)="logIn()">Login</a>
      </form>
      <div class="box-borders">
        <div class="top"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="bottom"></div>
      </div>
    </div>
    <div id="NewId" [@createFrame]="state">
      <div class="newID" [@innerFrame]="state">
        <div id="Step1" [@step1]="state">
          <h2>New Identity</h2>
          <p>If you're new to Identity and haven't made an account yet, click the button below. Having an account is free with no obligations.</p><a class="button btn-primary" (click)="state = '2'">Create Your Identity<span class="icon icon-arrow-right2"></span></a>
        </div>
        <div id="Step2" [@step2]="state">
          <div id="UserDetails">
            <form>
              <div class="prev" (click)="this.state = '1';"><i class="licon licon-chevron-left"></i></div>
              <!--.next((click)="this.state = '3';")i.fa.fa-arrow-right
              -->
              <h2>New Passport</h2>
              <div class="form-group">
                <label for="username">{{ sft?'Email':'Username' }}</label>
                <input class="input-normal" id="InputUsername" type="text" name="username" [(ngModel)]="newuser.username" [placeholder]="sft?'email':'username'" [@inputerror]="erruser" (keyup)="checkAccount($event)">
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input class="input-normal" id="InputPassword" type="password" placeholder="password" name="password" [(ngModel)]="newuser.password" (keyup)="checkPassword($event)" [@inputerror]="errpwd">
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input class="input-normal" type="text" placeholder="email address" id="Email" name="email" [(ngModel)]="newuser.email" [@inputerror]="erremail" (keyup)="checkAddress($event)">
              </div><a class="button btn-primary" (click)="this.state = '3'">New Identity</a>
            </form>
          </div>
        </div>
        <div id="Step3" [@step3]="state">
          <div id="AccountDetails">
            <form>
              <div class="prev" (click)="this.state = '2';"><i class="licon licon-chevron-left"></i></div>
              <!--.next((click)="this.state = '4';")i.fa.fa-arrow-right
              -->
              <h2>New Identity</h2>
              <div class="form-group">
                <label for="firstname">First Name</label>
                <input class="input-normal" type="text" placeholder="first name" name="firstname" required="" [(ngModel)]="newuser.firstname" (click)="unfocus()">
              </div>
              <div class="form-group">
                <label for="surname">Surname</label>
                <input class="input-normal" type="text" placeholder="surname" name="surname" required="" [(ngModel)]="newuser.surname" (click)="unfocus()">
              </div>
              <div class="form-group">
                <label for="birthday">Birthdate	</label>
                <div class="clear"></div>
                <div class="birthday">
                  <div class="year"><i class="fa fa-flex"></i>
                    <input class="input-normal" #Year type="text" placeholder="0000" name="year" required="" [(ngModel)]="newuser.year" (click)="openYear = openYear ? false : true; openMonth = false; openDay = false">
                    <ul class="years" [@animateOpen]="openYear" (click)="openYear = openYear ? false : true">
                      <li *ngFor="let year of years" (click)="newuser.year = year;focus('year');">{{ year }}</li>
                    </ul>
                  </div>
                  <div class="month"><i class="fa fa-flex"></i>
                    <input class="input-normal" #Month type="text" placeholder="00" name="month" required="" [(ngModel)]="newuser.month" (click)="openMonth = openMonth ? false : true; openDay = false; openYear= false">
                    <ul class="months" [@animateOpen]="openMonth" (click)="openMonth = openMonth ? false : true">
                      <li *ngFor="let month of months" (click)="newuser.month = month;focus('month');">{{ month }}</li>
                    </ul>
                  </div>
                  <div class="day"><i class="fa fa-flex"></i>
                    <input class="input-normal" #Day type="text" placeholder="00" name="day" required="" [(ngModel)]="newuser.day" (click)="openDay = openDay ? false : true; openMonth = false; openYear = false">
                    <ul class="days" [@animateOpen]="openDay" (click)="openDay = openDay ? false : true">
                      <li *ngFor="let day of days" (click)="newuser.day = day;focus('day');">
                        {{ day }}
                        
                      </li>
                    </ul>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
              <div class="form-group genders-group">
                <label for="genders">Gender</label>
                <div class="clear"></div>
                <div class="genders" (click)="unfocus()">
                  <label for="M" (click)="newuser.sex = m.value;" [attr.class]="(newuser.sex == m.value) ? &quot;active male&quot; : &quot;male&quot;">Male</label>
                  <input id="M" [checked]="newuser.sex == m.value" #m="" (click)="newuser.sex = m.value;" name="sex" value="M" type="radio">
                  <input id="F" [checked]="newuser.sex == f.value" #f="" (click)="newuser.sex = f.value;" name="sex" value="F" type="radio">
                  <label for="F" (click)="newuser.sex = f.value;" [attr.class]="(newuser.sex == f.value) ? &quot;active female&quot; : &quot;female&quot;">Female</label>
                  <input id="C" [checked]="newuser.sex == c.value" #c="" (click)="newuser.sex = c.value;" name="sex" value="sex" type="radio">
                  <label for="C" (click)="newuser.sex = c.value;" [attr.class]="(newuser.sex == c.value) ? &quot;active trans&quot; : &quot;trans&quot;"><i class="fa fa-transgender"></i></label>
                  <!--custom-gender([@customgender]="customgender")
                  input.input-normal.custom(#i='', type='text', placeholder='custom sex', name='sex', [(ngModel)]='newuser.sex')
                  
                  -->
                </div>
              </div>
              <div class="form-group countries-group">
                <label class="input-labels" for="country">Country</label>
                <div class="countries"><i class="fa fa-flex"></i>
                  <input class="input-normal" #country type="text" placeholder="Country" name="country" required="" [(ngModel)]="model.country" (click)="openCountries = openCountries ? false : true;">
                  <ul class="country" [@countryOpen]="openCountries" (click)="openCountries = openCountries ? false : true">
                    <li *ngFor="let country of countries" (click)="model.country = country.name">{{ country.name }}</li>
                  </ul>
                </div>
              </div>
              <div class="form-group newsletter-group">
                <label>Identity Newsletter</label>
                <div class="clear"></div>
                <div class="newsletter">
                  <input id="newslettercheckbox" style="width:auto !important;" type="checkbox" name="newsletter" [(ngModel)]="newuser.newsletter">
                  <label style="color: white;" for="newslettercheckbox" [attr.class]="(newuser.newsletter == true) ? &quot;active emails&quot; : &quot;&quot;">Subscribe to newsletters and updates via e-mail.</label>
                  <div class="clear"></div>
                </div>
              </div>
              <div class="clear"></div><a class="button btn-primary" (click)="this.state = '4'">Continue</a>
            </form>
          </div>
        </div>
        <div id="Step4" [@step4]="state">
          <div id="Confirmation">
            <form>
              <div class="prev" (click)="this.state = '3';"><i class="licon licon-chevron-left"></i></div>
              <h2>Welcome</h2>
              <div class="form-group confirm_email">
                <label for="confirmemail">Email</label>
                <input class="input-normal email" id="confirmemail" type="text" placeholder="retype email" name="confirmemail" required="" [(ngModel)]="newuser.confirmemail" [@inputerror]="erremail">
                <div class="clear"></div>
              </div>
              <div class="form-group confirm_password">
                <label for="confirmpassword">Password</label>
                <input class="input-normal password" id="confirmpassword" type="password" placeholder="retype password" name="confirmpassword" required="" [(ngModel)]="newuser.confirmpassword" [@inputerror]="errpwd" (keyup)="createKey($event)">
                <div class="clear"></div>
              </div>
              <div class="form-group captcha-group">
                <label for="captcha">Captcha</label>
                <div class="captcha"><span (click)="this.captcha.reset()">Refresh</span>
                  <re-captcha class="reset-recaptcha" site_key="6LdeyhwUAAAAAGN8617HbnDIrjPXSXp5Ma1Z4K5f" theme="dark"></re-captcha>
                </div>
              </div>
              <div class="clear"></div><a class="button btn-primary" (click)="createIdentity()">Login to Identity</a>
            </form>
          </div>
        </div>
        <div id="Agreement" [@agreement]="state">
          <div class="terms">
            <h2>TERMS OF SERVICE</h2>
            <p>By creating an account for Identity you accept the terms of service as well as the privacy policy. By this you also accept that the current version of the terms and conditions apply.</p>
            <p>1. You may not violate any local, state, national or international laws or regulations. Asylum cannot be held accountable for any user-side violations and will report such to the law enforcement agencies.</p>
            <p>2. The instructions of ingame support personnel are to be followed. Non-compliance can result in a temporary or permanent account ban.</p>
            <p>This document may be revised at any time. It is your responsibility to occasionally check this document to ensure that you are in compliance with the rules, policies and agreements.</p>
            <p>Pledges made to Asylum Entertainment Inc. for the development of Identity are considered donations to the continued development of Identity and are not purchases for the in-game intangible items received. You understand that any in-game claimables are rewards, from Asylum Entertainment Inc., as thanks for your contribution and are bound to a single game account and cannot be transferred.</p>
            <p>Termination of your account by Asylum Entertainment Inc. or an authorized representative may be caused by failing to abide by these rules. This does not entitle you to a refund or any fees paid for the use of the services provided by Asylum Entertainment Inc.</p>
            <h3>CONTACT</h3>
            <p>If you would like to contact us, or have questions or suggestions regarding our Privacy Policies, please e-mail us at the following address:</p>
            <p>Asylum Entertainment Inc.</p>
            <p><a href="mailto:support@asylumentertainment.ca">support@asylumentertainment.ca</a></p>
          </div>
          <div class="agreement-prompt" [@termserror]="errterms">
            <input id="terms" style="width:auto !important;" type="checkbox" name="model.terms == n.value" #n="" (click)="model.terms = n.value">
            <label for="terms"><span style="cursor: pointer;">I agree to the Identity Terms of Service and Privacy Policy.</span></label>
          </div>
        </div>
        <!-- Agreement-->
        <div class="box-borders">
          <div class="top"></div>
          <div class="left"></div>
          <div class="right"></div>
          <div class="bottom"></div>
        </div>
      </div>
    </div>
    <!-- new id-->
    <div id="OAuthLogins">
      <h2>Account Login</h2>
      <div class="auth steam" (click)="oauthLogin('steam')">
        <div class="background"></div>
        <div class="light"></div><a [attr.href]="oauthSteam" target="_blank">
          <div class="tryingOauth" *ngIf="loading['steam']">
            <div class="ico-box">
              <div class="icon-spin"><i class="icon icon-spinner2"></i></div>
            </div>
          </div>
          <div class="ico-box"><i class="fa fa-steam-square"></i></div>
          <div class="label">Steam</div></a>
      </div>
      <div class="auth facebook" (click)="oauthLogin('facebook')">
        <div class="background"></div>
        <div class="light"></div><a [attr.href]="oauthFacebook" target="_blank">
          <div class="tryingOauth" *ngIf="loading['facebook']">
            <div class="ico-box">
              <div class="icon-spin"><i class="icon icon-spinner2"></i></div>
            </div>
          </div>
          <div class="ico-box"><i class="fa fa-facebook"></i></div>
          <div class="label">Facebook</div></a>
      </div>
      <div class="background"></div>
      <div class="box-borders">
        <div class="top"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="bottom"></div>
      </div>
    </div>
    <div id="AccountHelp" *ngIf="!loggedIn" [@fadeIn]>
      <ul (click)="recoveryCode = 0">
        <li [@RecoverPasswordTab]="recoveryState" (click)="recoveryState = 'forgottenPassword'; showRecovery = true;">
          <div class="background"></div><span>Reset Password</span>
        </li>
        <li [@ForgottenUsernameTab]="recoveryState" (click)="recoveryState = 'forgottenUsername'; showRecovery = true;">
          <div class="background"></div><span>Forgot Username</span>
        </li>
        <li [@VerifyEmailTab]="recoveryState" (click)="recoveryState = 'verifyEmail'; showRecovery = true;">
          <div class="background"></div><span>Verification E-Mail</span>
        </li>
      </ul>
      <div class="box-borders">
        <div class="top"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="bottom"></div>
      </div>
    </div>
  </div>
</div>
<div id="Overlay" [@overlay]="showRecovery">
  <div id="Darkness" (click)="showRecovery = 'in'; recoveryState = 'in'" [@overlay]="showRecovery"></div>
  <div id="AccountHelpWindow" [@accountHelpWindow]="recoveryState">
    <div class="background"></div>
    <div class="box-borders">
      <div class="top"></div>
      <div class="left"></div>
      <div class="right"></div>
      <div class="bottom"></div>
    </div>
    <div class="close" (click)="showRecovery = 'in'; recoveryState = 'in';">x</div>
    <div id="error" *ngIf="recoveryError != ''" [@recoveryerror]="recoveryCode">{{ recoveryError }}</div>
    <form #recoverForm="ngForm">
      <div id="ResetPassword" [@ResetPassword]="recoveryState">
        <h1>Forgotten your Password?</h1>
        <p>Have you forgotten your chosen account username? Enter the e-mail address it was created under and we'll send it over right away.</p>
        <div class="input-group">
          <label for="recovery-username">Username</label>
          <input type="text" name="recovery-username" [(ngModel)]="recovery.username" placeholder="username" #recoverPassword>
          <label for="recovery-email">Email</label>
          <input type="text" name="recovery-email" [(ngModel)]="recovery.email" placeholder="youremail@address.com"><a class="button btn-primary" (click)="recoverPassword()">Recover Password</a>
        </div>
      </div>
      <div id="ForgotUsername" [@ForgotUsername]="recoveryState">
        <h1>Forgot your Username?</h1>
        <p>Have you forgotten your chosen account username? Enter the e-mail address it was created under and we'll send it over right away.</p>
        <div class="input-group">
          <label for="recovery-username">Email</label>
          <input type="text" name="recovery-email" [(ngModel)]="recovery.email" placeholder="youremail@address.com" #recoverEmail><a class="button btn-primary" (click)="recoverUsername()">Recover Username</a>
        </div>
      </div>
      <div id="VerificationEmail" [@VerificationEmail]="recoveryState">
        <h1>Verify your Email</h1>
        <p>Didn't receive the verification mail? Enter your e-mail address below and we will resend the mail.</p>
        <div class="input-group">
          <label for="recovery-username">Email</label>
          <input type="text" name="recovery-email" [(ngModel)]="recovery.email" placeholder="youremail@address.com" #recoverUsername><a class="button btn-primary" (click)="verfiyEmail()">Verfiy Email</a>
        </div>
      </div>
    </form>
  </div>
</div>