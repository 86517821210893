
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="RecoveryPage">
  <div class="hashAncor" id="contentpage">
    <div class="row title-row">
      <div class="border">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="bottom-line"></div>
      </div>
      <div class="container">
        <div class="background"></div>
        <div class="backgroundtwo"></div>
        <h5 class="left crowd-title">
          <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>Password Reset</span>
        </h5>
      </div>
    </div>
    <div class="container bottom">
      <div id="error" *ngIf="recoveryerr" (click)="recoveryerr = false">{{ recoveryerr }}</div>
      <div id="success" *ngIf="accountstate" (click)="accountstate = null">Account password has been recovered.</div>
      <div class="account-inactive" *ngIf="accountstate == false">
        <p>If you have forgotten your username and password, you can use this form to set a new password and log back into your Identity account.</p>
      </div>
      <div class="row reset-account-container" *ngIf="accountstate == false">
        <h2 class="actication-title">Reset Your Account Password</h2>
        <form id="Keys">
          <div class="passwords">
            <div class="left">
              <input type="password" [(ngModel)]="this.recovery.password" name="recoverypassword" placeholder="New Password">
            </div>
            <div class="right">
              <input type="password" [(ngModel)]="this.recovery.secondpassword" name="recoverysecondpassword" placeholder="Repeat Password">
            </div>
          </div>
          <div class="recoverykey">
            <input type="text" [(ngModel)]="this.recovery.key" name="recoverykey" placeholder="Account Reset Key">
            <button class="button activate-account" *ngIf="this.recovery.password == this.recovery.secondpassword" (click)="recover()">Reset Password</button>
            <button class="button activate-account red" *ngIf="this.recovery.password != this.recovery.secondpassword">Match Passwords</button>
          </div>
        </form>
      </div>
      <div class="row" *ngIf="accountstate == true">
        <div class="account-active">
          <div class="icon-container"><i class="icon icon-shield"></i></div>
          <div class="activate_window">
            <h2>Account Recovered</h2>
          </div>
          <p class="success">Your account has been recovered. You can now log-in with your new password.</p>
          <div id="AdditionalItems">
            <div class="box-borders">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
            </div>
            <ul>
              <li class="community"><a href="http://www.identityrpg.com/community/">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div>
                  <div class="background"></div>
                  <div class="title"> 
                    <div class="titlebackground"></div><span>Community</span>
                  </div></a></li>
              <li class="game-shop"><a routerLink="/shop">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div>
                  <div class="background"></div>
                  <div class="title">
                    <div class="titlebackground"></div><span>Game Shop</span>
                  </div></a></li>
              <li class="about-identity"><a routerLink="/account">
                  <div class="box-borders">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                  </div>
                  <div class="background"></div>
                  <div class="title">
                    <div class="titlebackground"></div><span>My Identity</span>
                  </div></a></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Login Options-->
      <login-options></login-options>
    </div>
  </div>
</div>