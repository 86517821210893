
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="TermsPage">
  <div class="hashAncor" id="contentpage">
    <div class="container">
      <div class="image"></div>
      <h2>TERMS OF SERVICE</h2>
      <p>By creating an account for Identity you accept the terms of service as well as the privacy policy. By this you also accept that the current version of the terms and conditions apply.</p>
      <p>1. You may not violate any local, state, national or international laws or regulations. Asylum cannot be held accountable for any user-side violations and will report such to the law enforcement agencies.</p>
      <p>2. The instructions of ingame support personnel are to be followed. Non-compliance can result in a temporary or permanent account ban.</p>
      <p>This document may be revised at any time. It is your responsibility to occasionally check this document to ensure that you are in compliance with the rules, policies and agreements.</p>
      <p>Pledges made to Asylum Entertainment Inc. for the development of Identity are considered donations to the continued development of Identity and are not purchases for the in-game intangible items received. You understand that any in-game claimables are rewards, from Asylum Entertainment Inc., as thanks for your contribution and are bound to a single game account and cannot be transferred.</p>
      <p>Termination of your account by Asylum Entertainment Inc. or an authorized representative may be caused by failing to abide by these rules. This does not entitle you to a refund or any fees paid for the use of the services provided by Asylum Entertainment Inc.</p>
      <h3>CONTACT</h3>
      <p>If you would like to contact us, or have questions or suggestions regarding our Privacy Policies, please e-mail us at the following address:</p>
      <p>Asylum Entertainment Inc.</p>
      <p><a href="mailto:support@asylumentertainment.ca">support@asylumentertainment.ca</a></p>
    </div>
  </div>
</div>