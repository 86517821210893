
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="back" id="ShareMenu">
  <div class="background"></div>
  <div class="share" (click)="shareMenuOpen = clickToActivate ? shareMenuOpen ? false : true : false" (mouseenter)="shareMenuOpen = !clickToActivate ? true : false"><span>Share</span>
    <div class="arrows" *ngIf="!singleArrow"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i></div>
    <div class="arrows single-arrow" *ngIf="singleArrow"><i class="fa fa-angle-up" [@rotateDown]="shareMenuOpen"></i></div>
  </div>
  <div class="share-menu" [@ShareMenu]="shareMenuOpen" (mouseleave)="shareMenuOpen = false">
    <div class="box-borders">
      <div class="top"></div>
      <div class="right"></div>
      <div class="bottom"></div>
      <div class="left"></div>
    </div>
    <ul>
      <li *ngFor="let statistic of socialStatistics" (click)="doShare(statistic.name)" [attr.class]="statistic.name">
        <div class="background"></div>
        <div class="icon"><i [attr.class]="'fa fa-'+statistic.name"></i></div><a class="name">{{ statistic.name }}</a><i class="hidden-arrow licon licon-chevron-right"></i>
        <div class="count" *ngIf="!statistic.statistic || statistic.statistic &lt; 1">Share Identity</div>
        <div class="count" *ngIf="statistic.statistic &gt; 1">{{ statistic.statistic }} Shares</div>
      </li>
      <li class="link" [ngxClipboard]="shareLink" (cbOnSuccess)="isCopied = true">
        <div class="background"></div>
        <div class="icon"><i class="fa fa-link"></i></div><i class="hidden-arrow licon licon-link2"></i>
        <div class="link">
          <input #shareLink [(ngModel)]="windowLink" (click)="focusLink()">
        </div>
        <div class="count" *ngIf="!isCopied">Click to copy this link.</div>
        <div class="count" *ngIf="isCopied">Link has been copied.</div>
      </li>
    </ul>
  </div>
  <div class="clear"></div>
</div>