
<div *ngIf="!hideHeader">
  <!-- +mixname-->
  <!-- +imagebox('let listing of items')-->
  <!-- +mixname-->
  <!-- +imagebox('let listing of items')-->
  <!-- app.component contains all the header variables-->
  <section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
</div>
<div id="Browser">
  <!-- +mixname-->
  <!-- +imagebox('let listing of items')-->
  <div class="cart-details" id="Overlay" [@cartoverlay]="showItemDetails">
    <div id="Darkness" (click)="hideDetails()"></div>
    <div class="ItemDetails" id="CartDetails" [@cartoverlay]="showItemDetails">
        <div class="box-borders">
          <div class="top"></div>
          <div class="right"></div>
          <div class="bottom"></div>
          <div class="left"></div>
        </div>
      <div class="title">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
        <div class="backgrounds">
          <div class="gradients"></div>
          <div class="stripes"></div>
        </div><i class="licon licon-cross-square close" (click)="hideDetails()"></i>
        <h2 *ngFor="let item of allitems | slice:selectedItem:selectedItem+1;"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h2>
      </div>
      <div id="InternalDetails"> 
        <div id="Item" *ngFor="let item of allitems | categoryFilter:itemquery.category | slice:selectedItem:selectedItem+1; let y = index;">
          <div class="row">
            <div id="SelectedImages">
              <ul>
                <li *ngFor="let image of item.images | imageSize:'original'"><img src="{{ image.url }}"></li>
              </ul>
              <!-- Image page logic			-->
              <div id="ImageControls" *ngIf="item.imagecount &gt; 1">
                  <div class="direction-controls">
                    <div class="next" (click)="selectedImage = (selectedImage &lt; item.imagecount-1) ? selectedImage+1 : selectedImage">
                      <div class="background"></div>
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div><span>next</span><i class="licon licon-chevron-right"></i>
                    </div>
                    <div class="prev" (click)="selectedImage = (selectedImage &gt; 0) ? selectedImage-1 : selectedImage">
                      <div class="background"></div>
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div><i class="licon licon-chevron-left"></i><span>prev</span>
                    </div>
                  </div>
                <div class="FullImage" *ngFor="let image of item.images | slice:selectedImage:selectedImage+1 | imageSize:'original'">
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div><a href="{{ image.url }}" target="_blank"><i class="licon licon-expand"></i></a>
                </div>
              </div>
            </div>
            <div class="crowd-box" id="SelectedDetails">
              <div class="line-background"></div>
              <div class="crowd-details">
                <h5 class="crowd-title"><a routerLink="/shop/item/{{ item.id }}">{{ item.title }}</a></h5>
              </div>
              <div class="crowd-description description">{{ item.description }}</div>
              <!--.price {{ item.originalprice }}-->
            </div>
            <div class="clear"></div>
            <div class="overlay-actions">
              <!--.items {{ cart.length }} Items-->
              <!--ul.controls( *ngIf="selectedselectedItem.length > 1" )
              li.item( *ngFor="let item of allitems; let i = index;", (click)="selectedItem = i" )
              	.blip( [style.background]="( i == selectedItem ) ? '#062b5d' : nil " )
              
              -->
                <div class="direction-controls">
                  <div class="next" (click)="selectedItem = (selectedItem) ? selectedItem+1 : selectedItem">
                    <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><span>next</span><i class="licon licon-chevron-right"></i>
                  </div>
                  <div class="prev" (click)="selectedItem = (selectedItem &gt; 0) ? selectedItem-1 : 0">
                    <div class="background"></div>
                      <div class="box-borders">
                        <div class="top"></div>
                        <div class="right"></div>
                        <div class="bottom"></div>
                        <div class="left"></div>
                      </div><i class="licon licon-chevron-left"></i><span>prev</span>
                  </div>
                </div>
              <div class="window-controls direction-controls">
                <div class="next" *ngIf="!inCart( item.id )" (click)="addToCart( item )">
                  <div class="background"></div>
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div><span>Add to Cart</span>
                </div>
                <div class="next" *ngIf="inCart( item.id )" routerLink="/checkout">
                  <div class="background"></div>
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div><span>Checkout</span><i class="licon licon-cart"></i>
                </div>
                <div class="prev" routerLink="/shop/item/{{ item.id }}">
                  <div class="background"></div>
                    <div class="box-borders">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                    </div><i class="licon licon-bookmark2"></i><span>View</span>
                </div>
              </div>
              <div class="center" *ngIf="currency"> <span>{{ this.currency.value * item.price | number:'1.2-2' }}</span><span class="currency">{{ this.currency.symbol }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hashAncor">
    <div *ngFor="let category of categories | slice:0:categoriesOnPage; let i = index">
        <div class="row title-row">
          <div class="border">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="bottom-line"></div>
          </div>
          <div class="container">
            <div class="background"></div>
            <div class="backgroundtwo"></div>
            <h5 class="left crowd-title">
              <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="/shop/categories/">{{ category.name }}</a>
            </h5>
          </div>
        </div>
      <div class="container">
        <div id="StoreItems">
          <ul>
            <li class="row grid_4 crowd-box hiddenStagger" *ngFor="let listing of items | categoryFilter:category.id | slice:0:3; let i = index" [@incomingCards]>
                <div class="box-borders">
                  <div class="top"></div>
                  <div class="right"></div>
                  <div class="bottom"></div>
                  <div class="left"></div>
                </div>
              <div class="background"></div>
              <div class="background2"></div>
              <div class="background3"></div>
              <ul id="ProductImages">
                <li *ngFor="let image of listing.images | imageSize:'listing'" (click)="viewItem(i, category.id);"><img class="slide" src="{{ image.url }}"></li>
              </ul>
              <div id="ProductDetails">
                  <div class="corners">
                    <div class="left"></div>
                    <div class="right"></div>
                    <div class="bottomleft"></div>
                    <div class="bottomright"></div>
                    <div class="topline"></div>
                  </div>
                <div class="crowd-title">
                  <h5><a routerLink="/shop/item/{{ listing.id }}">{{ listing.title }}</a></h5>
                  <div class="crowd-description"><a routerLink="/shop/item/{{ listing.id }}">{{ listing.description }}</a></div>
                  <div class="stock"><span>{{ listing.stock == '-1' ? 'In Stock' : listing.stock+' In Stock' }}</span></div>
                  <div id="Currency" *ngIf="currency &amp;&amp; enableCurrencySelector" (click)="currencyOpen = ( currencyOpen &gt;= 0) ? -1 : i"><i class="fa fa-angle-right" [@rotateDown]="(currencyOpen == i) ? true : false"></i><span class="onsale" *ngIf="currency">{{ currency.symbol }}</span>
                    <ul class="currencies" [@animateOpen]="(currencyOpen == i) ? true : false" (click)="currencyOpen = ( currencyOpen &gt;= 0) ? -1 : i">
                      <li *ngFor="let c of currencies" (click)="changeCurrency(c)"><span>{{ c.symbol }}</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crowd-pleaser" *ngIf="currency"><span class="onsale">{{ this.currency.value*listing.price | number:'1.2-2' }} {{ currency.symbol }}</span></div>
                  <div id="PurchaseButton" [attr.class]="inCart(listing.id) ? 'active' : ''">
                    <button class="buy button add-to-cart" (click)="addToCart( listing )">
                        <div class="box-borders">
                          <div class="top"></div>
                          <div class="right"></div>
                          <div class="bottom"></div>
                          <div class="left"></div>
                        </div>
                      <div class="background"></div>
                      <div class="activeborders">
                        <div class="top"></div>
                        <div class="left"></div>
                        <div class="bottom"></div>
                        <div class="right"></div>
                      </div>
                      <div class="highlight"></div><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; !inCart(listing.id)">Add to Cart</span><span *ngIf="(listing.limit == 0 || listing.limit &gt; listing.owns) &amp;&amp; inCart(listing.id)">Checkout</span><span *ngIf="(listing.limit != 0 &amp;&amp; listing.limit &lt;= listing.owns)">Player Owned</span>
                    </button>
                  </div>
                <div class="clear"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom-row">
        <div class="row title-row">
          <div class="border">
            <div class="top"></div>
            <div class="bottom"></div>
            <div class="bottom-line"></div>
          </div>
          <div class="container">
            <div class="background"></div>
            <div class="backgroundtwo"></div>
            <h5 class="left crowd-title">
              <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><a routerLink="categoriesOnPage = categoriesOnPage+1">View More... </a>
            </h5><a class="right shop-link" routerLink="categoriesOnPage = categoriesOnPage+1">
              <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">Additional Categories</span></a>
          </div>
        </div>
    </div>
  </div>
</div>