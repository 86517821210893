
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<div id="SocialNetwork">
  <div id="StoreItems">
    <div class="hashAncor">
      <div class="row title-row">
        <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
          <div class="bottom-line"></div>
          <div class="left-marker"></div>
          <div class="right-marker"></div>
        </div>
        <div class="container">
          <div class="background"></div>
          <h5 class="left crowd-title">
            <div class="arrows"><i class="licon licon-rank one"></i><i class="licon licon-rank two"></i><i class="licon licon-rank three"></i></div><span>{{ titlebar }}</span>
          </h5><a class="right shop-link" (click)="showChannels = (showChannels == true) ? false : true">
            <div class="arrows"><i class="one licon licon-rank"></i><i class="two licon licon-rank"></i><i class="three licon licon-rank"></i></div><span class="back-to-shop">{{ subtitlebar }}</span></a>
        </div>
      </div>
      <div class="container">
        <social></social>
      </div>
    </div>
  </div>
</div>