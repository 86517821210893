
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div class="prison-life" id="Corp">
  <div class="hashAncor" id="contentpage">
    <div class="slim-heading">
      <div class="background"></div>
      <h2>Prison Life</h2>
    </div>
    <div class="container">
      <div class="row">
        <p class="large">Eventually, your luck is going to run out. Crime does pay, and it pays well, but the long arm of the law will catch up with you. For minor crimes you might see a ticket or time in jail, but serious offences will land you in prison.</p>
        <div class="corp-box"> 
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <p>In Identity, prison has a culture and lifestyle of its own. You might be there as punishment, but there’s a lot going on while you’re serving your sentence. Put in your time in your cellblock’s relative safety, or choose to step out and join a prison gang.</p>
        </div>
        <div class="class-container">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <h4 class="crime-classes-title"><span>LIFE IN A PRISON</span></h4>
          <div class="crime-classes">
            <div class="crime-class is-clickable" id="Delivery" myMatchHeight="corp-class" (click)="LightboxTitle='Prison Tools';LightboxImage='/assets/img/art/prison-tools.jpg';ShowLightbox = true;">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-users"></i></div>
              <div class="title">Prison Gangs</div>
              <div class="desc">Prison gangs are the core of your activity behind bars. With three gangs vying for control around you, you’ll need to choose a side to stay safe on the inside. Those without gangs are even more vulnerable when they step outside the safety of their own cellblock.</div><img src="/assets/img/art/prison-tools.jpg">
              <div class="desc">Each prison gang has access to a private contraband market, providing its members with unique looks, makeshift weapons and more. Don’t get caught with illegal goods, though, as some police officer players could be serving as prison guards in their down-time.</div>
            </div>
            <div class="crime-class crime-boxed" id="Legal" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-star"></i></div>
              <div class="title">Infamy</div>
              <div class="desc">You’ll need to pull your weight if you want the protection that a gang can offer. Your gang is going to ask a lot of you; prison life is incredibly dangerous.</div>
              <div class="desc">Each gang member will be offered tasks to carry out for cigarettes, the prison currency of choice, allowing access to equipment and items from the contraband markets within the walls. You may be asked to shank a rival inmate or to destroy a treasured artifact deep in hostile turf, for example.</div>
              <div class="desc">Special achievements available within prison, often related to nefarious activity, will unlock customizations such as tattoos or haircuts so that you can send a message to those around you on the outside.					</div>
            </div>
            <div class="crime-class crime-boxed" id="Sentencing" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-key-hole"></i></div>
              <div class="title">Sentencing</div>
              <div class="desc">You could be serving up to 45 minutes behind bars. That is, unless you take matters into your own hands!</div>
              <div class="desc">As a good and obedient inmate, you have the options of working for the prison with small jobs such as pressing plates or cleaning up trash in the yard. This work will reduce your sentence time.</div>
              <div class="desc">Time is money, though. Sometimes you just can’t wait. If you’re willing to take the risk, you can force your way out. When conditions are right and the inmates are ready, you might be able to get into a guard room and get your hands on a weapon. This can kickoff an entire player-versus-player scenario between a group of inmates and the guard forces. Fight your way out through an increasingly difficult situation and you’re home free; you’re going to have an even bigger bounty on your head, though.</div>
              <div class="desc">Prison breaks can also happen from the outside. If the prison isn’t on lockdown, outside gangs can gear up and bring the fight to the police. These conflicts are much more intense, though, and there is a lot of risk involved as the police are well equipped and the attackers can lose everything if killed or arrested.</div>
            </div>
            <div class="crime-class" id="Manufacturing" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-hammer2"></i></div>
              <div class="title">Trial by Jury</div>
              <div class="desc">If you hate the idea of getting your hands dirty with work but you’re not up for risking a longer sentence trying to escape, you might want to try your luck in front of a jury of your peers.		</div>
              <div class="desc">Those with a heavy term can request a trial. Several random citizens around the land will be summoned to jury duty, being paid if they answer the call. Your fate will be in their hands, and also on the shoulders of your attorney.</div>
              <div class="desc">You might represent yourself or hire a smooth-talking <a target="_blank" routerLink="/info/corp">legal corporation</a> to help get you off the hook. Your or your lawyer will have a short opportunity to explain how the law must have wronged you (you’re innocent, right?) and then it’s all up to the jury. If most the jury declares you not-guilty, you’re free to go; however, if only a few don’t buy your story it’s back to your small cell.</div>
            </div>
            <div class="crime-class" id="Manufacturing" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-clock3"></i></div>
              <div class="title">Serving Your Time</div>
              <div style="display: flex; justify-content: space-around;"><img class="has-padding is-clickable-image" src="assets/img/art/SWAT.png" style="margin-right: 5px; width: auto; height: 275px; border: none; box-shadow: none;" (click)="LightboxTitle='SWAT';LightboxImage='/assets/img/art/SWAT.png';ShowLightbox = true;"><img class="has-padding is-clickable-image" src="assets/img/art/prison-trans.png" style="margin-right: 5px; width: auto; height: 275px; border: none; box-shadow: none;" (click)="LightboxTitle='Prison Uniform';LightboxImage='/assets/img/art/prison-trans.png';ShowLightbox = true;"></div>
              <div class="desc">When you’re not in the mood for the gritty and competitive life in gang warfare, find your way to the common areas in the prison. Common areas are safe zones with all sorts of ways to pass the time with other inmates around you.</div>
              <div class="desc">The common area gives you safe access to weightlifting, TVs which play from a variety of streaming services, billiards and more. You might be locked up, but that doesn’t mean you shouldn’t have fun!</div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="slim-heading bottom">
      <div class="background"></div>
      <div class="background2"></div>
      <div class="background3"></div>
      <h2>Always Expanding</h2>
      <div class="container bottom">
        <div class="corp-box">
          <p>As with most things in Identity, we at Asylum will constantly be expanding this system adding new classes of corporations and building on those already available. Identity's release is only the beginning. If you have any ideas for a well-suited corporation class, let us know on the forums.</p>
        </div>
        <div class="corp-box">
          <div class="align-center learn-more"><a class="btn-primary" href="http://www.identityrpg.com/community/"> <span>Go to the forums</span></a><a class="btn-primary" routerLink="/shop"><span>Purchase Identity</span></a></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="Overlay" [@overlay]="ShowLightbox">
  <div id="Darkness" [@overlay]="ShowLightbox" (click)="ShowLightbox = false"></div>
  <div id="ItemImageModal">
    <div class="box-borders">
      <div class="top"></div>
      <div class="right"></div>
      <div class="bottom"></div>
      <div class="left"></div>
    </div><i class="licon licon-cross2" (click)="ShowLightbox = false"></i>
    <div class="header highlight">
      <h2> <span>{{LightboxTitle}}</span></h2>
      <div class="border">
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
    <div id="ItemImages">
      <div id="ImageLightbox"><img src="{{LightboxImage}}"></div>
    </div>
  </div>
</div>