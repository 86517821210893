
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false"></section>
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<div id="Corp">
  <div class="hashAncor" id="contentpage">
    <div class="slim-heading">
      <div class="background"></div>
      <h2>Gangs and Warfare</h2>
    </div>
    <div class="container">
      <div class="row">
        <p class="large">You've made stacks of cash pushing drugs and the odd robbery, but when <i>push comes to shove</i>, you'll find <b>strength in numbers</b>.</p>
        <div class="corp-box"> 
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <p>A gang is a group of ruffians with the goal of obtaining power and infamy through the domination of those around them. Gangs are often at war with one another as they compete for limited resources, whether it's turf, drugs or blood.</p>
        </div>
        <div class="corp-box">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <p>There's no shortage of criminal activity in the world outside of gangs, but true power belongs to the syndicates and cartels with the strength and fortitude to rule over their rivals.</p>
        </div>
        <div class="class-container">
          <div class="box-borders">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
          </div>
          <h4 class="crime-classes-title"><span>LIFE IN A GANG</span></h4>
          <div class="crime-classes">
            <div class="crime-class" id="Delivery" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-users"></i></div>
              <div class="title">Comradery</div>
              <div class="desc">Identity is all about working together with your friends, and gangs are the social structure geared towards crime. You can belong to only one gang at any time, so choose where your loyalty lies.</div>
              <div class="desc">Your gang has a full rank structure and the leaders can control access levels for each member based on that rank. As your gang grows, it can also unlock additional perks for all members.</div>
              <div class="desc">The gang leadership can designate any home owned in the world as the HQ, giving access to all members of the gang. Hang out with your gangmates and stockpile weapons, drugs and anything else you can get your hands on. Your HQ, like your personal homes, can also be used for respawning on death. This makes it an important rally point when *#!% hits the fan!</div>
            </div>
            <div class="crime-class crime-boxed" id="Legal" myMatchHeight="corp-class" (click)="LightboxTitle='Cartel HQ Concepts';LightboxImage='/assets/img/concept/gang-cartel.png';ShowLightbox = true;">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-bag-dollar"></i></div>
              <div class="title">Cartels</div>
              <div class="desc">Cartels are conflict areas which are controlled by one gang at any time, providing the owners with control over trade and traffiking of their related substance throughout all the land.</div><img src="/assets/img/concept/gang-cartel-thumb.jpg">
              <div class="desc">Make no mistake about it, cartels are for only the most hardened of gangs. The areas surrounding cartels are stress zones, and you're entirely vulnerable in these combat areas.</div>
              <div class="desc">To the victor goes the spoils. The cartel zone is a functional HQ for the current owners.						</div>
            </div>
            <div class="crime-class crime-boxed" id="Transportation" myMatchHeight="corp-class" (click)="LightboxTitle='Turf Layout Concept';LightboxImage='/assets/img/concept/gang-turf.jpg';ShowLightbox = true;">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-home"></i></div>
              <div class="title">Turf</div>
              <div class="desc">Take control of city neighborhoods with gang turf zones. While cartels control substances across the world of Identity, the gangs who dominate a neighborhood have direct influence over criminal organizations in that area, such as drug dealers.</div><img src="/assets/img/concept/gang-turf-thumb.jpg">
              <div class="desc">Lookouts in the area will spot rival gangs in the streets of your turf and text message members of your gang in the area.</div>
              <div class="desc">Controlling gangs get access to a safe house in the area, tightening their hold.</div>
            </div>
            <div class="crime-class" id="Manufacturing" myMatchHeight="corp-class">
              <div class="corners">
                <div class="left"></div>
                <div class="right"></div>
                <div class="bottomleft"></div>
                <div class="bottomright"></div>
                <div class="topline"></div>
              </div>
              <div class="background">
                <div class="pattern"></div>
              </div>
              <div class="icon"><i class="licon licon-gun"></i></div>
              <div class="title">Warfare</div>
              <div class="desc">Dominate gangs who cross your path and prove your strength. Declare war on your rivals and hunt them down.</div>
              <div class="desc">Gang members who are at war are completely vulnerable to one another through the stress system, and so you need to be on the lookout at all times. Prey on your enemies until they submit to your demands.</div>
              <div class="desc">When declaring war on another gang, the other gang must accept the spoils set by the agressor. The spoils can include cash, turf, real-estate and more. When one side has had enough and ends the war, they forfeit those spoils to the stronger gang.</div>
              <div class="desc">In Identity, most items people carry can be taken if they're killed. What's theirs, is yours. War is hell.</div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="slim-heading bottom">
      <div class="background"></div>
      <div class="background2"></div>
      <div class="background3"></div>
      <h2>Always Expanding</h2>
      <div class="container bottom">
        <div class="corp-box">
          <p>As with most things in Identity, we at Asylum will constantly be expanding this system adding new classes of corporations and building on those already available. Identity's release is only the beginning. If you have any ideas for a well-suited corporation class, let us know on the forums.</p>
        </div>
        <div class="corp-box">
          <div class="align-center learn-more"><a class="btn-primary" href="http://www.identityrpg.com/community/"> <span>Go to the forums</span></a><a class="btn-primary" routerLink="/shop"><span>Purchase Identity</span></a></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="Overlay" [@overlay]="ShowLightbox">
  <div id="Darkness" [@overlay]="ShowLightbox" (click)="ShowLightbox = false"></div>
  <div id="ItemImageModal">
    <div class="box-borders">
      <div class="top"></div>
      <div class="right"></div>
      <div class="bottom"></div>
      <div class="left"></div>
    </div><i class="licon licon-cross2" (click)="ShowLightbox = false"></i>
    <div class="header highlight">
      <h2> <span>{{LightboxTitle}}</span></h2>
      <div class="border">
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
    <div id="ItemImages">
      <div id="ImageLightbox"><img src="{{LightboxImage}}"></div>
    </div>
  </div>
</div>