
<!-- +mixname-->
<!-- +imagebox('let listing of items')-->
<!-- app.component contains all the header variables-->
<section id="mainhead" [style.background]="header" [style.height]="headerHeight ? headerHeight : false">
  <div class="container">
    <div class="info-top" #Top>
      <div [attr.class]="showIntro == true ? 'open blurb' : 'blurb'">
        <div class="gradient"></div>
        <div class="background" [style.background-position]="bgPos"></div>
        <div class="blurb-holder">
          <h1>This is <b>Identity</b></h1>
          <p>A new breed of massively multiplayer online role-playing game where hundreds of players interact in a world of absolute freedom. It's the actions of players which determine your fate and the fate of the world you live in.</p>
          <p>Live as a criminal, a police officer, business person or anything in between. You are in full control of your own future in your persuit of wealth, glory and power.</p>
          <div class="blur"></div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="left-align slogan">
    <div class="container">
      <h1><b>IDENTITY </b><i>AT A GLANCE</i>
        <div class="left-bracket"></div>
        <div class="right-bracket"></div>
      </h1>
    </div>
    <div class="haveALook scroll open" (click)="this.goToSection(1); this.showScrollLink = false"> <i class="licon licon-rank2"></i><span>Have a Look</span></div>
  </div>
</section>
<div id="Info">
  <div class="hashAncor" id="contentpage">
    <div class="inforow fp-section fp-table" id="World" #World>
      <div class="box-borders">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
      <div class="background_image"></div>
      <div class="panel" [@infodescription]="viewedSections &gt; 0"><a name="massive_world"></a>
        <div class="background"></div>
        <div class="description">
          <div class="box-shots">
            <div class="top"><i class="licon licon-flare"></i></div>
            <div class="right"><i class="licon licon-flare"></i></div>
            <div class="bottom"><i class="licon licon-flare"></i></div>
            <div class="left"><i class="licon licon-flare"></i></div>
          </div>
          <h4><span>Massive &amp; detailed world</span></h4>
          <p>Identity takes place in a fictional land in the eastern United States, inspired primarily by the Carolinas and Georgia.</p>
          <p>The chosen location allows for a huge variety in terrain and environments. You'll encounter dirty cities, beautiful beaches and farmland, snow-capped mountains and 	everything in between. All of these areas are inspired by real locations in the United States, resulting in one of the most believeable and immersive worlds found in any video game.</p>
          <p>Traveling throughout the land on foot could take a long time, so you'll find highways, roads and trails to help you along. For those who haven't purchased a vehicle or bicycle, train stations are located throughout the land.</p>
          <p>The initial world size is expected to be an enormous 225 km<sup>2</sup></p>
        </div>
      </div>
      <div class="clear"></div>
      <div class="scroll" (click)="this.goToSection(2);"> <i class="fa fa-chevron3"></i>
        <div class="border">
          <div class="bottom"></div>
        </div><span>Next</span>
      </div>
      <div class="clear"></div>
    </div>
    <div class="inforow fp-section fp-table" id="Jobs" #Jobs>
      <div class="box-borders">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
      <div class="background_image"></div>
      <div class="panel" [@infodescription]="viewedSections &gt; 1"><a name="jobs_and_careers"></a>
        <div class="background"></div>
        <div class="description">
          <div class="box-shots">
            <div class="top"><i class="licon licon-flare"></i></div>
            <div class="right"><i class="licon licon-flare"></i></div>
            <div class="bottom"><i class="licon licon-flare"></i></div>
            <div class="left"><i class="licon licon-flare"></i></div>
          </div>
          <h4><span>Jobs and Careers</span></h4>
          <p>The world of Identity is filled of jobs of all kinds. These are simple tasks which anyone can take on without any sort of commitment.</p>
          <p>Careers are similar to jobs in many ways, although they're much more in-depth! Careers are complete with rank progression, special character talents and perks and much more.</p>
          <p>One example of a career is policing. You'll start your way as an entry-level officer but if you do a good job you'll find yourself climbing the ranks, unlocking abilities and even new specialties. In this case a specialty could make you a detective or SWAT officer, each again with their own further progression.</p>
        </div>
        <div class="clear"></div>
      </div>
      <div class="scroll" (click)="this.goToSection(3);"> <i class="fa fa-chevron3"></i>
        <div class="border">
          <div class="bottom"></div>
        </div><span>Next</span>
      </div>
      <div class="clear"></div>
    </div>
    <div class="inforow fp-section fp-table" id="Home" #Home>
      <div class="box-borders">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
      <div class="background_image"></div>
      <div class="panel" [@infodescription]="viewedSections &gt; 2"><a name="your_new_home"></a>
        <div class="background"></div>
        <div class="description">
          <div class="box-shots">
            <div class="top"><i class="licon licon-flare"></i></div>
            <div class="right"><i class="licon licon-flare"></i></div>
            <div class="bottom"><i class="licon licon-flare"></i></div>
            <div class="left"><i class="licon licon-flare"></i></div>
          </div>
          <h4><span>Your new home</span></h4>
          <p>Housing is an important part of your identity. In such a large world, you'll find that every single residential building is available for purchase (and sale) by you and other players, and all of these homes in the world are unique with only one owner at a time. Apartments, on the other hand, can house an unlimited number of players as they are instanced, although generally smaller in size.</p>
          <p>You have full control over the furnishing of your home, and some of those furnishings have important uses, too! While some things are decorative, such as a painting painted by another player, you can also sleep in a bed, store items in a container or closet, or even grow and craft objects in your home.</p>
          <p>A new home comes empty so you'll have to furnish and upgrade it yourself.</p>
        </div>
        <div class="clear"></div>
      </div>
      <div class="scroll" (click)="this.goToSection(4);"> <i class="fa fa-chevron3"></i>
        <div class="border">
          <div class="bottom"></div>
        </div><span>Next</span>
      </div>
      <div class="clear"></div>
    </div>
    <div class="inforow fp-section fp-table" id="Crime" #Crime>
      <div class="box-borders">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
      <div class="background_image"></div>
      <div class="panel" [@infodescription]="viewedSections &gt; 3"><a name="life_of_crime"></a>
        <div class="background"></div>
        <div class="description">
          <div class="box-shots">
            <div class="top"><i class="licon licon-flare"></i></div>
            <div class="right"><i class="licon licon-flare"></i></div>
            <div class="bottom"><i class="licon licon-flare"></i></div>
            <div class="left"><i class="licon licon-flare"></i></div>
          </div>
          <h4> <span>Live a life of crime</span></h4>
          <p>While there are a lot of legal ways to make money, there are just as many illegal routes. Illegal jobs will most often give a better payout but you'll have the police on your trail and risk spending your time in jail or prison.</p>
          <p>Illegal jobs include everything from drug production, use and sale to robbing people, stores or even banks. Minor robberies can be handled alone, but you'll need an organized and well-equipped team to pull off big heists.</p>
          <p>If you're planning on a life of crime, forming an organized gang to control turf and cartels might be the way to go. Gangs are just one form of organization you can create or join, with the power to control and change the world through illegal and often violent means.</p>
        </div>
        <div class="clear"></div>
        <div class="learn-more"><a class="btn-primary" routerLink="/info/gangs/"><span>Tell me more about gangs and warfare</span></a><br><a class="btn-primary" routerLink="/info/prison-life/"><span>Tell me more about prison life</span></a></div>
      </div>
      <div class="scroll" (click)="this.goToSection(5);"> <i class="fa fa-chevron3"></i>
        <div class="border">
          <div class="bottom"></div>
        </div><span>Next</span>
      </div>
      <div class="clear"></div>
    </div>
    <div class="inforow fp-section fp-table" id="Empire" #Empire>
      <div class="box-borders">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
      <div class="background_image"></div>
      <div class="panel" [@infodescription]="viewedSections &gt; 4"><a name="building_an_empire"></a>
        <div class="background"></div>
        <div class="description">
          <div class="box-shots">
            <div class="top"><i class="licon licon-flare"></i></div>
            <div class="right"><i class="licon licon-flare"></i></div>
            <div class="bottom"><i class="licon licon-flare"></i></div>
            <div class="left"><i class="licon licon-flare"></i></div>
          </div>
          <h4><span>Build an empire</span></h4>
          <p>From small business ownership to corporations and a living market, everything is possible for the business person in Identity!</p>
          <p>The vast majority of items you'll find in shops are created by players either in their home workshops or in large factories. Everything from furniture to weapons and vehicles are crafted by other players. Crafted items also allow for a huge variety in customizations so that few pieces will look the same and they can be branded with your mark or the mark of your company who owns the recipe.</p>
          <p>Many shops and businesses can be owned and controlled by players, while you're also able to create your own corporations which can own some assets and employees.</p>
        </div>
        <div class="clear"></div>
        <div class="learn-more"><a class="btn-primary" routerLink="/info/corp/"><span>Tell me more about corporations</span></a></div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="final-row" #Bottom>
      <div class="background_video"><a class="credit" href="https://www.youtube.com/watch?v=PK3jeK8ghVg">Video Credit: Wailander</a>
        <video class="html video-player ng-tns-c2-1" autoplay="true" loop="true" muted="true">
          <source class="ng-tns-c2-1" src="/assets/video/looping_movie.mp4" type="video/mp4">
        </video>
      </div>
      <div class="hero_prompt" [@infopagepanel]="viewedSections &gt; 4"><img class="logo-image" src="/assets/img/IdentityLogoGlow.png">
        <h2>Play Identity</h2>
        <div class="align-center"><a class="btn-primary" routerLink="/shop/"><span>Learn how you can play Identity before release</span></a></div>
      </div>
      <div class="scroll"><span (click)="this.goToTop();">Back to Top</span></div>
    </div>
  </div>
</div>